import React from "react";
import classes from "./ProductImg.module.css";

const spanClsActive = [classes['product__navigation-item'], classes['product__navigation-item--active']]

const ProductImg = (props) => {
  return (
    <div className={classes.product__image}>
      {/*Затемняющий элемент с надписью +2фото:*/}
      {/*<div className={classes['product__image-more-photo']}>+2 фото</div>*/}
      <img
        /*src="../../../../../img/item1.jpg"
        srcSet="../../../../../img/item1-2x.jpg"*/
        src={props.img}
        srcSet={props.img}
        width="318"
        height="220"
        /*alt="Загородный дом с видом на озеро"*/
        alt={props.alt}
      />
      
          {/*переключатель изображений внизу картинки:*/}
        {/*<div className={classes['product__image-navigation']}>
          <span className={spanClsActive.join(' ')}/>
          <span className={classes['product__navigation-item']}/>
          <span className={classes['product__navigation-item']}/>
          <span className={classes['product__navigation-item']}/>
          <span className={classes['product__navigation-item']}/>
        </div>*/}
    </div>
)
}

export default ProductImg