import React, {Component} from "react";
import classes from "./ResultsList.module.css";
import Product from "./Product/Product";
import Pagination from "../Pagination/Pagination";
import Results from "../Results";

class ResultsList extends Component {
  constructor(props) {
    super(props)
    this.myRef = React.createRef()
  }

  paginationClickHandler = (event) => {
    this.props.paginationHandleClick(event)
    this.myRef.current.scrollIntoView({
      behavior: 'smooth',
      block: 'start'
    })

    /*this.myRef.current.scrollTo({
      top: 0,
      behavior: 'smooth',
    })*/
  }

  paginationClickHandlerCart = (event) => {
    this.props.paginationHandleClickCart(event)
    this.myRef.current.scrollIntoView({
      behavior: 'smooth',
      block: 'start'
    })

    /*this.myRef.current.scrollTo({
      top: 0,
      behavior: 'smooth',
    })*/
  }

  render() {
    const {paginationCurrentPage, paginationCurrentPageCart, paginationProductsPerPage} = this.props.filterState
    const indexOfLastProduct = paginationCurrentPage * paginationProductsPerPage
    const indexOfFirstProduct = indexOfLastProduct - paginationProductsPerPage
    const indexOfLastProductCart = paginationCurrentPageCart * paginationProductsPerPage
    const indexOfFirstProductCart = indexOfLastProductCart - paginationProductsPerPage


    if (this.props.filterState.inCartProducts.length !== 0 && this.props.filterState.resultsSortingCartActive) {
      const currentProducts = this.props.filterState.inCartProducts.filter(product => product.inCartVisible).slice(indexOfFirstProductCart, indexOfLastProductCart)
/*      const pageNumbers = []
      for (let i = 1; i <= Math.ceil(this.props.filterState.inCartProducts.filter(product => product.visible).length / paginationProductsPerPage); i++) {
        pageNumbers.push(i);
      }
      const renderPageNumbers = pageNumbers.map(number => {
        return (
          <li
            key={number}
            id={number}
            onClick={(event) => {
              this.props.paginationHandleClick(event)
              this.myRef.current.scrollTo(0, 0)
            }}
          >
            {number}
          </li>
        );
      })*/
      return (
        <div
          className={classes.results__wrapper}
          ref={this.myRef}
        >
          <ul className={classes.results__list}>
            {
              currentProducts.map((product, id) => {
                return (
                  <Product
                    key={product.title + id}
                    visible={product.visible}
                    img={product.img[0]}
                    //alt={product.alt[0]}
                    cat={product.cat}
                    filterState={this.props.filterState}
                    product={product}
                    products={this.props.products}
                    productsFiltered={this.props.productsFiltered}
                    inCartProductAmountChangeHandler={this.props.inCartProductAmountChangeHandler}
                    inCartProductAmountAddHandler={this.props.inCartProductAmountAddHandler}
                    inCartProductAmountRecalculateHandler={this.props.inCartProductAmountRecalculateHandler}
                    inCartProductDeleteHandler={this.props.inCartProductDeleteHandler}
                    eventAddToCartDataHandler={this.props.eventAddToCartDataHandler}
                  />
                )
              })
            }
          </ul>
{/*          <ul id="page-numbers">
            {renderPageNumbers}
          </ul>*/}
                  <Pagination
          currentProductsArr={this.props.filterState.inCartProducts}
          paginationProductsPerPage={paginationProductsPerPage}
          // paginationHandleClick={this.props.paginationHandleClick}
          paginationCurrentPage={this.props.filterState.paginationCurrentPageCart}
          paginationHandleClick={this.props.paginationHandleClickCart}
          paginationClickHandler={this.paginationClickHandlerCart}
          filterState={this.props.filterState}

          // scrollToTop={this.props.scrollToTop}
          // ref={this.myRef}
        />
        </div>
      )
    }

    if (this.props.productsFiltered.length === 0 && this.props.filterState.filterSelectCategorySelected === this.props.filterState.filterSelectCategory[0]) {
      const currentProducts = this.props.products.filter(product => product.visible).slice(indexOfFirstProduct, indexOfLastProduct)
      /*const pageNumbers = []
      for (let i = 1; i <= Math.ceil(this.props.products.filter(product => product.visible).length / paginationProductsPerPage); i++) {
        pageNumbers.push(i);
      }
      const renderPageNumbers = pageNumbers.map(number => {
        return (
          <li
            key={number}
            id={number}
            onClick={(event) => {
              this.props.paginationHandleClick(event)
              this.myRef.current.scrollTo(0, 0)
            }}
          >
            {number}
          </li>
        );
      })*/
      return (
        <div
          className={classes.results__wrapper}
          ref={this.myRef}
        >
          <ul className={classes.results__list}>
            {
              currentProducts.map((product, id) => {
                return (
                  <Product
                    key={product.title + id}
                    visible={product.visible}
                    img={product.img[0]}
                    //alt={product.alt[0]}
                    filterState={this.props.filterState}
                    cat={product.cat}
                    product={product}
                    products={this.props.products}
                    productsFiltered={this.props.productsFiltered}
                    inputPositiveNumbersCheckHandler={this.props.inputPositiveNumbersCheckHandler}
                    inCartProductAmountChangeHandler={this.props.inCartProductAmountChangeHandler}
                    inCartProductAmountAddHandler={this.props.inCartProductAmountAddHandler}
                    inCartProductAmountRecalculateHandler={this.props.inCartProductAmountRecalculateHandler}
                    inCartProductDeleteHandler={this.props.inCartProductDeleteHandler}
                    eventAddToCartDataHandler={this.props.eventAddToCartDataHandler}
                  />
                )
              })
            }
          </ul>
          {/*<ul id="page-numbers">
            {renderPageNumbers}
          </ul>*/}
                  <Pagination
          currentProductsArr={this.props.products}
          paginationProductsPerPage={paginationProductsPerPage}
          paginationHandleClick={this.props.paginationHandleClick}
          paginationCurrentPage={this.props.filterState.paginationCurrentPage}
          paginationClickHandler={this.paginationClickHandler}
          filterState={this.props.filterState}
          btnNextClick={this.props.btnNextClick}
          btnPrevClick={this.props.btnPrevClick}
          btnDecrementClick={this.props.btnDecrementClick}
          btnIncrementClick={this.props.btnIncrementClick}
          // scrollToTop={this.props.scrollToTop}
          // ref={this.myRef}
        />
        </div>
      )
    }

    if (this.props.productsFiltered.length === 0) {
      return null
    }

    if (this.props.productsFiltered.length !== 0) {
      const currentProducts = this.props.productsFiltered.filter(product => product.visible).slice(indexOfFirstProduct, indexOfLastProduct)
      /*const pageNumbers = []
      for (let i = 1; i <= Math.ceil(this.props.productsFiltered.filter(product => product.visible).length / paginationProductsPerPage); i++) {
        pageNumbers.push(i);
      }
      const renderPageNumbers = pageNumbers.map(number => {
        return (
          <li
            key={number}
            id={number}
            onClick={(event) => {
              this.props.paginationHandleClick(event)
              this.myRef.current.scrollTo(0, 0)
            }}
          >
            {number}
          </li>
        );
      })*/
      return (
        <div
          className={classes.results__wrapper}
          ref={this.myRef}
        >
          <ul className={classes.results__list}>
            {
              currentProducts.map((product, id) => {
                return (
                  <Product
                    key={product.title + id}
                    visible={product.visible}
                    img={product.img[0]}
                    //alt={product.alt[0]}
                    cat={product.cat}
                    filterState={this.props.filterState}
                    product={product}
                    products={this.props.products}
                    productsFiltered={this.props.productsFiltered}
                    inputPositiveNumbersCheckHandler={this.props.inputPositiveNumbersCheckHandler}
                    inCartProductAmountChangeHandler={this.props.inCartProductAmountChangeHandler}
                    inCartProductAmountAddHandler={this.props.inCartProductAmountAddHandler}
                    inCartProductAmountRecalculateHandler={this.props.inCartProductAmountRecalculateHandler}
                    inCartProductDeleteHandler={this.props.inCartProductDeleteHandler}
                    eventAddToCartDataHandler={this.props.eventAddToCartDataHandler}
                  />
                )
              })
            }
          </ul>
          {/*<ul id="page-numbers">
            {renderPageNumbers}
          </ul>*/}
                  <Pagination
          currentProductsArr={this.props.productsFiltered}
          paginationProductsPerPage={paginationProductsPerPage}
          paginationHandleClick={this.props.paginationHandleClick}
          paginationCurrentPage={this.props.filterState.paginationCurrentPage}
          paginationClickHandler={this.paginationClickHandler}
          filterState={this.props.filterState}
          btnNextClick={this.props.btnNextClick}
          btnPrevClick={this.props.btnPrevClick}
          btnDecrementClick={this.props.btnDecrementClick}
          btnIncrementClick={this.props.btnIncrementClick}
          // scrollToTop={this.props.scrollToTop}
          // ref={this.myRef}
        />
        </div>
      )
    }


    /*
    if (props.productsFiltered.length === 0
        && props.filterState.eventCheckbox.length === 0
        && props.filterState.filterCameraSelectedPx === ''
        && props.filterState.filterCameraSelectedVid === ''
      ) {
        return (
          <ul className={classes.results__list}>
            {
              props.products.map((product, id) => {
                return (
                  <Product
                    key={product.title + id}
                    visible={product.visible}
                    img={product.img[0]}
                    //alt={product.alt[0]}
                    cat={product.cat}
                    product={product}
                    products={props.products}
                    productsFiltered={props.productsFiltered}
                  />
                )
              })
            }
          </ul>
        )
      }
    */

  }
}

export default ResultsList