import {
  SORTING_CART_CHANGE_HANDLER
} from "../actions/actionTypes"

const initialState = {
  inCartProducts: [],
}

export default function inCartProductsReducer(state = initialState, action) {
  
  switch (action.type) {
    
    case SORTING_CART_CHANGE_HANDLER:
      return {
        inCartProducts: action.payload.filter(product => product.inCartVisible)
      }
  
    default:
      return {
        ...state,
      }
      
  }
}

//попробовать отрефакторить inCartProducts в редакс, т.е. все вхождения inCartProducts в стейте заменить на рекдакс. Т.е. не рефакторить метод, например inCartProductAmountChangeHandler, а только inCartProducts в нем. Начать с кнопки Корзина