import React from "react";
import classes from "./ProductContent.module.css";
import AddToCartButton from "../AddToCartButton/AddToCartButton";
import AddToCartAmount from "../AddToCartAmount/AddToCartAmount";
import RemoveFromCartButton from "../RemoveFromCartButton/RemoveFromCartButton";
import InCartButtonPlusMinus from "../InCartButtonPlusMinus/InCartButtonPlusMinus";
import MediaQuery from "react-responsive";

const ProductContent = (props) => {
  let renderProductButtons
  renderProductButtons =
    <>
      {props.filterState.resultsSortingCartActive
        ? <p className={classes.product__add_to_cart}>
          Кол-во:
        </p>
        : <p className={classes.product__add_to_cart}>
          Кол-во:
        </p>}
      {/*
        // Кнопка минус
        {props.filterState.resultsSortingCartActive && props.filterState.inCartProducts.length !== 0
          ?
          <InCartButtonPlusMinus
            filterState={props.filterState}
            plusMinusPlus={false}
          />
          : null}*/}
      <AddToCartAmount
        filterState={props.filterState}
        product={props.product}
        inCartVisible={props.product.inCartVisible}
        inCartProductAmount={props.product.inCartProductAmount}
        inCartProductAmountAdded={props.product.inCartProductAmountAdded}
        inCartProductAmountChangeHandler={props.inCartProductAmountChangeHandler}
        eventAddToCartDataHandler={props.eventAddToCartDataHandler}
      />
      {/*
        // Кнопка плюс
        {props.filterState.resultsSortingCartActive && props.filterState.inCartProducts.length !== 0
          ?
          <InCartButtonPlusMinus
            filterState={props.filterState}
            plusMinusPlus={true}
          />
          : null}*/}
      {props.filterState.resultsSortingCartActive && props.filterState.inCartProducts.length !== 0
        ?
        <AddToCartButton
          filterState={props.filterState}
          onClick={props.inCartProductAmountRecalculateHandler}
        />
        :
        <AddToCartButton
          filterState={props.filterState}
          onClick={props.inCartProductAmountAddHandler}
        />
      }
      {props.filterState.resultsSortingCartActive && props.filterState.inCartProducts.length !== 0
        ?
        <RemoveFromCartButton
          filterState={props.filterState}
          product={props.product}
          eventAddToCartDataHandler={props.eventAddToCartDataHandler}
          inCartProductDeleteHandler={props.inCartProductDeleteHandler}
        />
        : null}
    </>

  let renderProductContent
  renderProductContent =
    <>
      <h3 className={classes.product__title}>
        {/*<a href="#">*/}
        {props.product.title}
        {/*</a>*/}
      </h3>
      <div className={classes.product__price_wrapper}>
        <div className={classes.product__price_left_column_sku}>
          <p>Код товара: {props.product.skuInternal}</p>
          <p>Артикул: {props.product.skuManufacturer}</p>
        </div>
        <div className={classes.product__price_right_column}>
          {/*<p>Цена по акции: {Math.round(props.product.price)} руб.</p>*/}
          {props.product.balance === 0 || props.product.balance === null || props.product.balance === '' || props.product.balance === '0'
            ? <p>Нет в наличии</p>
            : <p>Остаток по акции: {props.product.balance} шт.<br/></p>}
          {/*<p>Остаток по акции: {props.product.balance} шт.<br/></p>*/}
          {props.product.balance === 0 || props.product.balance === null || props.product.balance === '' || props.product.balance === '0'
            ? null
            : <p>Цена по акции: {props.product.price} руб.</p>}
          {/*<p>Цена по акции: {props.product.price} руб.</p>*/}
          <p>Цена: {Math.round(props.product.price + props.product.price * 0.50)} руб.</p>
        </div>
      </div>
      <div className={classes.product__description_wrapper}>
        <div className={classes.product__description_left_column}>
          {props.product.cable
            ? <p>Провод: {props.product.cable}</p>
            : null}
          {props.product.cableLength
            ? <p>Длина: {props.product.cableLength} м</p>
            : null}
          {props.product.sockets
            ? <p>Кол-во гнёзд: {props.product.sockets}</p>
            : null}
          {props.product.ratedCurrent
            ? <p>Номинальный ток: {props.product.ratedCurrent}</p>
            : null}
          {props.product.load
            ? <p>Макс. нагрузка: {props.product.load} кВт</p>
            : null}
          {props.product.series
            ? <p>Серия: {props.product.series}</p>
            : null}
          {props.product.poleNumber
            ? <p>Количество полюсов: {props.product.poleNumber}</p>
            : null}
          {props.product.current
            ? <p>Номинальный ток: {props.product.current}А</p>
            : null}
          {props.product.characteristics
            ? <p>Характеристика: {props.product.characteristics}</p>
            : null}
          {props.product.breakingCapacity
            ? <p>Отключающая способность: {props.product.breakingCapacity}</p>
            : null}
        </div>
        <div className={classes.product__description_right_column}>
          {props.product.grounding
            ? <p>Заземление: {props.product.grounding}</p>
            : null}
          {props.product.switchButton
            ? <p>Выключатель на корпусе: {props.product.switchButton}</p>
            : null}
          {props.product.protectionDegree
            ? <p>Степень защиты: {props.product.protectionDegree}</p>
            : null}
          {props.product.color
            ? <p>Цвет: {props.product.color}</p>
            : null}
          {props.product.type
            ? <p>Тип: {props.product.type}</p>
            : null}
          {props.product.brand
            ? <p>Производитель: {props.product.brand}</p>
            : null}
        </div>
      </div>

      <p className={classes.product__in_cart_amount}>В корзине: {props.product.inCartProductAmountAdded} шт. на
        сумму: {(props.product.inCartProductAmountAdded * props.product.price).toFixed(2)} руб.</p>

      <MediaQuery minWidth={1280}>
      <div className={classes.product__buttons}>
        {renderProductButtons}
      </div>
      </MediaQuery>
      <MediaQuery minWidth={600} maxWidth={1279}>
        <div className={classes.tablet_product__buttons}>
          {renderProductButtons}
        </div>
      </MediaQuery>
      <MediaQuery maxWidth={599}>
        <div className={classes.mobile_product__buttons}>
          {renderProductButtons}
        </div>
      </MediaQuery>


      {/*<div className={classes.product__address}>{props.address}</div>
<div className={classes.product__date}>{props.date}</div>*/
      }
    </>

  return (
    <>
      <MediaQuery minWidth={600}>
        <div className={classes.product__content}>
          {renderProductContent}
        </div>
      </MediaQuery>

      <MediaQuery maxWidth={599}>
        <div className={classes.mobile_product__content}>
          {renderProductContent}
        </div>
      </MediaQuery>
    </>
  )
}

export default ProductContent