import React from 'react'
import MediaQuery from 'react-responsive'
import classes from './HomePage.module.css'
import Header from "../../Components/Header/Header";
import ContentWrapper from "../../Components/ContentWrapper/ContentWrapper";

const HomePage = () => {
  return (
    <>
      <MediaQuery minWidth={1280}>
        <section className={classes.HomePage}>
          <Header/>
          <ContentWrapper/>
        </section>
      </MediaQuery>

      <MediaQuery minWidth={600} maxWidth={1279}>
        <section className={classes.tablet_HomePage}>
          <Header/>
          <ContentWrapper/>
        </section>
      </MediaQuery>

      <MediaQuery maxWidth={599}>
        <section className={classes.mobile_HomePage}>
          <Header/>
          <ContentWrapper/>
        </section>
      </MediaQuery>
    </>
  )
}

export default HomePage