import React, {Component} from "react";
import classes from "./FilterSelect.module.css";
import SelectSvg from "../SelectSvg/SelectSvg";
import FilterSelectOptionsFromObj from "./FilterSelectOptions/FilterSelectOptionsFromObj";

const FilterSelect = (props) => {
  
  const cls = [classes.FilterSelect]
  
  if(props.filterDisabled) {
    cls.push(classes.FilterSelectDisabled)
  }
  
  return (
    <div className={cls.join(' ')}>
      <label htmlFor="categories">{props.filterSelectName}</label>
      <select
        // className={classes.FilterSelectSelect}
        id="categories"
        name="categories"
        value={props.filterSelectedCategory}
        onChange={props.onChange}
      >
        <FilterSelectOptionsFromObj
          filterSelectOptions={props.filterSelectOptions}
        />
      </select>
      <SelectSvg/>
    </div>
  )
  
}

export default FilterSelect