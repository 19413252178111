import React, {Component} from "react";
import classes from "./Pagination.module.css";

class Pagination extends Component {
  constructor(props) {
    super(props)

  }

  render() {
    let cls
    let clsNext
    let clsPrev
    const {upperPageBound, lowerPageBound, isPrevBtnActive, isNextBtnActive, paginationCurrentPage} = this.props.filterState
    const currentProductsArr = this.props.currentProductsArr
    const paginationProductsPerPage = this.props.paginationProductsPerPage
    const pageNumbers = []

    for (let i = 1; i <= Math.ceil(currentProductsArr.filter(product => product.visible).length / paginationProductsPerPage); i++) {
      pageNumbers.push(i);
    }

    const renderPageNumbers = pageNumbers
      .map(number => {

        this.props.paginationCurrentPage === number
          ? cls = classes.pagination__li_active
          : cls = classes.pagination__li_not_active

        if (number === 1 && paginationCurrentPage === 1) {
          return (
            <li className={cls}
                key={number}
                id={number}
                onClick={this.props.paginationClickHandler}
            >
                {number}
            </li>
          )
        } else if ((number < upperPageBound + 1) && number > lowerPageBound) {
          return (
            <li className={cls}
                key={number}
                id={number}
                onClick={this.props.paginationClickHandler}
                >
                {number}
            </li>
          )
        }
      })

    /*
    let pageIncrementBtn = null
    if(pageNumbers.length > upperPageBound){
      pageIncrementBtn =
        <li className=''>
        <a
          href='#'
          onClick={this.props.btnIncrementClick}
        > &hellip;
        </a>
      </li>
    }
    let pageDecrementBtn = null
    if(lowerPageBound >= 1){
      pageDecrementBtn =
        <li className=''>
          <a
            href='#'
            onClick={this.props.btnDecrementClick}
          > &hellip;
          </a>
        </li>
    }
  */
    let renderPrevBtn
    paginationCurrentPage === 1
      ? clsPrev = classes.pagination__li_prev_next_stop
      : clsPrev = classes.pagination__li_prev_next
    renderPrevBtn =
      <li className={clsPrev}
          id="btnPrev"
          onClick={this.props.btnPrevClick}
      >
        Назад
      </li>

    let renderNextBtn
    paginationCurrentPage === pageNumbers.length || pageNumbers.length === 1
      ? clsNext = classes.pagination__li_prev_next_stop
      : clsNext = classes.pagination__li_prev_next
    renderNextBtn =
      <li className={clsNext}
          id="btnNext"
          onClick={this.props.btnNextClick}
      >
        Далее
      </li>


    return (
      <div className={classes.pagination__wrapper}>
        <ul
          className={classes.pagination__ul}
          id="page-numbers"
        >
          {renderPrevBtn}
          {/*{pageDecrementBtn}*/}
          {renderPageNumbers}
          {/*{pageIncrementBtn}*/}
          {renderNextBtn}
        </ul>
      </div>
    )
  }
}

export default Pagination