import React from "react"
import classes from "./AddToCartButton.module.css"

const cls = [classes.button, classes.filter__button]

const AddToCartButton = (props) => {
  return (
    <button
      className={cls.join(' ')} type="button"
      onClick={props.onClick}
    >
      {props.filterState.resultsSortingCartActive && props.filterState.inCartProducts.length !== 0
        ? 'Пересчитать'
        : 'Добавить в корзину'
      }
    </button>
  )
}

export default AddToCartButton