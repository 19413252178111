import React from "react"
import classes from "./FilterCheckbox.module.css"
import FilterCheckboxLi from "./FilterCheckboxLi/FilterCheckboxLi";


const FilterCheckbox = (props) => {
  
  const cls = [classes['Filter__type']]
  const clsUl = [classes['Filter__checkboxes-list']]
  
  if (props.filterDisabled) {
    cls.push(classes['Filter__checkboxes-list-disabled'])
  }
  
  return (
    <fieldset className={cls.join(' ')}>
      <legend>{Object.values(props.filterCheckboxName)}</legend>
      <ul className={clsUl.join(' ')}>
        <FilterCheckboxLi
          filterState={props.filterState}
          filterCheckboxOptions={props.filterCheckboxOptions}
          filterCheckboxName={props.filterCheckboxName}
          // filterCheckboxDefaultChecked={props.filterCheckboxDefaultChecked}
          filterCheckboxChecked={props.filterCheckboxChecked}
          onChange={props.onChange}
          // eventCheckboxDataHandler={props.eventCheckboxDataHandler}
        />
      </ul>
    </fieldset>
  )
}

export default FilterCheckbox