import React from "react"
import classes from "./FilterButton.module.css"

const cls = [classes.button, classes.filter__button]

const FilterButton = (props) => {
  return (
    <button
      className={cls.join(' ')} type="button"
      onClick={props.onFilterButtonClick}
    >
      Показать
    </button>
  )
}

export default FilterButton