import React from "react";
import classes from "./ResultsSortingPlaceOrder.module.css";
import MediaQuery from "react-responsive";

const cls = [classes.button, classes.filter__button, classes['cat__button-active']]

const ResultsSortingPlaceOrder = (props) => {
  let renderPlaceOrder
  renderPlaceOrder =
    <>
      <p className={classes.filter__button_p}>
        Сумма заказа: {props.filterState.inCartProducts.reduce(function (sum, inCartPoduct) {
        return sum + (inCartPoduct.inCartProductAmountAdded * inCartPoduct.price);
      }, 0.0).toFixed(2)} руб.
      </p>
      <button
        className={
          cls.join(' ')
        }
        type="button"
        onClick={props.resultsSortingPlaceOrderHandler}
      >
        Оформить заказ
      </button>
    </>

  return (
    props.filterState.resultsSortingCartActive
      ?
      <>
        <MediaQuery minWidth={1280}>
          <div className={classes.filter__button_div}>
            {renderPlaceOrder}
          </div>
        </MediaQuery>
        <MediaQuery minWidth={600} maxWidth={1279}>
          <div className={classes.tablet_filter__button_div}>
            {renderPlaceOrder}
          </div>
        </MediaQuery>
        <MediaQuery maxWidth={599}>
          <div className={classes.mobile_filter__button_div}>
            {renderPlaceOrder}
          </div>
        </MediaQuery>
      </>
      : null

  )
}

export default ResultsSortingPlaceOrder