import React from "react";
import classes from "./PersonalDataCookies.module.css";

const PersonalDataCookies = (props) => {
  return (
    props.personalDataCookiesShow
      ?
      <div className={classes.personalData_wrapper}>
        <div className={classes.personalData}>
        {/*  <p className={classes.personalData_p}>*/}
            Для улучшения работы сайта и его взаимодействия с пользователями используются cookie-файлы. Продолжая работу с сайтом, Вы разрешаете использование cookie-файлов. Вы всегда можете отключить файлы cookie в настройках Вашего браузера.
          {/*</p>*/}
        </div>
        <a
          className={classes.personalData_close}
          onClick={props.personalDataCookiesHandler}
        >
          Принять
        </a>
      </div>
      : null
  )
}

export default PersonalDataCookies