import React from "react";
import classes from "./Product.module.css";
import ProductFav from "./ProductFav/ProductFav";
import ProductImg from "./ProductImg/ProductImg";
import ProductContent from "./ProductContent/ProductContent";
import MediaQuery from "react-responsive";

const Product = (props) => {
  let renderProduct
  renderProduct =
    <>
      {/*<ProductFav/>*/}
      <ProductImg
        img={props.img}
        alt={props.alt}
      />
      <ProductContent
        filterState={props.filterState}
        product={props.product}
        products={props.products}
        productsFiltered={props.productsFiltered}
        inCartProductAmountChangeHandler={props.inCartProductAmountChangeHandler}
        inCartProductAmountAddHandler={props.inCartProductAmountAddHandler}
        inCartProductAmountRecalculateHandler={props.inCartProductAmountRecalculateHandler}
        inCartProductDeleteHandler={props.inCartProductDeleteHandler}
        eventAddToCartDataHandler={props.eventAddToCartDataHandler}
      />
    </>
  console.log('props.visible= ', props.visible)
  return (
    (props.filterState.resultsSortingCatActive && props.visible === true) || (props.filterState.resultsSortingCartActive && props.product.inCartVisible === true)
      ?
      <>
        <MediaQuery minWidth={1280}>
          <li className={classes.product}>
            {renderProduct}
          </li>
        </MediaQuery>
        <MediaQuery minWidth={600} maxWidth={1279}>
          <li className={classes.tablet_product}>
            {renderProduct}
          </li>
        </MediaQuery>
        <MediaQuery maxWidth={599}>
          <li className={classes.mobile_product}>
            {renderProduct}
          </li>
        </MediaQuery>
      </>
      : null
  )
}

export default Product