import React, {Component} from "react"
import FilterCheckbox from "../../FilterElements/FilterCheckbox/FilterCheckbox"
import FilterSelect from "../../FilterElements/FilterSelect/FilterSelect"

class FilterUdlinitel extends Component {

  render() {
    return (
      this.props.filterUdlinitelVisible
        ?
        <>
          <FilterCheckbox
            // filterCheckboxDefaultChecked={props.filterState.filterCheckboxCameraDefaultChecked}
            filterState={this.props.filterState}
            filterDisabled={this.props.filterDisabled}
            filterCheckboxName={this.props.filterState.filterCheckboxUdlinitelSockets}
            filterCheckboxOptions={this.props.filterState.filterCheckboxUdlinitel}
            filterCheckboxChecked={this.props.filterState.filterCheckboxUdlinitelChecked}
            onChange={this.props.onCheckboxUdlinitelSocketsEventChange}
          />
          <FilterCheckbox
            // filterCheckboxDefaultChecked={props.filterState.filterCheckboxCameraDefaultChecked}
            filterState={this.props.filterState}
            filterDisabled={this.props.filterDisabled}
            filterCheckboxName={this.props.filterState.filterCheckboxUdlinitelLengthName}
            filterCheckboxOptions={this.props.filterState.filterCheckboxUdlinitelLengthNew}
            filterCheckboxChecked={this.props.filterState.filterCheckboxUdlinitelLengthCheckedNew}
            onChange={this.props.onCheckboxUdlinitelLengthEventChangeNew}
            eventCheckboxDataHandler={this.props.eventCheckboxDataHandler}
          />
  
          <FilterCheckbox
            // filterCheckboxDefaultChecked={props.filterState.filterCheckboxCameraDefaultChecked}
            filterState={this.props.filterState}
            filterDisabled={this.props.filterDisabled}
            filterCheckboxName={this.props.filterState.filterCheckboxUdlinitelCurrentName}
            filterCheckboxOptions={this.props.filterState.filterCheckboxUdlinitelCurrent}
            filterCheckboxChecked={this.props.filterState.filterCheckboxUdlinitelCurrentChecked}
            onChange={this.props.onCheckboxUdlinitelCurrentEventChange}
            // eventCheckboxDataHandler={this.props.eventCheckboxDataHandler}
          />
          <FilterCheckbox
            // filterCheckboxDefaultChecked={props.filterState.filterCheckboxCameraDefaultChecked}
            filterState={this.props.filterState}
            filterDisabled={this.props.filterDisabled}
            filterCheckboxName={this.props.filterState.filterCheckboxUdlinitelLoadName}
            filterCheckboxOptions={this.props.filterState.filterCheckboxUdlinitelLoad}
            filterCheckboxChecked={this.props.filterState.filterCheckboxUdlinitelLoadChecked}
            onChange={this.props.onCheckboxUdlinitelLoadEventChange}
            // eventCheckboxDataHandler={this.props.eventCheckboxDataHandler}
          />
          <FilterCheckbox
            // filterCheckboxDefaultChecked={props.filterState.filterCheckboxCameraDefaultChecked}
            filterState={this.props.filterState}
            filterDisabled={this.props.filterDisabled}
            filterCheckboxName={this.props.filterState.filterCheckboxUdlinitelCableName}
            filterCheckboxOptions={this.props.filterState.filterCheckboxUdlinitelCable}
            filterCheckboxChecked={this.props.filterState.filterCheckboxUdlinitelCableChecked}
            onChange={this.props.onCheckboxUdlinitelCableEventChange}
            // eventCheckboxDataHandler={this.props.eventCheckboxDataHandler}
          />
          <FilterCheckbox
            // filterCheckboxDefaultChecked={props.filterState.filterCheckboxCameraDefaultChecked}
            filterState={this.props.filterState}
            filterDisabled={this.props.filterDisabled}
            filterCheckboxName={this.props.filterState.filterCheckboxUdlinitelProtectionName}
            filterCheckboxOptions={this.props.filterState.filterCheckboxUdlinitelProtection}
            filterCheckboxChecked={this.props.filterState.filterCheckboxUdlinitelProtectionChecked}
            onChange={this.props.onCheckboxUdlinitelProtectionEventChange}
            // eventCheckboxDataHandler={this.props.eventCheckboxDataHandler}
          />
          <FilterSelect
            filterState={this.props.filterState}
            filterSelectedCategory={this.props.filterState.filterSelectUdlinitelGroundSelected}
            filterDisabled={this.props.filterDisabled}
            filterSelectName={this.props.filterState.filterSelectUdlinitelGroundName}
            filterSelectOptions={this.props.filterState.filterSelectUdlinitelGround}
            onChange={this.props.onSelectUdlinitelGroundingEventChange}
          />
          <FilterSelect
            filterState={this.props.filterState}
            filterSelectedCategory={this.props.filterState.filterSelectUdlinitelSwitchSelected}
            filterDisabled={this.props.filterDisabled}
            filterSelectName={this.props.filterState.filterSelectUdlinitelSwitchName}
            filterSelectOptions={this.props.filterState.filterSelectUdlinitelSwitch}
            onChange={this.props.onSelectUdlinitelSwitchEventChange}
          />
        </>
        : null
    )
  }
}

export default FilterUdlinitel