import {
  FILTER_RANGE_WIDTH,
  FILTER_RANGE_INPUT_MIN,
  FILTER_RANGE_CURRENT_MIN,
  FILTER_RANGE_MOUSE_MOVE_MIN,
  FILTER_RANGE_SET_INPUT_MAX,
  FILTER_RANGE_CURRENT_MAX,
  FILTER_RANGE_MOUSE_MOVE_MAX,
  FILTER_RANGE_RESET
} from "../actions/actionTypes"
import products from "../products";



const productPriceArr = [
  ...new Set(products.map(product => {
    return (
      product.price
    )
  }))
].sort(function (a, b) {
  return a - b;
}).filter(item => item !== null)

const rangeCurrentMinCalculated = Math.floor(productPriceArr[0])
const rangeCurrentMaxCalculated = Math.ceil(productPriceArr[productPriceArr.length - 1])

const initialState = {
  // rangeSliderWidth: 320,
  // rangeOffsetSliderWidht: 22.546875,
  rangeSliderWidth: 0,
  rangeOffsetSliderWidht: 0,
  // rangeMin: 0,
  // rangeMax: 15000,
  rangeMin: rangeCurrentMinCalculated,
  rangeMax: rangeCurrentMaxCalculated,
  rangeMinValueBetween: 1,
  // rangeCurrentMin: 55,
  // rangeInputMin: 55,
  // rangeCurrentMax: 15000,
  // rangeInputMax: 15000,
  rangeCurrentMin: rangeCurrentMinCalculated,
  rangeInputMin: rangeCurrentMinCalculated,
  rangeCurrentMax: rangeCurrentMaxCalculated,
  rangeInputMax: rangeCurrentMaxCalculated,
  // thisMinValueStyleWidth: (rangeCurrentMinCalculated*100)/ rangeCurrentMaxCalculated + "%",
  // thisMaxValueStyleWidth: (rangeCurrentMaxCalculated*100)/ rangeCurrentMaxCalculated + "%",
  
  //скорее всего, нужно правильно записать в редакс dragedWidht и его изменения и должно все работать
  // dragedWidht:,
  filterButtonResetClicked: false,
  
  
}

export default function filterRangeReducer(state = initialState, action) {
  
  switch (action.type) {
    
    case FILTER_RANGE_WIDTH:
      return {
        ...state,
        rangeSliderWidth: action.payload1,
        rangeOffsetSliderWidht: action.payload2,
        filterButtonResetClicked: false,
      }
    
    case FILTER_RANGE_INPUT_MIN:
      return {
        ...state,
        rangeInputMin: action.payload,
        filterButtonResetClicked: false,
      }
    
    case FILTER_RANGE_CURRENT_MIN:
      return {
        ...state,
        rangeCurrentMin: action.payload,
        filterButtonResetClicked: false,
      }
    
    case FILTER_RANGE_MOUSE_MOVE_MIN:
      return {
        ...state,
        rangeCurrentMin: action.payload,
        rangeInputMin: action.payload,
        filterButtonResetClicked: false,
        
      }
    
    case FILTER_RANGE_SET_INPUT_MAX:
      return {
        ...state,
        rangeInputMax: action.payload,
        filterButtonResetClicked: false,
      }
    
    case FILTER_RANGE_CURRENT_MAX:
      return {
        ...state,
        rangeCurrentMax: action.payload,
        filterButtonResetClicked: false,
      }
    
    case FILTER_RANGE_MOUSE_MOVE_MAX:
      return {
        ...state,
        rangeCurrentMax: action.payload,
        rangeInputMax: action.payload,
        filterButtonResetClicked: false,
      }
    
    case FILTER_RANGE_RESET:
      return {
        ...state,
        // rangeSliderWidth: 0,
        // rangeOffsetSliderWidht: 0,
        // rangeMin: 0,
        // rangeMax: 15000,
        rangeMin: rangeCurrentMinCalculated,
        rangeMax: rangeCurrentMaxCalculated,
        // rangeMinValueBetween: 10,
        // rangeCurrentMin: 55,
        // rangeInputMin: 55,
        // rangeCurrentMax: 15000,
        // rangeInputMax: 15000,
        rangeCurrentMin: rangeCurrentMinCalculated,
        rangeInputMin: rangeCurrentMinCalculated,
        rangeCurrentMax: rangeCurrentMaxCalculated,
        rangeInputMax: rangeCurrentMaxCalculated,
        filterButtonResetClicked: true,
      }
    
    default:
      return {
        ...state
      }
  }
}