import React from "react"
import classes from "./FilterButtonReset.module.css"

const cls = [classes.button, classes.filter__button]

const FilterButtonReset = (props) => {
  
/*  props.filterButtonResetShow
    ? cls.push(classes.button, classes.filter__button)
    : cls.push(classes.filter__button_disabled)*/
  
  return (
    <button
      className={cls.join(' ')}
      type="button"
      onClick={props.onFilterButtonResetClick}
      disabled={!props.filterButtonResetShow}
    >
      Сбросить фильтр
    </button>
  )
}

export default FilterButtonReset