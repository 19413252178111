import React, {Component} from "react";

const FilterSelectOptionsFromObj = (props) => {
  
  return (
    props.filterSelectOptions.map((element, id) => {
      return (
        <option
          value={element}
          key={element + id}
        >
          {element}
        </option>
      )
    })
  )
  
/*  return (
    props.filterSelectOptions.map((element, id) => {
      return (
        <option
          value={Object.keys(element)}
          key={element + id}
        >
          {Object.values(element)}
        </option>
      )
    })
  )*/
}

export default FilterSelectOptionsFromObj