import React from "react"
import classes from "./RemoveFromCartButton.module.css"

const cls = [classes.button, classes.filter__button]

const RemoveFromCartButton = (props) => {
  return (
    <button
      className={cls.join(' ')} type="button"
      onMouseDown={() => props.eventAddToCartDataHandler(props.product)}
      onKeyPress={() => props.eventAddToCartDataHandler(props.product)}
      onClick={props.inCartProductDeleteHandler}
    >
      Удалить
    </button>
  )
}

export default RemoveFromCartButton