import React from "react";
import classes from "./ResultsShowLabel.module.css"
import MediaQuery from "react-responsive";

const ResultsShowLabel = (props) => {
  return(
    <>
      <MediaQuery minWidth={1280}>
    <p
      className={classes.results_show_label}
    >
      Показать:
    </p>
      </MediaQuery>

      <MediaQuery minWidth={600} maxWidth={1279}>
        <p
          className={classes.tablet_results_show_label}
        >
          Показать:
        </p>
      </MediaQuery>

      <MediaQuery maxWidth={599}>
        <p
          className={classes.mobile_results_show_label}
        >
          Показать:
        </p>
    </MediaQuery>
    </>
  )
}

export default ResultsShowLabel