import React from "react";
import classes from "./Results.module.css";
import ResultsHead from "./ResultsHead/ResultsHead";
import ResultsCartInfo from "./ResultsHead/ResultsSorting/ResultsCartInfo/ResultsCartInfo";
import ResultsList from "./ResultsList/ResultsList";
import MediaQuery from "react-responsive";

const Results = (props) => {
  const resultsSectionRef = React.createRef()

  let renderResultsHead
  renderResultsHead =
    <ResultsHead
      filterState={props.filterState}
      resultsSortingCatChangeHandler={props.resultsSortingCatChangeHandler}
      resultsSortingCartChangeHandler={props.resultsSortingCartChangeHandler}
      resultsSortingPlaceOrderHandler={props.resultsSortingPlaceOrderHandler}
      /*
      filterRadiobuttonsOptions={props.filterRadiobuttonsSorting}
      filterRadiobuttonsName={props.filterRadiobuttonsSortingName}
       */
    />

  let renderResultsCartInfo
  renderResultsCartInfo =
    <ResultsCartInfo
      filterState={props.filterState}
      resultsCartInfoSubmitHandler={props.resultsCartInfoSubmitHandler}
      resultsCartInfoInputHandler={props.resultsCartInfoInputHandler}
      personalDataPolicyHandler={props.personalDataPolicyHandler}
    />

  let renderResultsList
  renderResultsList =
    <ResultsList
      filterState={props.filterState}
      products={props.products}
      productsFiltered={props.productsFiltered}
      inCartProductAmountChangeHandler={props.inCartProductAmountChangeHandler}
      inCartProductAmountAddHandler={props.inCartProductAmountAddHandler}
      inCartProductAmountRecalculateHandler={props.inCartProductAmountRecalculateHandler}
      inCartProductDeleteHandler={props.inCartProductDeleteHandler}
      eventAddToCartDataHandler={props.eventAddToCartDataHandler}
      paginationHandleClick={props.paginationHandleClick}
      paginationHandleClickCart={props.paginationHandleClickCart}
      btnNextClick={props.btnNextClick}
      btnPrevClick={props.btnPrevClick}
      btnDecrementClick={props.btnDecrementClick}
      btnIncrementClick={props.btnIncrementClick}
      scrollToTop={props.scrollToTop}
    />

  return (
    <>
      <MediaQuery minWidth={1280}>
        <section className={classes.Results}>
          {renderResultsHead}
          {renderResultsCartInfo}
          {renderResultsList}
        </section>
      </MediaQuery>
      <MediaQuery minWidth={600} maxWidth={1279}>
        <div className={classes.tablet_Results}>
          {renderResultsHead}
          {renderResultsCartInfo}
          {renderResultsList}
        </div>
      </MediaQuery>
      <MediaQuery maxWidth={599}>
        <div className={classes.mobile_Results}>
          {renderResultsHead}
          {renderResultsCartInfo}
          {renderResultsList}
        </div>
      </MediaQuery>
    </>
  )
}

export default Results