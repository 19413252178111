import {
  SELECT,
  ALL_FILTERS_VISIBLE,
  UDLINITEL_VISIBLE,
  VILKI_VISIBLE,
  SCHTEPSELNYE_GNEZDA_VISIBLE,
  VYKLUCHATELI_PROHODNYE_VISIBLE,
  SHNURY_VISIBLE,
  TROINIKI_VISIBLE,
  TELEPHONE_UDLINITEL_VISIBLE,
  KOLODKI_VISIBLE,
  RAZJEM_PITANIYA_VISIBLE,
  AVT_VYKLUCHATELI_MODULNYE_VISIBLE,
  // ESTATE_VISIBLE,
  // CAMERA_VISIBLE,
  // LAPTOP_VISIBLE,
  // CAR_VISIBLE,
  FILTER_RANGE_WIDTH,
  FILTER_RANGE_INPUT_MIN,
  FILTER_RANGE_CURRENT_MIN,
  FILTER_RANGE_MOUSE_MOVE_MIN,
  FILTER_RANGE_SET_INPUT_MAX,
  FILTER_RANGE_CURRENT_MAX,
  FILTER_RANGE_MOUSE_MOVE_MAX,
  FILTER_RANGE_RESET,
  SORTING_CART_CHANGE_HANDLER,
} from "./actionTypes"

export function selectFilterCategory(event) {
  return {
    type: SELECT,
    payload: event.target.value
  }
}

export function allFiltersVisible() {
  return {
    type: ALL_FILTERS_VISIBLE
  }
}
export function udlinitelVisible() {
  return {
    type: UDLINITEL_VISIBLE
  }
}
export function vilkiVisible() {
  return {
    type: VILKI_VISIBLE
  }
}
export function shtepselnyeGnezdaVisible() {
  return {
    type: SCHTEPSELNYE_GNEZDA_VISIBLE
  }
}
export function vykluchateliProhodnyeVisible() {
  return {
    type: VYKLUCHATELI_PROHODNYE_VISIBLE
  }
}
export function shnuryVisible() {
  return {
    type: SHNURY_VISIBLE
  }
}
export function troinikiVisible() {
  return {
    type: TROINIKI_VISIBLE
  }
}
export function telephoneUdlinitelVisible() {
  return {
    type: TELEPHONE_UDLINITEL_VISIBLE
  }
}
export function kolodkiVisible() {
  return {
    type: KOLODKI_VISIBLE
  }
}
export function razjemPitaniyaVisible() {
  return {
    type: RAZJEM_PITANIYA_VISIBLE
  }
}
export function avtVykluchateliModulnyeVisible() {
  return {
    type: AVT_VYKLUCHATELI_MODULNYE_VISIBLE
  }
}
/*export function estateVisible() {
  return {
    type: ESTATE_VISIBLE
  }
}

export function cameraVisible() {
  return {
    type: CAMERA_VISIBLE
  }
}

export function laptopVisible() {
  return {
    type: LAPTOP_VISIBLE
  }
}

export function carVisible() {
  return {
    type: CAR_VISIBLE
  }
}*/

export function filterRangeWidth(rangeSliderWidth, rangeOffsetSliderWidht) {
  return {
    type: FILTER_RANGE_WIDTH,
    payload1: rangeSliderWidth,
    payload2: rangeOffsetSliderWidht
    
  }
}

export function filterRangeInputMinimal(rangeInputMin) {
  return {
    type: FILTER_RANGE_INPUT_MIN,
    payload: rangeInputMin,
  }
}

export function filterRangeCurrentMinimal(rangeCurrentMin) {
  return {
    type: FILTER_RANGE_CURRENT_MIN,
    payload: rangeCurrentMin,
  }
}

export function filterRangeMouseMoveMinimalHandler(rangeCurrentMin) {
  return {
    type: FILTER_RANGE_MOUSE_MOVE_MIN,
    payload: rangeCurrentMin,
  }
}

export function filterRangeSetInputMaximumHandler(rangeInputMax) {
  return {
    type: FILTER_RANGE_SET_INPUT_MAX,
    payload: rangeInputMax,
  }
}

export function filterRangeSetCurrentMaximumHandler(rangeCurrentMax) {
  return {
    type: FILTER_RANGE_CURRENT_MAX,
    payload: rangeCurrentMax,
  }
}

export function filterRangeMouseMoveMaximumHandler(rangeCurrentMax) {
  return {
    type: FILTER_RANGE_MOUSE_MOVE_MAX,
    payload: rangeCurrentMax,
  }
}

export function filterRangeReset() {
  return {
    type: FILTER_RANGE_RESET
  }
}

export function inCartProductAmountChange(products) {
  return {
    type: SORTING_CART_CHANGE_HANDLER,
    payload: products,
  }
}



