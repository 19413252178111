import React from "react";
import classes from "./ResultsHead.module.css";
import ResultsSorting from "./ResultsSorting/ResultsSorting";
import ResultsSortingOrder from "./ResultsSorting/ResultsSortingOrder/ResultsSortingOrder";
import ResultsShowLabel from "./ResultsSorting/ResultsShowLabel/ResultsShowLabel";
import Results from "../Results";
import ResultsSortingPlaceOrder from "./ResultsSorting/ResultsSortingPlaceOrder/ResultsSortingPlaceOrder";
import MediaQuery from "react-responsive";

const ResultsHead = (props) => {

  let renderResultsShowLabel
  renderResultsShowLabel =
    <ResultsShowLabel/>

  let renderResultsSorting
  renderResultsSorting =
    <ResultsSorting
      filterState={props.filterState}
      resultsSortingCatChangeHandler={props.resultsSortingCatChangeHandler}
      resultsSortingCartChangeHandler={props.resultsSortingCartChangeHandler}
      /*        filterRadiobuttonsOptions={props.filterRadiobuttonsOptions}
              filterRadiobuttonsName={props.filterRadiobuttonsName}*/
    />

  let renderResultsSortingPlaceOrder
  renderResultsSortingPlaceOrder =
    <ResultsSortingPlaceOrder
      filterState={props.filterState}
      resultsSortingPlaceOrderHandler={props.resultsSortingPlaceOrderHandler}
    />

  return (
    <>
      <MediaQuery minWidth={1280}>
        <div className={classes.results__head}>
          <h2 className={classes.results__title}>Результаты</h2>
          {renderResultsShowLabel}
          <div className={classes.results__sorting_wrapper}>
            {renderResultsSorting}
            {renderResultsSortingPlaceOrder}
          </div>
        </div>
      </MediaQuery>
      <MediaQuery minWidth={600} maxWidth={1279}>
        <div className={classes.tablet_results__head}>
          <h2 className={classes.tablet_results__title}>Результаты</h2>
          {renderResultsShowLabel}
          <div className={classes.tablet_results__sorting_wrapper}>
            {renderResultsSorting}
            {renderResultsSortingPlaceOrder}
          </div>
        </div>
      </MediaQuery>
      <MediaQuery maxWidth={599}>
        <div className={classes.mobile_results__head}>
          <h2 className={classes.mobile_results__title}>Результаты</h2>
          {renderResultsShowLabel}
          <div className={classes.mobile_results__sorting_wrapper}>
            {renderResultsSorting}
            {renderResultsSortingPlaceOrder}
          </div>
        </div>
      </MediaQuery>
    </>
  )
}

export default ResultsHead