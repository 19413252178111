import React from 'react'
import MediaQuery from 'react-responsive'
import './App.css';
import HomePage from "./Containers/HomePage/HomePage";

function App() {
  return (
    <>
      <MediaQuery minWidth={600}>
        <div className="App">
          <HomePage/>
        </div>
      </MediaQuery>

      <MediaQuery maxWidth={599}>
        <div className="mobile_App">
        {/*<div style={{width: '590px'}}>*/}
          <HomePage/>
        </div>
      </MediaQuery>
    </>
  )
}

export default App;
