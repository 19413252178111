import React, {Component} from "react"
import classes from "./FilterCheckboxLi.module.css"

class FilterCheckboxLi extends Component {
/*  constructor(props) {
    super(props);
    this.checkboxInput = React.createRef()
    this.focusCheckboxInput = this.focusCheckboxInput.bind(this)
  }
  
  focusCheckboxInput() {
    this.checkboxInput.current.focus()
  }*/
  
  
  // здесь прописать то что мы хотим передать в родителя, т.е. событие и уже в самом contentwrapper использовать данные из стейта eventCheckboxData в нашем обработчике по чекбоксу или просто без стейта а через коллбэк
  // eventCheckboxLiDataHandler = (event) => {
  // const eventCheckboxData = event.target
  // this.props.eventCheckboxDataHandler(eventCheckboxData)
  // }
  
  render() {
    const checked = [].concat(this.props.filterCheckboxChecked)
    
    return (
      
      this.props.filterCheckboxOptions.map((element, id) => {
        return (
          <li
            className={classes['Filter__checkboxes-item']}
            key={element}
            // key={element.toString()}
          >
            <label
              // key={element}
              // htmlFor={element}
              // ref={this.checkboxInput}
              // onClick={this.props.onChange}
              
              className = {
                checked[id]
                  ? classes.activity
                  : ''
              }
            >
              <input
                
                className="visually-hidden"
                type="checkbox"
                name={Object.keys(this.props.filterCheckboxName)}
                // value={Object.keys(element)}
                // id={Object.keys(element)}
                value={element}
                checked={checked[id]}
                //  defaultChecked={props.filterCheckboxChecked}
                onChange={this.props.onChange}
              />
              {/*<label htmlFor={Object.keys(element)}>{Object.values(element)}</label>*/}
              {element}
            </label>
          </li>
        )
      })
    )
  }
}

export default FilterCheckboxLi