import React from "react"
import "./FilterRange.scss"

class FilterRange extends React.Component {

/*
  state = {
    rangeSliderWidth: 0,
    rangeOffsetSliderWidht: 0,
    rangeMin: 0,
    rangeMax: 10000000,
    rangeMinValueBetween: 10,
    rangeCurrentMin: 55,
    rangeInputMin: 55,
    rangeCurrentMax: 3000000,
    rangeInputMax: 3000000,
  }
*/
  
  componentDidMount() {
    const { rangeCurrentMin, rangeCurrentMax, rangeMax } = this.props;
  
  
    // this.minValue.style.width = thisMinValueStyleWidth;
    this.minValue.style.width = (rangeCurrentMin*100)/rangeMax + "%";
    // this.minValue.style.width = 0.00055 + "%";
    // this.maxValue.style.width = thisMaxValueStyleWidth;
    this.maxValue.style.width = (rangeCurrentMax*100)/rangeMax + "%";
    // this.maxValue.style.width = 30 + "%";
    
    
    
    const rangeSliderWidth = this.slider.offsetWidth
    const rangeOffsetSliderWidht = this.slider.getBoundingClientRect().left
    this.props.filterRangeWidthHandler(rangeSliderWidth, rangeOffsetSliderWidht)
  }
  

  filterRangeSetMin = (e) => {
    const { rangeMin, rangeMax, rangeCurrentMax, rangeMinValueBetween } = this.props;
    const rangeInputMin = e.target.value;
    this.props.filterRangeInputMin(rangeInputMin)
    if((rangeInputMin >= rangeMin) && (rangeInputMin <= (rangeCurrentMax-rangeMinValueBetween))){
      const rangeCurrentMin = parseInt(rangeInputMin)
      this.props.filterRangeCurrentMin(rangeCurrentMin)
      this.minValue.style.width = (rangeInputMin*100)/rangeMax + "%";
    }
  }
  
  filterRangeChangeMinValue = (e) => {
    e.preventDefault();
    
    document.addEventListener('mousemove', this.filterRangeOnMouseMoveMin);
    document.addEventListener('mouseup', this.filterRangeOnMouseUpMin);
    
    document.addEventListener('touchmove', this.filterRangeOnMouseMoveMin);
    document.addEventListener('touchend', this.filterRangeOnMouseUpMin);
  }
  
  filterRangeOnMouseMoveMin = (e) => {
    const { rangeMin, rangeMax, rangeCurrentMax, rangeMinValueBetween} = this.props;
    const rangeSliderWidth = this.props.rangeSliderWidth
    const rangeOffsetSliderWidht = this.props.rangeOffsetSliderWidht
    
    const dragedWidht = e.clientX - rangeOffsetSliderWidht;
    const dragedWidhtInPercent = (dragedWidht*100)/rangeSliderWidth;
    const rangeCurrentMin = Math.abs(parseInt((rangeMax * dragedWidhtInPercent)/100));
    
    // console.log(e.pageX, e.clientX, rangeOffsetSliderWidht);
    //
    // console.log('Цена от и до: ',rangeCurrentMin , (rangeCurrentMax-rangeMinValueBetween));
    //
    // console.log((rangeMax * dragedWidhtInPercent)/100);
    //
    // console.log('this.slider.offsetWidth', this.slider.offsetWidth);
    // console.log('this.slider.offsetLeft', this.slider.offsetLeft);
    //
    // console.log('this.state.rangeSliderWidth', this.props.rangeSliderWidth);
    // console.log('this.state.rangeOffsetSliderWidht', this.props.rangeOffsetSliderWidht);
    //
    // console.log('this.slider.getBoundingClientRect().left', this.slider.getBoundingClientRect().left);
    //
    
    if( (rangeCurrentMin >= rangeMin) && (rangeCurrentMin <= (rangeCurrentMax-rangeMinValueBetween))){
      this.minValue.style.width = dragedWidhtInPercent + "%";
      this.minValue.dataset.content = rangeCurrentMin;
      this.props.filterRangeMouseMoveMinHandler(rangeCurrentMin)
    }
  }
  
  filterRangeOnMouseUpMin = () => {
    document.removeEventListener('mouseup', this.filterRangeOnMouseUpMin);
    document.removeEventListener('mousemove', this.filterRangeOnMouseMoveMin);
    
    document.removeEventListener('touchend', this.filterRangeOnMouseMoveMin);
    document.removeEventListener('touchmove', this.filterRangeOnMouseUpMin);
  }
  
  
  filterRangeSetMax = (e) => {
    const { rangeMin, rangeMax, rangeCurrentMin, rangeCurrentMax, rangeMinValueBetween } = this.props;
    const rangeInputMax = e.target.value;
    this.props.filterRangeSetInputMaxHandler(rangeInputMax)
    if((rangeInputMax >= rangeCurrentMin + rangeMinValueBetween) && (rangeInputMax <= rangeMax)){
      const rangeCurrentMax = parseInt(rangeInputMax)
      this.props.filterRangeSetCurrentMaxHandler(rangeCurrentMax)
      this.maxValue.style.width = (rangeInputMax*100)/rangeMax + "%";
    }
  }
  
  filterRangeChangeMaxValue = (e) => {
    e.preventDefault();
    
    document.addEventListener('mousemove', this.filterRangeOnMouseMoveMax);
    document.addEventListener('mouseup', this.filterRangeOnMouseUpMax);
    
    document.addEventListener('touchmove', this.filterRangeOnMouseMoveMax);
    document.addEventListener('touchend', this.filterRangeOnMouseUpMax);
  }
  
  filterRangeOnMouseMoveMax = (e) => {
    const { rangeMax, rangeCurrentMin, rangeMinValueBetween} = this.props;
    const rangeOffsetSliderWidht = this.props.rangeOffsetSliderWidht;
    const rangeSliderWidth = this.props.rangeSliderWidth
    const maxWalueThumb = this.maxValue;
    const dragedWidht = e.clientX - rangeOffsetSliderWidht;
    const dragedWidhtInPercent = (dragedWidht*100)/rangeSliderWidth;
    const rangeCurrentMax = Math.abs(parseInt((rangeMax * dragedWidhtInPercent)/100));
    
    if( (rangeCurrentMax >= (rangeCurrentMin + rangeMinValueBetween)) && (rangeCurrentMax <= rangeMax)){
      
      maxWalueThumb.style.width = dragedWidhtInPercent + "%";
      maxWalueThumb.dataset.content = rangeCurrentMax;
      this.props.filterRangeMouseMoveMaxHandler(rangeCurrentMax)
    }
  }
  
  filterRangeOnMouseUpMax = () => {
    document.removeEventListener('mouseup', this.onMouseUp);
    document.removeEventListener('mousemove', this.filterRangeOnMouseMoveMax);
    
    document.removeEventListener('touchend', this.onMouseUp);
    document.removeEventListener('touchmove', this.filterRangeOnMouseMoveMax);
  }
  
  filterRangeMaxForMin = () => {
    const rangeCurrentMax = this.props.rangeCurrentMax;
    const rangeMinValueBetween = this.props.rangeMinValueBetween;
    return rangeCurrentMax - rangeMinValueBetween;
  }
  
  filterRangeMinForMax = () => {
    const rangeCurrentMin = this.props.rangeCurrentMin;
    const rangeMinValueBetween = this.props.rangeMinValueBetween;
    return rangeCurrentMin + rangeMinValueBetween;
  }
  
  render() {
    
    
    const { rangeMin, rangeMax, rangeCurrentMin, rangeInputMin, rangeCurrentMax, rangeInputMax, rangeMinValueBetween, filterButtonResetClicked } = this.props;
  
    if (this.props.filterButtonResetClicked) {
      this.minValue.style.width = (rangeCurrentMin*100)/rangeMax + "%";
      this.maxValue.style.width = (rangeCurrentMax*100)/rangeMax + "%";
    }
    
    return (
      <div className="card">
        <div className="current-value">
          <label htmlFor="min-input">Цена, руб от: </label>
          <input
            id="min-input"
            type="number"
            onChange={this.filterRangeSetMin}
            value={rangeInputMin}
            min={rangeMin}
            max={this.filterRangeMaxForMin}/>
          
          <br/>
          <label htmlFor="max-input">до: </label>
          <input
            id="max-input"
            type="number"
            onChange={this.filterRangeSetMax}
            value={rangeInputMax}
            min={this.filterRangeMinForMax}
            max={rangeMax}/>
        
        </div>
        
        <div className="values">
          <div>{ rangeMin }</div>
          <div>{ rangeMax }</div>
        </div>
        
        <div ref={ref => this.slider = ref} id="slider">
          
          <div ref={ref => this.minValue = ref} id="min" data-content={rangeCurrentMin}>
            <div ref={ref => this.minValueDrag = ref} id="min-drag" onMouseDown ={this.filterRangeChangeMinValue} onTouchStart={this.filterRangeChangeMinValue}></div>
          </div>
          
          <div ref={ref => this.maxValue = ref} id="max" data-content={rangeCurrentMax}>
            <div ref={ref => this.maxValueDrag = ref} id="max-drag" onMouseDown={this.filterRangeChangeMaxValue} onTouchStart={this.filterRangeChangeMaxValue}></div>
          </div>
        
        </div>
      </div>
    )
  }
}








/*

//Рабочий вариант 16.09.2022

  componentDidMount() {
    const { rangeCurrentMin, rangeCurrentMax, rangeMax } = this.props;
    
    
    
    this.minValue.style.width = (rangeCurrentMin*100)/rangeMax + "%";
    // this.minValue.style.width = 0.00055 + "%";
    this.maxValue.style.width = (rangeCurrentMax*100)/rangeMax + "%";
    // this.maxValue.style.width = 30 + "%";
    
    
    
    const rangeSliderWidth = this.slider.offsetWidth
    const rangeOffsetSliderWidht = this.slider.getBoundingClientRect().left
    this.props.filterRangeWidthHandler(rangeSliderWidth, rangeOffsetSliderWidht)
  }
  

  filterRangeSetMin = (e) => {
    const { rangeMin, rangeMax, rangeCurrentMax, rangeMinValueBetween } = this.props;
    const rangeInputMin = e.target.value;
    this.props.filterRangeInputMin(rangeInputMin)
    if((rangeInputMin >= rangeMin) && (rangeInputMin <= (rangeCurrentMax-rangeMinValueBetween))){
      const rangeCurrentMin = parseInt(rangeInputMin)
      this.props.filterRangeCurrentMin(rangeCurrentMin)
      this.minValue.style.width = (rangeInputMin*100)/rangeMax + "%";
    }
  }
  
  filterRangeChangeMinValue = (e) => {
    e.preventDefault();
    
    document.addEventListener('mousemove', this.filterRangeOnMouseMoveMin);
    document.addEventListener('mouseup', this.filterRangeOnMouseUpMin);
    
    document.addEventListener('touchmove', this.filterRangeOnMouseMoveMin);
    document.addEventListener('touchend', this.filterRangeOnMouseUpMin);
  }
  
  filterRangeOnMouseMoveMin = (e) => {
    const { rangeMin, rangeMax, rangeCurrentMax, rangeMinValueBetween} = this.props;
    const rangeSliderWidth = this.props.rangeSliderWidth
    const rangeOffsetSliderWidht = this.props.rangeOffsetSliderWidht
    
    const dragedWidht = e.clientX - rangeOffsetSliderWidht;
    const dragedWidhtInPercent = (dragedWidht*100)/rangeSliderWidth;
    const rangeCurrentMin = Math.abs(parseInt((rangeMax * dragedWidhtInPercent)/100));
    
    console.log(e.pageX, e.clientX, rangeOffsetSliderWidht);
    
    console.log('Цена от и до: ',rangeCurrentMin , (rangeCurrentMax-rangeMinValueBetween));
    
    console.log((rangeMax * dragedWidhtInPercent)/100);
  
    console.log('this.slider.offsetWidth', this.slider.offsetWidth);
    console.log('this.slider.offsetLeft', this.slider.offsetLeft);
  
    console.log('this.state.rangeSliderWidth', this.props.rangeSliderWidth);
    console.log('this.state.rangeOffsetSliderWidht', this.props.rangeOffsetSliderWidht);
  
    console.log('this.slider.getBoundingClientRect().left', this.slider.getBoundingClientRect().left);
    
    
    if( (rangeCurrentMin >= rangeMin) && (rangeCurrentMin <= (rangeCurrentMax-rangeMinValueBetween))){
      this.minValue.style.width = dragedWidhtInPercent + "%";
      this.minValue.dataset.content = rangeCurrentMin;
      this.props.filterRangeMouseMoveMinHandler(rangeCurrentMin)
    }
  }
  
  filterRangeOnMouseUpMin = () => {
    document.removeEventListener('mouseup', this.filterRangeOnMouseUpMin);
    document.removeEventListener('mousemove', this.filterRangeOnMouseMoveMin);
    
    document.removeEventListener('touchend', this.filterRangeOnMouseMoveMin);
    document.removeEventListener('touchmove', this.filterRangeOnMouseUpMin);
  }
  
  
  filterRangeSetMax = (e) => {
    const { rangeMin, rangeMax, rangeCurrentMin, rangeCurrentMax, rangeMinValueBetween } = this.props;
    const rangeInputMax = e.target.value;
    this.props.filterRangeSetInputMaxHandler(rangeInputMax)
    if((rangeInputMax >= rangeCurrentMin + rangeMinValueBetween) && (rangeInputMax <= rangeMax)){
      const rangeCurrentMax = parseInt(rangeInputMax)
      this.props.filterRangeSetCurrentMaxHandler(rangeCurrentMax)
      this.maxValue.style.width = (rangeInputMax*100)/rangeMax + "%";
    }
  }
  
  filterRangeChangeMaxValue = (e) => {
    e.preventDefault();
    
    document.addEventListener('mousemove', this.filterRangeOnMouseMoveMax);
    document.addEventListener('mouseup', this.filterRangeOnMouseUpMax);
    
    document.addEventListener('touchmove', this.filterRangeOnMouseMoveMax);
    document.addEventListener('touchend', this.filterRangeOnMouseUpMax);
  }
  
  filterRangeOnMouseMoveMax = (e) => {
    const { rangeMax, rangeCurrentMin, rangeMinValueBetween} = this.props;
    const rangeOffsetSliderWidht = this.props.rangeOffsetSliderWidht;
    const rangeSliderWidth = this.props.rangeSliderWidth
    const maxWalueThumb = this.maxValue;
    const dragedWidht = e.clientX - rangeOffsetSliderWidht;
    const dragedWidhtInPercent = (dragedWidht*100)/rangeSliderWidth;
    const rangeCurrentMax = Math.abs(parseInt((rangeMax * dragedWidhtInPercent)/100));
    
    if( (rangeCurrentMax >= (rangeCurrentMin + rangeMinValueBetween)) && (rangeCurrentMax <= rangeMax)){
      
      maxWalueThumb.style.width = dragedWidhtInPercent + "%";
      maxWalueThumb.dataset.content = rangeCurrentMax;
      this.props.filterRangeMouseMoveMaxHandler(rangeCurrentMax)
    }
  }
  
  filterRangeOnMouseUpMax = () => {
    document.removeEventListener('mouseup', this.onMouseUp);
    document.removeEventListener('mousemove', this.filterRangeOnMouseMoveMax);
    
    document.removeEventListener('touchend', this.onMouseUp);
    document.removeEventListener('touchmove', this.filterRangeOnMouseMoveMax);
  }
  
  filterRangeMaxForMin = () => {
    const rangeCurrentMax = this.props.rangeCurrentMax;
    const rangeMinValueBetween = this.props.rangeMinValueBetween;
    return rangeCurrentMax - rangeMinValueBetween;
  }
  
  filterRangeMinForMax = () => {
    const rangeCurrentMin = this.props.rangeCurrentMin;
    const rangeMinValueBetween = this.props.rangeMinValueBetween;
    return rangeCurrentMin + rangeMinValueBetween;
  }
  
  render() {
    const { rangeMin, rangeMax, rangeCurrentMin, rangeInputMin, rangeCurrentMax, rangeInputMax, rangeMinValueBetween } = this.props;
    
    return (
      <div className="card">
        <div className="current-value">
          <label htmlFor="min-input">Цена, руб от: </label>
          <input
            id="min-input"
            type="number"
            onChange={this.filterRangeSetMin}
            value={rangeInputMin}
            min={rangeMin}
            max={this.filterRangeMaxForMin}/>
          
          <br/>
          <label htmlFor="max-input">до: </label>
          <input
            id="max-input"
            type="number"
            onChange={this.filterRangeSetMax}
            value={rangeInputMax}
            min={this.filterRangeMinForMax}
            max={rangeMax}/>
        
        </div>
        
        <div className="values">
          <div>{ rangeMin }</div>
          <div>{ rangeMax }</div>
        </div>
        
        <div ref={ref => this.slider = ref} id="slider">
          
          <div ref={ref => this.minValue = ref} id="min" data-content={rangeCurrentMin}>
            <div ref={ref => this.minValueDrag = ref} id="min-drag" onMouseDown ={this.filterRangeChangeMinValue} onTouchStart={this.filterRangeChangeMinValue}></div>
          </div>
          
          <div ref={ref => this.maxValue = ref} id="max" data-content={rangeCurrentMax}>
            <div ref={ref => this.maxValueDrag = ref} id="max-drag" onMouseDown={this.filterRangeChangeMaxValue} onTouchStart={this.filterRangeChangeMaxValue}></div>
          </div>
        
        </div>
      </div>
    )
  }
}


*/
export default FilterRange