import React, {Component} from "react"
import classes from "./Filter.module.css"
import FilterSelect from "../FilterElements/FilterSelect/FilterSelect";
import FilterButton from "../FilterElements/FilterButton/FilterButton";
import FilterRange from "../FilterElements/FilterRange/FilterRange";
import FilterButtonReset from "../FilterElements/FilterButtonReset/FilterButtonReset";
import FilterButtonTest from "../FilterElements/FilterButtonTest/FilterButtonTest";
import FilterUdlinitel from "./FilterUdlinitel/FilterUdlinitel";
import FilterButtonResetLocalStorage
  from "../FilterElements/FilterButtonResetLocalStorage/FilterButtonResetLocalStorage";
import FilterAvtVyklModul from "./FilterAvtVyklModul/FilterAvtVyklModul";
import MediaQuery from "react-responsive";

class Filter extends Component {

  render() {
    let renderForm
    renderForm =
      <form action="#" method="post">
        <FilterSelect
          filterSelectOptions={this.props.filterState.filterSelectCategory}
          filterSelectName={this.props.filterState.filterSelectCategoryName}
          filterSelectedCategory={this.props.filterSelectedCategory}
          onChange={this.props.onSelectCatChange}
        />
        <FilterRange
          filterState={this.props.filterState}
          filterRangeWidthHandler={this.props.filterRangeWidthHandler}
          filterRangeInputMin={this.props.filterRangeInputMin}
          filterRangeCurrentMin={this.props.filterRangeCurrentMin}
          filterRangeMouseMoveMinHandler={this.props.filterRangeMouseMoveMinHandler}
          filterRangeSetInputMaxHandler={this.props.filterRangeSetInputMaxHandler}
          filterRangeSetCurrentMaxHandler={this.props.filterRangeSetCurrentMaxHandler}
          filterRangeMouseMoveMaxHandler={this.props.filterRangeMouseMoveMaxHandler}
          rangeSliderWidth={this.props.rangeSliderWidth}
          rangeOffsetSliderWidht={this.props.rangeOffsetSliderWidht}
          rangeMin={this.props.rangeMin}
          rangeMax={this.props.rangeMax}
          rangeMinValueBetween={this.props.rangeMinValueBetween}
          rangeCurrentMin={this.props.rangeCurrentMin}
          rangeInputMin={this.props.rangeInputMin}
          rangeCurrentMax={this.props.rangeCurrentMax}
          rangeInputMax={this.props.rangeInputMax}
          filterButtonResetClicked={this.props.filterButtonResetClicked}
        />
        <FilterUdlinitel
          filterState={this.props.filterState}
          filterDisabled={this.props.filterState.filterDisabled}
          filterUdlinitelVisible={this.props.filterUdlinitelVisible}
          onCheckboxUdlinitelSocketsEventChange={this.props.onCheckboxUdlinitelSocketsEventChange}
          onCheckboxUdlinitelLengthEventChangeNew={this.props.onCheckboxUdlinitelLengthEventChangeNew}
          onCheckboxUdlinitelCurrentEventChange={this.props.onCheckboxUdlinitelCurrentEventChange}

          onCheckboxUdlinitelLoadEventChange={this.props.onCheckboxUdlinitelLoadEventChange}

          onCheckboxUdlinitelCableEventChange={this.props.onCheckboxUdlinitelCableEventChange}

          onSelectUdlinitelGroundingEventChange={this.props.onSelectUdlinitelGroundingEventChange}

          onSelectUdlinitelSwitchEventChange={this.props.onSelectUdlinitelSwitchEventChange}
          onCheckboxUdlinitelProtectionEventChange={this.props.onCheckboxUdlinitelProtectionEventChange}

          eventCheckboxDataHandler={this.props.eventCheckboxDataHandler}
        />
        <FilterAvtVyklModul
          filterState={this.props.filterState}
          filterDisabled={this.props.filterState.filterDisabled}
          filterAvtVykluchateliModulnyeVisible={this.props.filterAvtVykluchateliModulnyeVisible}
          eventCheckboxDataHandler={this.props.eventCheckboxDataHandler}
          onCheckboxAvtVyklModulPoleEventChange={this.props.onCheckboxAvtVyklModulPoleEventChange}
          onCheckboxAvtVyklModulCurrentEventChange={this.props.onCheckboxAvtVyklModulCurrentEventChange}

          onCheckboxAvtVyklModulBreakingCapacityEventChange={this.props.onCheckboxAvtVyklModulBreakingCapacityEventChange}
          onSelectAvtVyklModulBrandEventChange={this.props.onSelectAvtVyklModulBrandEventChange}
          onSelectAvtVyklModulSeriesEventChange={this.props.onSelectAvtVyklModulSeriesEventChange}

        />
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}>
          <FilterButton
            onFilterButtonClick={this.props.onFilterButtonClick}
          />
          <FilterButtonReset
            filterButtonResetShow={this.props.filterState.filterButtonResetShow}
            onFilterButtonResetClick={this.props.onFilterButtonResetClick}
          />
        </div>

        {/*Тестовые кнопки*/}
        {/*<div
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}>
          <FilterButtonTest
            onFilterButtonClick={this.props.onButtonClickTest}
          />
          <FilterButtonResetLocalStorage/>
        </div>*/}

      </form>


    return (
      <>
        <MediaQuery minWidth={1280}>
          <section className={classes.filter}>
            <h2 className={classes.filter__title}>Фильтр</h2>
            {renderForm}
          </section>
        </MediaQuery>
        <MediaQuery minWidth={600} maxWidth={1279}>
          <div className={classes.tablet_filter}>
            {/*<h2 className={classes.filter__title}>Фильтр</h2>*/}
            {renderForm}
          </div>
        </MediaQuery>
        <MediaQuery maxWidth={599}>
          <div className={classes.mobile_filter}>
            {/*<h2 className={classes.filter__title}>Фильтр</h2>*/}
            {renderForm}
          </div>
        </MediaQuery>

      </>
    )
  }
}

export default Filter