import React from "react";
import classes from "./ResultsSortingCatCart.module.css";
import ResultsSortingCat from "./ResultsSortingCat/ResultsSortingCat";
import ResultsSortingCart from "./ResultsSortingCart/ResultsSortingCart";
import Results from "../../../Results";

const ResultsSortingCatCart = (props) => {
  return (
    <div className={classes['sorting-cat-cart']}>
      <ResultsSortingCat
        filterState={props.filterState}
        resultsSortingCatChangeHandler={props.resultsSortingCatChangeHandler}
      />
      <ResultsSortingCart
        filterState={props.filterState}
        resultsSortingCartChangeHandler={props.resultsSortingCartChangeHandler}
      />
    </div>
  
  )
}

export default ResultsSortingCatCart