import React from "react"
import classes from "./AddToCartAmount.module.css"

const cls = [classes.button, classes.filter__button]

class AddToCartAmount extends React.Component {
  
  render() {
    return (
      <input
        //id={this.props.product.id}
        type="number"
/*        value={
          this.props.filterState.resultsSortingCartActive && this.props.filterState.inCartProducts.length !== 0
            ? this.props.inCartProductAmountAdded
            : this.props.inCartProductAmount
        }*/
        value={this.props.inCartProductAmount}
        className={cls.join(' ')}
        // onClick={() => this.props.eventAddToCartDataHandler(this.props.product.id)}
        onFocus={() => this.props.eventAddToCartDataHandler(this.props.product)}
        // onClick={() => this.props.eventAddToCartDataHandler(this.props.product)}
        onChange={this.props.inCartProductAmountChangeHandler}
      >
      </input>
    )
  }
}

export default AddToCartAmount

/*onClick или onFocus - при нажатии на input, из этого компонента в родительский, в стейт, через пропс eventAddToCartDataHandler передается id элемента, на котором произошло событие, далее в родительском компоненте вызывается коллбэк eventAddToCartDataHandler с данными eventAddToCartData, в которые мы передали this.props.product.id и далее в обработчике inCartProductAmountChangeHandler сравниеваем, если это переданное значение равно product.id в цикле map, тогда  применяем обработчик, который в стейт в продукт и в инпут записывает нужное количество товара для добавления в корзину*/