import React from "react";
import classes from "./ResultsSortingCat.module.css";
import ResultsSortingCatCartBtn from "../ResultsSortingCatCartBtn/ResultsSortingCatCartBtn";

const cls = [classes.button, classes.filter__button, classes['cat__button-active']]

const ResultsSortingCat = (props) => {
  props.filterState.resultsSortingCatActive
    ? cls.splice(2,1,classes['cat__button-active'])
    : cls.splice(2,1,classes['cat__button-initial'])
  return (
    
    /*    <ResultsSortingCatCartBtn
          ResultsSortingCatCartBtnName={'Каталог товаров'}
        />*/
    
    <button
      className={
        cls.join(' ')
      }
      type="button"
      onClick={props.resultsSortingCatChangeHandler}
    >
      Каталог товаров
    </button>
  
  )
}

export default ResultsSortingCat