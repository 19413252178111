import React from "react";
import classes from "./ResultsSortingCart.module.css";
import ResultsSortingCatCartBtn from "../ResultsSortingCatCartBtn/ResultsSortingCatCartBtn";

const cls = [classes.button, classes.filter__button, classes['cart__button-active']]

const ResultsSortingCart = (props) => {
  props.filterState.resultsSortingCartActive
    ? cls.splice(2, 1, classes['cart__button-active'])
    : cls.splice(2, 1, classes['cart__button-initial'])
  return (

    /*    <ResultsSortingCatCartBtn
          ResultsSortingCatCartBtnName={'Корзина'}
        />*/
    <button
      className={
        cls.join(' ')
      }
      type="button"
      onClick={props.resultsSortingCartChangeHandler}
    >
      Корзина
    </button>

  )
}

export default ResultsSortingCart