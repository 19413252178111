import React from "react";
import classes from "./ResultsSorting.module.css";
import ResultsSortingOrder from "./ResultsSortingOrder/ResultsSortingOrder";
import ResultsSortingCart from "./ResultsSortingFavs/ResultsSortingFavs";
import ResultsHead from "../ResultsHead";
import ResultsSortingCatCart from "./ResultsSortingCatCart/ResultsSortingCatCart";
import Results from "../../Results";
import MediaQuery from "react-responsive";

const ResultsSorting = (props) => {
  let renderResultsSorting
  renderResultsSorting =
    <form className={classes.sorting__form}>
      {/*<ResultsSortingOrder
          filterRadiobuttonsOptions={props.filterRadiobuttonsOptions}
          filterRadiobuttonsName={props.filterRadiobuttonsName}
        />*/}
      <ResultsSortingCatCart
        filterState={props.filterState}
        resultsSortingCatChangeHandler={props.resultsSortingCatChangeHandler}
        resultsSortingCartChangeHandler={props.resultsSortingCartChangeHandler}
      />
      {/*Кнопка показать избранные товары:*/}
      {/*<ResultsSortingFavs />*/}
    </form>

  return (
    <>
      <MediaQuery minWidth={1280}>
        <div className={classes.results__sorting}>
          {renderResultsSorting}
        </div>
      </MediaQuery>
      <MediaQuery minWidth={600} maxWidth={1279}>
        <div className={classes.mobile_results__sorting}>
          {renderResultsSorting}
        </div>
      </MediaQuery>
      <MediaQuery maxWidth={599}>
        <div className={classes.mobile_results__sorting}>
          {renderResultsSorting}
        </div>
      </MediaQuery>
    </>
  )
}

export default ResultsSorting