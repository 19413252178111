export const SELECT = 'SELECT'
export const ALL_FILTERS_VISIBLE = 'ALL_FILTERS_VISIBLE'
export const UDLINITEL_VISIBLE = 'UDLINITEL_VISIBLE'
export const VILKI_VISIBLE = 'VILKI_VISIBLE'
export const SCHTEPSELNYE_GNEZDA_VISIBLE = 'SCHTEPSELNYE_GNEZDA_VISIBLE'
export const VYKLUCHATELI_PROHODNYE_VISIBLE = 'VYKLUCHATELI_PROHODNYE_VISIBLE'
export const SHNURY_VISIBLE = 'SHNURY_VISIBLE'
export const TROINIKI_VISIBLE = 'TROINIKI_VISIBLE'
export const TELEPHONE_UDLINITEL_VISIBLE = 'TELEPHONE_UDLINITEL_VISIBLE'
export const KOLODKI_VISIBLE = 'KOLODKI_VISIBLE'
export const RAZJEM_PITANIYA_VISIBLE = 'RAZJEM_PITANIYA_VISIBLE'
export const AVT_VYKLUCHATELI_MODULNYE_VISIBLE = 'AVT_VYKLUCHATELI_MODULNYE_VISIBLE'
// export const ESTATE_VISIBLE = 'ESTATE_VISIBLE'
// export const CAMERA_VISIBLE = 'CAMERA_VISIBLE'
// export const LAPTOP_VISIBLE = 'LAPTOP_VISIBLE'
// export const CAR_VISIBLE = 'CAR_VISIBLE'
export const FILTER_RANGE_WIDTH = 'FILTER_RANGE_WIDTH'
export const FILTER_RANGE_INPUT_MIN = 'FILTER_RANGE_INPUT_MIN'
export const FILTER_RANGE_CURRENT_MIN = 'FILTER_RANGE_CURRENT_MIN'
export const FILTER_RANGE_MOUSE_MOVE_MIN = 'FILTER_RANGE_MOUSE_MOVE_MIN'
export const FILTER_RANGE_SET_INPUT_MAX = 'FILTER_RANGE_SET_INPUT_MAX'
export const FILTER_RANGE_CURRENT_MAX = 'FILTER_RANGE_CURRENT_MAX'
export const FILTER_RANGE_MOUSE_MOVE_MAX = 'FILTER_RANGE_MOUSE_MOVE_MAX'
export const FILTER_RANGE_RESET = 'FILTER_RANGE_RESET'

export const SORTING_CART_CHANGE_HANDLER = 'SORTING_CART_CHANGE_HANDLER'