import React, {Component} from "react"
import FilterCheckbox from "../../FilterElements/FilterCheckbox/FilterCheckbox";
import FilterSelect from "../../FilterElements/FilterSelect/FilterSelect";

class FilterAvtVyklModul extends Component {

  render() {
    return (
      this.props.filterAvtVykluchateliModulnyeVisible
        ?
        <>
          {/*АвтВыклМодульные Количество полюсов*/}
          <FilterCheckbox
            filterState={this.props.filterState}
            filterDisabled={this.props.filterDisabled}
            filterCheckboxName={this.props.filterState.filterCheckboxAvtVyklModulPoleName}
            filterCheckboxOptions={this.props.filterState.filterCheckboxAvtVyklModulPole}
            filterCheckboxChecked={this.props.filterState.filterCheckboxAvtVyklModulPoleChecked}
            onChange={this.props.onCheckboxAvtVyklModulPoleEventChange}
            eventCheckboxDataHandler={this.props.eventCheckboxDataHandler}
          />

          {/*АвтВыклМодульные Номинальный ток*/}
          <FilterCheckbox
            filterState={this.props.filterState}
            filterDisabled={this.props.filterDisabled}
            filterCheckboxName={this.props.filterState.filterCheckboxAvtVyklModulCurrentName}
            filterCheckboxOptions={this.props.filterState.filterCheckboxAvtVyklModulCurrent}
            filterCheckboxChecked={this.props.filterState.filterCheckboxAvtVyklModulCurrentChecked}
            onChange={this.props.onCheckboxAvtVyklModulCurrentEventChange}
            eventCheckboxDataHandler={this.props.eventCheckboxDataHandler}
          />

          {/*АвтВыклМодульные Отключающая способность*/}
          <FilterCheckbox
            filterState={this.props.filterState}
            filterDisabled={this.props.filterDisabled}
            filterCheckboxName={this.props.filterState.filterCheckboxAvtVyklModulBreakingCapacityName}
            filterCheckboxOptions={this.props.filterState.filterCheckboxAvtVyklModulBreakingCapacity}
            filterCheckboxChecked={this.props.filterState.filterCheckboxAvtVyklModulBreakingCapacityChecked}
            onChange={this.props.onCheckboxAvtVyklModulBreakingCapacityEventChange}
            eventCheckboxDataHandler={this.props.eventCheckboxDataHandler}
          />

          <FilterSelect
            filterState={this.props.filterState}
            filterSelectedCategory={this.props.filterState.filterSelectAvtVyklModulBrandSelected}
            filterDisabled={this.props.filterDisabled}
            filterSelectName={this.props.filterState.filterSelectAvtVyklModulBrandName}
            filterSelectOptions={this.props.filterState.filterSelectAvtVyklModulBrand}
            onChange={this.props.onSelectAvtVyklModulBrandEventChange}
          />

          <FilterSelect
            filterState={this.props.filterState}
            filterSelectedCategory={this.props.filterState.filterSelectAvtVyklModulSeriesSelected}
            filterDisabled={this.props.filterDisabled}
            filterSelectName={this.props.filterState.filterSelectAvtVyklModulSeriesName}
            filterSelectOptions={this.props.filterState.filterSelectAvtVyklModulSeries}
            onChange={this.props.onSelectAvtVyklModulSeriesEventChange}
          />
        </>
        : null
    )
  }
}

export default FilterAvtVyklModul