import React, {Component} from 'react'
import MediaQuery from 'react-responsive'
import classes from './ContentWrapper.module.css'
import Filter from "../Filter/FilterContainers/Filter"
import Results from "../Results/Results"
import {connect} from 'react-redux'
import products from '../../store/products'
import {send} from 'emailjs-com'
import {
  selectFilterCategory,
  allFiltersVisible,
  udlinitelVisible,
  filterRangeWidth,
  filterRangeInputMinimal,
  filterRangeCurrentMinimal,
  filterRangeMouseMoveMinimalHandler,
  filterRangeSetInputMaximumHandler,
  filterRangeSetCurrentMaximumHandler,
  filterRangeMouseMoveMaximumHandler,
  filterRangeReset,
  vilkiVisible,
  shtepselnyeGnezdaVisible,
  vykluchateliProhodnyeVisible,
  shnuryVisible,
  troinikiVisible,
  telephoneUdlinitelVisible,
  kolodkiVisible,
  razjemPitaniyaVisible,
  inCartProductAmountChange,
  avtVykluchateliModulnyeVisible,
} from "../../store/actions/actions"
import PersonalDataPolicy from "../PersonalDataPolicy/PersonalDataPolicy";
import PersonalDataCookies from "../PersonalDataCookies/PersonalDataCookies";

const filterCheckboxUdlinitel = [
  ...new Set(products.map((product) => {
    return (
      product.sockets
    )
  }))
].sort(function (a, b) {
  return a - b;
}).filter(item => item !== null)
  .filter(item => item !== undefined)
  .filter(item => item !== '')

const filterCheckboxUdlinitelChecked = new Array([
  ...new Set(products.map((product) => {
    return (
      product.sockets
    )
  }))
].sort(function (a, b) {
  return a - b;
}).filter(item => item !== null)
  .filter(item => item !== undefined)
  .filter(item => item !== '')
  .length)
  .fill(false)

const filterCheckboxUdlinitelCheckedTest = [
  ...[
      ...new Set(products.map((product) => {
        return (
          product.sockets
        )
      }))
    ].sort(function (a, b) {
      return a - b;
    }).filter(item => item !== null)
      .filter(item => item !== undefined)
      .filter(item => item !== '')
      .map((item, id) => (
          {
            [item]:
              new Array([
                ...new Set(products.map((product) => {
                  return (
                    product.sockets
                  )
                }))
              ].sort(function (a, b) {
                return a - b;
              }).filter(item => item !== null)
                .filter(item => item !== undefined)
                .filter(item => item !== '')
                .length)
                .fill(false)
                [id]
          }
        )
      ),
]

const filterCheckboxUdlinitelLengthNew = [
  ...new Set(products.map((product) => {
    return (
      product.cableLength
    )
  }))
]
  .filter(item => item !== null)
  .filter(item => item !== undefined)
  .filter(item => item !== '')
  .sort(function (a, b) {
    return a - b;
  })
  .map(item => item + 'м')

const filterCheckboxUdlinitelLengthCheckedNew = new Array([
  ...new Set(products.map((product) => {
    return (
      product.cableLength
    )
  }))
].sort(function (a, b) {
  return a - b;
}).filter(item => item !== null)
  .filter(item => item !== undefined)
  .filter(item => item !== '')
  .length)
  .fill(false)

const filterCheckboxUdlinitelLengthCheckedTestNew = [
  ...[
      ...new Set(products.map((product) => {
        return (
          product.cableLength
        )
      }))
    ]
      .filter(item => item !== null)
      .filter(item => item !== undefined)
      .filter(item => item !== '')
      .sort(function (a, b) {
        return a - b;
      })
      .map(item => item + 'м')
      .map((item, id) => (
          {
            [item]:
              new Array([
                ...new Set(products.map((product) => {
                  return (
                    product.cableLength
                  )
                }))
              ].sort(function (a, b) {
                return a - b;
              }).filter(item => item !== null)
                .filter(item => item !== undefined)
                .filter(item => item !== '')
                .length)
                .fill(false)
                [id]
          }
        )
      ),
]

const filterCheckboxUdlinitelCurrent = [
  ...new Set(products.map((product) => {
    return (
      product.ratedCurrent
    )
  }))
].sort(function (a, b) {
  return a - b;
}).filter(item => item !== null)
  .filter(item => item !== undefined)
  .filter(item => item !== '')

const filterCheckboxUdlinitelCurrentChecked = new Array([
  ...new Set(products.map((product) => {
    return (
      product.ratedCurrent
    )
  }))
].sort(function (a, b) {
  return a - b;
}).filter(item => item !== null)
  .filter(item => item !== undefined)
  .filter(item => item !== '')
  .length)
  .fill(false)


const filterCheckboxUdlinitelCurrentCheckedTest = [
  ...[
      ...new Set(products.map((product) => {
        return (
          product.ratedCurrent
        )
      }))
    ]
      .filter(item => item !== null)
      .filter(item => item !== undefined)
      .filter(item => item !== '')
      .sort(function (a, b) {
        return a - b;
      })
      .map((item, id) => (
          {
            [item]:
              new Array([
                ...new Set(products.map((product) => {
                  return (
                    product.ratedCurrent
                  )
                }))
              ].sort(function (a, b) {
                return a - b;
              }).filter(item => item !== null)
                .filter(item => item !== undefined)
                .filter(item => item !== '')
                .length)
                .fill(false)
                [id]
          }
        )
      ),
]

function filterCheckboxCommon(option, unit) {
  return (
    [
      ...new Set(products.map((product) => {
        return (
          product[option]
        )
      }))
    ]
      .filter(item => item !== null)
      .filter(item => item !== undefined)
      .filter(item => item !== '')
      .sort(function (a, b) {
        return a - b;
      })
      .map(item =>
        unit
          ? item + unit
          : item
      )
  )
}

function filterCheckboxCheckedCommon(option) {
  return (
    new Array([
      ...new Set(products.map((product) => {
        return (
          product[option]
        )
      }))
    ]
      .sort(function (a, b) {
        return a - b;
      })
      .filter(item => item !== null)
      .filter(item => item !== undefined)
      .filter(item => item !== '')
      .length)
      .fill(false)
  )
}


function filterCheckboxCheckedTestCommon(option, unit) {
  return (
    [
      ...[
          ...new Set(products.map((product) => {
            return (
              product[option]
            )
          }))
        ]
          .filter(item => item !== null)
          .filter(item => item !== undefined)
          .filter(item => item !== '')
          .sort(function (a, b) {
            return a - b;
          })
          .map(item =>
            unit
              ? item + unit
              : item
          )
          .map((item, id) => (
              {
                [item]:
                  new Array([
                    ...new Set(products.map((product) => {
                      return (
                        product[option]
                      )
                    }))
                  ].sort(function (a, b) {
                    return a - b;
                  }).filter(item => item !== null)
                    .filter(item => item !== undefined)
                    .filter(item => item !== '')
                    .length)
                    .fill(false)
                    [id]
              }
            )
          ),
    ]
  )
}

function filterSelectCommon(option, cat) {


  return (
   [
      ' Все варианты',
      ...new Set(
        products
          .filter(product => product.cat === cat)
          .map((product) => {
        return (
          product[option]
        )
      }))
    ]
      .filter(item => item !== null)
      .filter(item => item !== undefined)
      .filter(item => item !== '')
      .sort()
  )

}

class ContentWrapper extends Component {

  state = {
    eventCheckboxData: '',
    eventAddToCartData: '',

    inCartProducts: [],

    orderPopUpActive: false,

    resultsSortingCatActive: true,
    resultsSortingCartActive: false,

    filterButtonResetShow: false,
    filterDisabled: false,

    filterSelectCategoryName: 'Категория товаров',

    filterSelectCategory: [
      'Все товары',
      ...new Set(products.map((product) => {
        return (
          product.cat
        )
      }))
    ],
    filterSelectCategorySelected: 'Все товары',

    filterCheckboxUdlinitelSockets: {
      udlinitelSockets: 'Количество розеток'
    },

    filterCheckboxUdlinitel: filterCheckboxUdlinitel,

    filterCheckboxUdlinitelChecked: filterCheckboxUdlinitelChecked,

    filterCheckboxUdlinitelCheckedTest: filterCheckboxUdlinitelCheckedTest,

    eventCheckbox: [],

    filterCheckboxUdlinitelLengthName: {
      udlinitelLength: 'Длина'
    },

    filterCheckboxUdlinitelLengthNew: filterCheckboxUdlinitelLengthNew,

    filterCheckboxUdlinitelLengthCheckedNew: filterCheckboxUdlinitelLengthCheckedNew,

    filterCheckboxUdlinitelLengthCheckedTestNew: filterCheckboxUdlinitelLengthCheckedTestNew,

    eventCheckboxLengthNew: [],

    filterCheckboxUdlinitelCurrentName: {
      udlinitelCurrent: 'Номинальный ток'
    },

    filterCheckboxUdlinitelCurrent: filterCheckboxUdlinitelCurrent,

    filterCheckboxUdlinitelCurrentChecked: filterCheckboxUdlinitelCurrentChecked,

    filterCheckboxUdlinitelCurrentCheckedTest: filterCheckboxUdlinitelCurrentCheckedTest,

    eventCheckboxCurrent: [],

    filterCheckboxUdlinitelLoadName: {
      udlinitelLoad: 'Максимальная нагрузка'
    },

    filterCheckboxUdlinitelLoad: filterCheckboxCommon('load', ' кВт'),

    filterCheckboxUdlinitelLoadChecked: filterCheckboxCheckedCommon('load'),

    filterCheckboxUdlinitelLoadCheckedTest: filterCheckboxCheckedTestCommon('load', ' кВт'),

    eventCheckboxLoad: [],

    filterCheckboxUdlinitelCableName: {
      udlinitelCable: 'Провод'
    },

    filterCheckboxUdlinitelCable: filterCheckboxCommon('cable'),

    filterCheckboxUdlinitelCableChecked: filterCheckboxCheckedCommon('cable'),

    filterCheckboxUdlinitelCableCheckedTest: filterCheckboxCheckedTestCommon('cable'),

    eventCheckboxCable: [],

    filterSelectUdlinitelGroundName: 'Заземление',

    filterSelectUdlinitelGround: filterSelectCommon('grounding', "Удлинители и сетевые фильтры"),

    filterSelectUdlinitelGroundAny: false,

    filterSelectUdlinitelGroundSelected: '',

    filterSelectUdlinitelSwitchName: 'Выключатель на корпусе',

    filterSelectUdlinitelSwitch: filterSelectCommon('switchButton', "Удлинители и сетевые фильтры"),

    filterSelectUdlinitelSwitchAny: false,

    filterSelectUdlinitelSwitchSelected: '',

    filterCheckboxUdlinitelProtectionName: {
      udlinitelProtection: 'Степень защиты'
    },

    filterCheckboxUdlinitelProtection: filterCheckboxCommon('protectionDegree'),

    filterCheckboxUdlinitelProtectionChecked: filterCheckboxCheckedCommon('protectionDegree'),

    filterCheckboxUdlinitelProtectionCheckedTest: filterCheckboxCheckedTestCommon('protectionDegree'),

    eventCheckboxProtection: [],

    //АвтВыклМодульные Количество полюсов
    filterCheckboxAvtVyklModulPoleName: {
      avtVyklModulPole: 'Количество полюсов'
    },

    filterCheckboxAvtVyklModulPole: filterCheckboxCommon('poleNumber', ''),

    filterCheckboxAvtVyklModulPoleChecked: filterCheckboxCheckedCommon('poleNumber'),

    filterCheckboxAvtVyklModulPoleCheckedTest: filterCheckboxCheckedTestCommon('poleNumber', ''),

    eventCheckboxAvtVyklModulPole: [],

    //АвтВыклМодульные Номинальный ток
    filterCheckboxAvtVyklModulCurrentName: {
      avtVyklModulCurrent: 'Номинальный ток'
    },

    filterCheckboxAvtVyklModulCurrent: filterCheckboxCommon('current', 'А'),

    filterCheckboxAvtVyklModulCurrentChecked: filterCheckboxCheckedCommon('current'),

    filterCheckboxAvtVyklModulCurrentCheckedTest: filterCheckboxCheckedTestCommon('current', 'А'),

    eventCheckboxAvtVyklModulCurrent: [],

    //АвтВыклМодульные Отключающая способность
    filterCheckboxAvtVyklModulBreakingCapacityName: {
      avtVyklModulBreakingCapacity: 'Отключающая способность'
    },

    filterCheckboxAvtVyklModulBreakingCapacity: filterCheckboxCommon('breakingCapacity', ''),

    filterCheckboxAvtVyklModulBreakingCapacityChecked: filterCheckboxCheckedCommon('breakingCapacity'),

    filterCheckboxAvtVyklModulBreakingCapacityCheckedTest: filterCheckboxCheckedTestCommon('breakingCapacity', ''),

    eventCheckboxAvtVyklModulBreakingCapacity: [],

    //АвтВыклМодульные Бренд
    filterSelectAvtVyklModulBrandName: 'Производитель',

    filterSelectAvtVyklModulBrand: filterSelectCommon('brand', "Автоматические выключатели модульные"),

    filterSelectAvtVyklModulBrandAny: false,

    filterSelectAvtVyklModulBrandSelected: '',

    //АвтВыклМодульные Серия
    filterSelectAvtVyklModulSeriesName: 'Серия',

    filterSelectAvtVyklModulSeries: filterSelectCommon('series', "Автоматические выключатели модульные"),

    filterSelectAvtVyklModulSeriesAny: false,

    filterSelectAvtVyklModulSeriesSelected: '',

    filterRadiobuttonsSorting: [
      {cat: 'Каталог товаров'},
      {cart: 'Корзина'},
      // {new: 'Новые'},
    ],
    filterRadiobuttonsSortingName: {
      sortingOrder: 'Показать:'
    },

    productsFiltered: [],

    // products: products,
    products: localStorage.getItem('inCartProductsLocal')
      ? JSON.parse(window.localStorage.getItem('inCartProductsLocal'))
        .map(inCartProduct => {
          return (
            {
              ...inCartProduct,
              visible: true
            }
          )
        })
      : products
    ,

    toSend: {
      clientName: '',
      clientTelephone: '',
      clientCompany: '',
      clientINN: '',
      clientKPP: '',
      clientAddress: '',
      clientBankAccount: '',
      clientBankBIK: '',
      // clientOrder: [],
    },

    sendButtonClicked: false,

    personalDataPolicyShow: false,
    personalDataCookiesShow: !window.localStorage.getItem('cookieDate') || ((Number(window.localStorage.getItem('cookieDate')) + 10512000000) < Date.now()),
    //если убрать ! вначале и добавить в конце ?false :true то будет то же самое

    paginationCurrentPage: 1,
    paginationCurrentPageCart: 1,
    paginationProductsPerPage: 20,
    upperPageBound: 5,
    lowerPageBound: 0,
    isPrevBtnActive: 'disabled',
    isNextBtnActive: '',
    pageBound: 5,
  }

  buttonClickTestHandler = () => {

    // console.log('TEST - this.props = ', this.props)
    window.setTimeout(() => console.log('this.state=', this.state), 1000)
    window.setTimeout((eventCheckbox = 'eventCheckboxLengthNew') => console.log(`TEST - this.state.${[eventCheckbox]}= `, this.state[eventCheckbox]), 1125)
    window.setTimeout((eventCheckbox = 'eventCheckboxLoad') => console.log(`TEST - this.state.${[eventCheckbox]}= `, this.state[eventCheckbox]), 1130)
    window.setTimeout((eventCheckbox = 'eventCheckboxCable') => console.log(`TEST - this.state.${[eventCheckbox]}= `, this.state[eventCheckbox]), 1130)
    // console.log('window.localStorage= ', window.localStorage)
    // console.log('window.localStorage.inCartProductsLocal= ', JSON.parse(window.localStorage.getItem('inCartProductsLocal')))
  }

  filterButtonClickHandler = () => {

    window.setTimeout(() => this.filterRangeChangeHandler(), 1400)

    if (this.state.eventCheckbox.length !== 0) {
      window.setTimeout(() => this.eventCheckboxUdlinitelSocketsChangeHandler(), 100)
    }

    if (this.state.eventCheckboxLengthNew.length !== 0) {
      window.setTimeout(() => this.eventCheckboxUdlinitelLengthChangeHandlerNew(), 200)
    }

    if (this.state.eventCheckboxCurrent.length !== 0) {
      window.setTimeout(() => this.eventCheckboxUdlinitelCurrentChangeHandler(), 300)
    }

    if (this.state.eventCheckboxLoad.length !== 0) {
      window.setTimeout(() => this.eventCheckboxChangeHandlerCommon('eventCheckboxLoad', 'load', ' кВт'), 400)
    }

    if (this.state.eventCheckboxCable.length !== 0) {
      window.setTimeout(() => this.eventCheckboxChangeHandlerCommon('eventCheckboxCable', 'cable', ''), 500)
    }

    if (this.state.eventCheckboxProtection.length !== 0) {
      window.setTimeout(() => this.eventCheckboxChangeHandlerCommon('eventCheckboxProtection', 'protectionDegree', ''), 500)
    }

    if (this.state.filterSelectUdlinitelGroundSelected !== '') {
      window.setTimeout(() => this.eventSelectChangeHandler('filterSelectUdlinitelGroundSelected', 'grounding'), 600)
    }

    if (this.state.filterSelectUdlinitelSwitchSelected !== '') {
      window.setTimeout(() => this.eventSelectChangeHandler('filterSelectUdlinitelSwitchSelected', 'switchButton'), 700)
    }

    if (this.state.eventCheckboxAvtVyklModulPole.length !== 0) {
      window.setTimeout(() => this.eventCheckboxChangeHandlerCommon('eventCheckboxAvtVyklModulPole', 'poleNumber', ''), 515)
    }

    if (this.state.eventCheckboxAvtVyklModulCurrent.length !== 0) {
      window.setTimeout(() => this.eventCheckboxChangeHandlerCommon('eventCheckboxAvtVyklModulCurrent', 'current', 'А'), 520)
    }

    if (this.state.eventCheckboxAvtVyklModulBreakingCapacity.length !== 0) {
      window.setTimeout(() => this.eventCheckboxChangeHandlerCommon('eventCheckboxAvtVyklModulBreakingCapacity', 'breakingCapacity', ''), 525)
    }

    if (this.state.filterSelectAvtVyklModulBrandSelected !== '') {
      window.setTimeout(() => this.eventSelectChangeHandler('filterSelectAvtVyklModulBrandSelected', 'brand'), 530)
    }

    if (this.state.filterSelectAvtVyklModulSeriesSelected !== '') {
      window.setTimeout(() => this.eventSelectChangeHandler('filterSelectAvtVyklModulSeriesSelected', 'series'), 535)
    }

    this.setState({
      filterDisabled: true,
      filterButtonResetShow: true,
    })

  }

  filterButtonResetClickHandler = () => {
    if (this.props.filterSelectedCategory === 'Все товары') {
      this.selectShowAll('Все товары')
    }
    if (this.props.filterUdlinitelVisible
      // && this.state.productsFiltered.length !== 0
    ) {
      this.selectShowAll('Удлинители и сетевые фильтры')
      this.setState({
        //сброс Количество розеток
        filterDisabled: false,
        filterCheckboxUdlinitel: filterCheckboxUdlinitel,
        filterCheckboxUdlinitelChecked: filterCheckboxUdlinitelChecked,
        filterCheckboxUdlinitelCheckedTest: filterCheckboxUdlinitelCheckedTest,
        eventCheckbox: [],

        //сброс Длина
        filterCheckboxUdlinitelLengthNew: filterCheckboxUdlinitelLengthNew,
        filterCheckboxUdlinitelLengthCheckedNew: filterCheckboxUdlinitelLengthCheckedNew,
        filterCheckboxUdlinitelLengthCheckedTestNew: filterCheckboxUdlinitelLengthCheckedTestNew,
        eventCheckboxLengthNew: [],

        //сброс Номинальный ток
        filterCheckboxUdlinitelCurrent: filterCheckboxUdlinitelCurrent,
        filterCheckboxUdlinitelCurrentChecked: filterCheckboxUdlinitelCurrentChecked,
        filterCheckboxUdlinitelCurrentCheckedTest: filterCheckboxUdlinitelCurrentCheckedTest,
        eventCheckboxCurrent: [],

        //сброс Максимальная нагрузка
        filterCheckboxUdlinitelLoad: filterCheckboxCommon('load', ' кВт'),
        filterCheckboxUdlinitelLoadChecked: filterCheckboxCheckedCommon('load'),
        filterCheckboxUdlinitelLoadCheckedTest: filterCheckboxCheckedTestCommon('load', ' кВт'),
        eventCheckboxLoad: [],

        //сброс Провод
        filterCheckboxUdlinitelCable: filterCheckboxCommon('cable'),
        filterCheckboxUdlinitelCableChecked: filterCheckboxCheckedCommon('cable'),
        filterCheckboxUdlinitelCableCheckedTest: filterCheckboxCheckedTestCommon('cable'),
        eventCheckboxCable: [],

        //сброс Степень защиты
        filterCheckboxUdlinitelProtection: filterCheckboxCommon('protectionDegree'),
        filterCheckboxUdlinitelProtectionChecked: filterCheckboxCheckedCommon('protectionDegree'),
        filterCheckboxUdlinitelProtectionCheckedTest: filterCheckboxCheckedTestCommon('protectionDegree'),
        eventCheckboxProtection: [],

        //сброс Заземление
        filterSelectUdlinitelGround: filterSelectCommon('grounding', "Удлинители и сетевые фильтры"),
        filterSelectUdlinitelGroundAny: false,
        filterSelectUdlinitelGroundSelected: '',

        //сброс Выключатель на корпусе
        filterSelectUdlinitelSwitch: filterSelectCommon('switchButton', "Удлинители и сетевые фильтры"),
        filterSelectUdlinitelSwitchAny: false,
        filterSelectUdlinitelSwitchSelected: '',
      })
    }
    if (this.props.filterVilkiVisible && this.state.productsFiltered.length !== 0) {
      this.selectShowAll('Вилки')
    }
    if (this.props.filterShtepselnyeGnezdaVisible && this.state.productsFiltered.length !== 0) {
      this.selectShowAll('Штепсельные гнезда')
    }
    if (this.props.filterVykluchateliProhodnyeVisible && this.state.productsFiltered.length !== 0) {
      this.selectShowAll('Выключатели проходные (бра)')
    }
    if (this.props.filterShnuryVisible && this.state.productsFiltered.length !== 0) {
      this.selectShowAll('Шнуры с вилкой')
    }
    if (this.props.filterTroinikiVisible && this.state.productsFiltered.length !== 0) {
      this.selectShowAll('Тройники и разветвители')
    }
    if (this.props.filterTelephoneUdlinitelVisible && this.state.productsFiltered.length !== 0) {
      this.selectShowAll('Телефонные удлинители')
    }
    if (this.props.filterKolodkiVisible && this.state.productsFiltered.length !== 0) {
      this.selectShowAll('Колодки')
    }
    if (this.props.filterRazjemPitaniyaVisible && this.state.productsFiltered.length !== 0) {
      this.selectShowAll('Разъемы питания 2.1х5.5 с клеммной колодкой')
    }
    if (this.props.filterAvtVykluchateliModulnyeVisible
      // && this.state.productsFiltered.length !== 0
    ) {
      this.selectShowAll('Автоматические выключатели модульные')
      this.setState({
        filterDisabled: false,

        //сброс АвтВыклМодульные Количество полюсов
        filterCheckboxAvtVyklModulPole: filterCheckboxCommon('poleNumber'),
        filterCheckboxAvtVyklModulPoleChecked: filterCheckboxCheckedCommon('poleNumber'),
        filterCheckboxAvtVyklModulPoleCheckedTest: filterCheckboxCheckedTestCommon('poleNumber'),
        eventCheckboxAvtVyklModulPole: [],

        //сброс АвтВыклМодульные Номинальный ток
        filterCheckboxAvtVyklModulCurrent: filterCheckboxCommon('current', 'А'),
        filterCheckboxAvtVyklModulCurrentChecked: filterCheckboxCheckedCommon('current'),
        filterCheckboxAvtVyklModulCurrentCheckedTest: filterCheckboxCheckedTestCommon('current', 'А'),
        eventCheckboxAvtVyklModulCurrent: [],

        //сброс АвтВыклМодульные Отключающая способность
        filterCheckboxAvtVyklModulBreakingCapacity: filterCheckboxCommon('breakingCapacity', ''),
        filterCheckboxAvtVyklModulBreakingCapacityChecked: filterCheckboxCheckedCommon('breakingCapacity'),
        filterCheckboxAvtVyklModulBreakingCapacityCheckedTest: filterCheckboxCheckedTestCommon('breakingCapacity', ''),
        eventCheckboxAvtVyklModulBreakingCapacity: [],

        //сброс АвтВыклМодульные Бренд
        filterSelectAvtVyklModulBrand: filterSelectCommon('brand', "Автоматические выключатели модульные"),
        filterSelectAvtVyklModulBrandAny: false,
        filterSelectAvtVyklModulBrandSelected: '',

        //сброс АвтВыклМодульные Серия
        filterSelectAvtVyklModulSeries: filterSelectCommon('series', "Автоматические выключатели модульные"),
        filterSelectAvtVyklModulSeriesAny: false,
        filterSelectAvtVyklModulSeriesSelected: '',


      })
    }

    this.setState({
      filterDisabled: false,
      filterButtonResetShow: false,
      paginationCurrentPage: 1,
    })

    this.props.onFilterRangeReset()

    // this.scrollToTop()

  }


  // Фильтр по категории - selectCat начало
  selectCatChangeHandler = (event) => {
    event.preventDefault()
    this.props.onSelectFilterCategory(event)

    //Если объект стейта возвращает массив объектов и нужно изменить какое-то свойство объекта из массива (https://stackoverflow.com/questions/49477547/setstate-of-an-array-of-objects-in-react):
    this.setState({
      productsFiltered: this.state.products.map((product) => {
        return (
          event.target.value !== product.cat && event.target.value !== 'Все товары'
            ? Object.assign(product, {visible: false})
            : Object.assign(product, {visible: true})
        )
      }),
      filterSelectCategorySelected: event.target.value,
      paginationCurrentPage: 1,
    })
    if (event.target.value === 'Все товары') {
      this.props.onAllFiltersVisible()
    }
    if (event.target.value === 'Удлинители и сетевые фильтры') {
      this.props.onUdlinitelVisible()
    }
    if (event.target.value === 'Вилки') {
      this.props.onVilkiVisible()
    }
    if (event.target.value === 'Штепсельные гнезда') {
      this.props.onShtepselnyeGnezdaVisible()
    }
    if (event.target.value === 'Выключатели проходные (бра)') {
      this.props.onVykluchateliProhodnyeVisible()
    }
    if (event.target.value === 'Шнуры с вилкой') {
      this.props.onShnuryVisible()
    }
    if (event.target.value === 'Тройники и разветвители') {
      this.props.onTroinikiVisible()
    }
    if (event.target.value === 'Телефонные удлинители') {
      this.props.onTelephoneUdlinitelVisible()
    }
    if (event.target.value === 'Колодки') {
      this.props.onKolodkiVisible()
    }
    if (event.target.value === 'Разъемы питания 2.1х5.5 с клеммной колодкой') {
      this.props.onRazjemPitaniyaVisible()
    }
    if (event.target.value === 'Автоматические выключатели модульные') {
      this.props.onAvtVykluchateliModulnyeVisible()
    }

    // console.log('event.target.value=', event.target.value)
  }
  // Фильтр по категории - selectCat конец


  // Фильтр по цене - FilterRange начало
  filterRangeWidthHandler = (rangeSliderWidth, rangeOffsetSliderWidht) => {
    this.props.onFilterRangeWidth(rangeSliderWidth, rangeOffsetSliderWidht)
  }

  filterRangeInputMin = (rangeInputMin) => {
    this.props.onFilterRangeInputMinimal(rangeInputMin)
  }

  filterRangeCurrentMin = (rangeCurrentMin) => {
    this.props.onFilterRangeCurrentMinimal(rangeCurrentMin)
  }

  filterRangeMouseMoveMinHandler = (rangeCurrentMin) => {
    this.props.onFilterRangeMouseMoveMinimalHandler(rangeCurrentMin)
  }

  filterRangeSetInputMaxHandler = (rangeInputMax) => {
    this.props.onFilterRangeSetInputMaximumHandler(rangeInputMax)
  }

  filterRangeSetCurrentMaxHandler = (rangeCurrentMax) => {
    this.props.onFilterRangeSetCurrentMaximumHandler(rangeCurrentMax)
  }

  filterRangeMouseMoveMaxHandler = (rangeCurrentMax) => {
    this.props.onFilterRangeMouseMoveMaximumHandler(rangeCurrentMax)
  }

  filterRangeChangeHandler = () => {
    const rangeInputMin = Number(this.props.rangeInputMin)
    const rangeInputMax = Number(this.props.rangeInputMax)

    let currentProductsArrs = [
      'products',
      'productsFiltered',
      'inCartProducts'
    ]
    currentProductsArrs.map(currentProductsArr => {
      return (
        this.setState({
          [currentProductsArr]: this.state[currentProductsArr]
            .map((product) => {
                return (
                  product.visible && product.price >= rangeInputMin && product.price <= rangeInputMax
                    ?
                    {
                      ...product,
                      visible: true,
                    }
                    :
                    {
                      ...product,
                      visible: false,
                    }
                )
              }
            )
        })
      )
    })
  }

  // Фильтр по цене - FilterRange конец


  selectShowAll(option) {

    let currentProductsArrs = [
      'products',
      // 'productsFiltered',
      'inCartProducts'
    ]

    currentProductsArrs.map(currentProductsArr => {
      return (
        this.setState({
          [currentProductsArr]: this.state[currentProductsArr]
            .map((product) => {
              return (
                option === 'Все товары'
                  ?
                  {...product, visible: true}
                  :
                  product.cat === option
                    ? {...product, visible: true}
                    : {...product, visible: false}
              )
            }),
          productsFiltered: this.state.products
            .map((product) => {
              return (
                product.cat === option
                  ? {...product, visible: true}
                  : {...product, visible: false}
              )
            })
            .filter(product => product.visible),
        })
      )
    })

  }

  eventCheckboxDataHandler = (eventCheckboxData) => {
    this.setState({
      eventCheckboxData: eventCheckboxData
    })

  }

  eventAddToCartDataHandler = (eventAddToCartData) => {
    this.setState({
      eventAddToCartData: eventAddToCartData
    })

  }

  // Фильтр чекбокс количество розеток - начало
  eventCheckboxUdlinitelSocketsChangeHandler = () => {
    if ((this.state.filterSelectCategorySelected === this.state.filterSelectCategory[0] && this.state.productsFiltered.length === 0)) {
      this.setState({
        productsFiltered: this.state.products
          .map((product) => {
            return (
              this.state.eventCheckbox.includes(product.sockets)
                ? {...product, visible: true}
                : {...product, visible: false}
            )
          })
          .filter(product => product.visible),
      })
    } else {
      this.setState({
        productsFiltered: this.state.productsFiltered
          .map((product) => {
            return (
              this.state.eventCheckbox.includes(product.sockets) && this.state.filterSelectCategorySelected === product.cat
                ? {...product, visible: true}
                : {...product, visible: false}
            )
          })
          .filter(product => product.visible),
      })
    }
  }


  checkboxUdlinitelSocketsChangeHandler = (event) => {
    const filterCheckboxUdlinitelChecked = this.state.filterCheckboxUdlinitelChecked
    const filterCheckboxUdlinitelCheckedTest = this.state.filterCheckboxUdlinitelCheckedTest
    const eventCheckbox = this.state.eventCheckbox

    const target = event.target
    const value = target.value
    const name = target.name

    // if (event.target.name === 'udlinitelSockets') {
    if (name === 'udlinitelSockets') {
      // if (!this.state.eventCheckbox.includes(event.target.value)) {
      if (!eventCheckbox.includes(value)) {

        this.setState({
          //eventCheckbox: [...eventCheckbox, event.target.value],
          eventCheckbox: [...eventCheckbox, value],
          filterCheckboxUdlinitelCheckedTest: filterCheckboxUdlinitelCheckedTest.map(elem =>
            Object.fromEntries(
              Object.entries(elem)
                .map(([option, show]) => option //=== event.target.value
                  === value
                  ? [option, show = true]
                  : [option, show]
                )
            )
          ),
          filterCheckboxUdlinitelChecked: filterCheckboxUdlinitelCheckedTest.map(elem =>
            Object.values(
              Object.fromEntries(
                Object.entries(elem)
                  .map(([option, show]) =>
                    //option === event.target.value
                    option === value
                      ? [option, show = true]
                      : [option, show]
                  )
              )
            )[0]
          )
        })
      } else {
        this.setState({
          // eventCheckbox: eventCheckbox.filter(item => item !== event.target.value),
          eventCheckbox: eventCheckbox.filter(item => item !== value),
          filterCheckboxUdlinitelCheckedTest: filterCheckboxUdlinitelCheckedTest.map(elem =>
            Object.fromEntries(
              Object.entries(elem)
                .map(([option, show]) =>
                  //option === event.target.value
                  option === value
                    ? [option, show = false]
                    : [option, show]
                )
            )
          ),
          filterCheckboxUdlinitelChecked: filterCheckboxUdlinitelCheckedTest.map(elem =>
            Object.values(
              Object.fromEntries(
                Object.entries(elem)
                  .map(([option, show]) =>
                    // option === event.target.value
                    option === value
                      ? [option, show = false]
                      : [option, show]
                  )
              )
            )[0]
          )
        })
      }
      // console.log('target=', target)
      // console.log('value=', value)
      // console.log('name=', name)
      // console.log('event.target.value=', event.target.value)
      // window.setTimeout(() => console.log('eventCheckbox=', this.state.eventCheckbox), 1000)
      // window.setTimeout(() => console.log('filterCheckboxUdlinitelChecked=', this.state.filterCheckboxUdlinitelChecked), 1000)
      // window.setTimeout(() => console.log('filterCheckboxUdlinitelCheckedTest=', this.state.filterCheckboxUdlinitelCheckedTest), 1000)
      // window.setTimeout(() => console.log('event.target=', event.target), 1400)
      // window.setTimeout(() => console.log('event.target.name=', event.target.name), 1410)
    }
  }
// Фильтр чекбокс количество розеток - конец


  // Фильтр чекбокс длина - начало
  // не работает сбросить фильтр, не сбрасывается фильтр и при выборе все товары

  eventCheckboxUdlinitelLengthChangeHandlerNew = () => {
    if ((this.state.filterSelectCategorySelected === this.state.filterSelectCategory[0] && this.state.productsFiltered.length === 0)) {
      this.setState({
        productsFiltered: this.state.products
          .map((product) => {
            return (
              this.state.eventCheckboxLengthNew.includes(product.cableLength + 'м')
                ? {...product, visible: true}
                : {...product, visible: false}
            )
          })
          .filter(product => product.visible),
      })
    } else {
      this.setState({
        productsFiltered: this.state.productsFiltered
          .map((product) => {
            return (
              this.state.eventCheckboxLengthNew.includes(product.cableLength + 'м') && this.state.filterSelectCategorySelected === product.cat
                ? {...product, visible: true}
                : {...product, visible: false}
            )
          })
          .filter(product => product.visible),
      })
    }
  }

  checkboxUdlinitelLengthChangeHandlerNew = (event) => {
    const filterCheckboxUdlinitelLengthCheckedNew = this.state.filterCheckboxUdlinitelLengthCheckedNew
    const filterCheckboxUdlinitelLengthCheckedTestNew = this.state.filterCheckboxUdlinitelLengthCheckedTestNew
    const eventCheckboxLengthNew = this.state.eventCheckboxLengthNew

    const target = event.target
    const value = target.value
    const name = target.name

    if (!this.state.eventCheckboxLengthNew.includes(value)) {

      this.setState({
        // eventCheckboxLength: [...eventCheckboxLength, event.target.value],
        eventCheckboxLengthNew: [...eventCheckboxLengthNew, value],
        filterCheckboxUdlinitelLengthCheckedTestNew: filterCheckboxUdlinitelLengthCheckedTestNew.map(elem =>
          Object.entries(elem)[0][0] === value
            ?
            Object.fromEntries(
              [
                [
                  Object.entries(elem)[0][0],
                  Object.entries(elem)[0][1] = true
                ],
              ]
            )
            : Object.fromEntries(
            [
              [
                Object.entries(elem)[0][0],
                Object.entries(elem)[0][1],
              ],
            ]
            )
        ),
        filterCheckboxUdlinitelLengthCheckedNew: filterCheckboxUdlinitelLengthCheckedTestNew.map(elem =>
          Object.values(
            Object.entries(elem)[0][0] === value
              ?
              Object.fromEntries(
                [
                  [
                    Object.entries(elem)[0][0],
                    Object.entries(elem)[0][1] = true
                  ],
                ]
              )
              : Object.fromEntries(
              [
                [
                  Object.entries(elem)[0][0],
                  Object.entries(elem)[0][1],
                ],
              ]
              )
          )[0]
        ),
      })
    } else {
      this.setState({
        eventCheckboxLengthNew: eventCheckboxLengthNew.filter(item => item !== value),
        filterCheckboxUdlinitelLengthCheckedTestNew: filterCheckboxUdlinitelLengthCheckedTestNew.map
        (elem =>
          Object.entries(elem)[0][0] === value
            ? Object.fromEntries(
            [
              [
                Object.entries(elem)[0][0],
                Object.entries(elem)[0][1] = false
              ],
            ]
            )
            : Object.fromEntries(
            [
              [
                Object.entries(elem)[0][0],
                Object.entries(elem)[0][1],
              ],
            ]
            )
        ),

        filterCheckboxUdlinitelLengthCheckedNew: filterCheckboxUdlinitelLengthCheckedTestNew.map
        (elem =>
          Object.values(
            Object.entries(elem)[0][0] === value
              ? Object.fromEntries(
              [
                [
                  Object.entries(elem)[0][0],
                  Object.entries(elem)[0][1] = false
                ],
              ]
              )
              : Object.fromEntries(
              [
                [
                  Object.entries(elem)[0][0],
                  Object.entries(elem)[0][1],
                ],
              ]
              )
          )[0]
        ),
      })
    }
    // window.setTimeout(() => console.log('eventCheckboxLengthNew=', this.state.eventCheckboxLengthNew), 1000)
    // window.setTimeout(() => console.log('filterCheckboxUdlinitelLengthCheckedNew=', this.state.filterCheckboxUdlinitelLengthCheckedNew), 1000)
    // window.setTimeout(() => console.log('filterCheckboxUdlinitelLengthCheckedTestNew=', this.state.filterCheckboxUdlinitelLengthCheckedTestNew), 1000)
    // window.setTimeout(() => console.log('event.target=', event.target), 1400)
    // window.setTimeout(() => console.log('event.target.name=', event.target.name), 1410)
    // console.log('event.target.value=', event.target.value)
  }

  // Фильтр чекбокс номинальныйТок - начало
  eventCheckboxUdlinitelCurrentChangeHandler = () => {
    if ((this.state.filterSelectCategorySelected === this.state.filterSelectCategory[0] && this.state.productsFiltered.length === 0)) {
      this.setState({
        productsFiltered: this.state.products
          .map((product) => {
            return (
              this.state.eventCheckboxCurrent.includes(product.ratedCurrent)
                ? {...product, visible: true}
                : {...product, visible: false}
            )
          })
          .filter(product => product.visible),
      })
    } else {
      this.setState({
        productsFiltered: this.state.productsFiltered
          .map((product) => {
            return (
              this.state.eventCheckboxCurrent.includes(product.ratedCurrent) && this.state.filterSelectCategorySelected === product.cat
                ? {...product, visible: true}
                : {...product, visible: false}
            )
          })
          .filter(product => product.visible),
      })
    }
  }

  checkboxUdlinitelCurrentChangeHandler = (event) => {
    const filterCheckboxUdlinitelCurrentChecked = this.state.filterCheckboxUdlinitelCurrentChecked
    const filterCheckboxUdlinitelCurrentCheckedTest = this.state.filterCheckboxUdlinitelCurrentCheckedTest
    const eventCheckboxCurrent = this.state.eventCheckboxCurrent

    const target = event.target
    const value = target.value
    const name = target.name

    if (!this.state.eventCheckboxCurrent.includes(value)) {

      this.setState({
        // eventCheckboxLength: [...eventCheckboxLength, event.target.value],
        eventCheckboxCurrent: [...eventCheckboxCurrent, value],
        filterCheckboxUdlinitelCurrentCheckedTest: filterCheckboxUdlinitelCurrentCheckedTest.map(elem =>
          Object.entries(elem)[0][0] === value
            ?
            Object.fromEntries(
              [
                [
                  Object.entries(elem)[0][0],
                  Object.entries(elem)[0][1] = true
                ],
              ]
            )
            : Object.fromEntries(
            [
              [
                Object.entries(elem)[0][0],
                Object.entries(elem)[0][1],
              ],
            ]
            )
        ),
        filterCheckboxUdlinitelCurrentChecked: filterCheckboxUdlinitelCurrentCheckedTest.map(elem =>
          Object.values(
            Object.entries(elem)[0][0] === value
              ?
              Object.fromEntries(
                [
                  [
                    Object.entries(elem)[0][0],
                    Object.entries(elem)[0][1] = true
                  ],
                ]
              )
              : Object.fromEntries(
              [
                [
                  Object.entries(elem)[0][0],
                  Object.entries(elem)[0][1],
                ],
              ]
              )
          )[0]
        ),
      })
    } else {
      this.setState({
        eventCheckboxCurrent: eventCheckboxCurrent.filter(item => item !== value),
        filterCheckboxUdlinitelCurrentCheckedTest: filterCheckboxUdlinitelCurrentCheckedTest.map
        (elem =>
          Object.entries(elem)[0][0] === value
            ? Object.fromEntries(
            [
              [
                Object.entries(elem)[0][0],
                Object.entries(elem)[0][1] = false
              ],
            ]
            )
            : Object.fromEntries(
            [
              [
                Object.entries(elem)[0][0],
                Object.entries(elem)[0][1],
              ],
            ]
            )
        ),

        filterCheckboxUdlinitelCurrentChecked: filterCheckboxUdlinitelCurrentCheckedTest.map
        (elem =>
          Object.values(
            Object.entries(elem)[0][0] === value
              ? Object.fromEntries(
              [
                [
                  Object.entries(elem)[0][0],
                  Object.entries(elem)[0][1] = false
                ],
              ]
              )
              : Object.fromEntries(
              [
                [
                  Object.entries(elem)[0][0],
                  Object.entries(elem)[0][1],
                ],
              ]
              )
          )[0]
        ),
      })
    }
  }


  // Фильтр чекбокс максимальная нагрузка - общий - начало

  eventCheckboxChangeHandlerCommon = (eventCheckbox, option, unit) => {
    if ((this.state.filterSelectCategorySelected === this.state.filterSelectCategory[0] && this.state.productsFiltered.length === 0)) {
      this.setState({
        productsFiltered: this.state.products
          .map((product) => {
            return (
              this.state[eventCheckbox].includes(product[option] + unit)
                ? {...product, visible: true}
                : {...product, visible: false}
            )
          })
          .filter(product => product.visible),
      })
    } else {
      this.setState({
        productsFiltered: this.state.productsFiltered
          .map((product) => {
            return (
              this.state[eventCheckbox].includes(product[option] + unit) && this.state.filterSelectCategorySelected === product.cat
                ? {...product, visible: true}
                : {...product, visible: false}
            )
          })
          .filter(product => product.visible),
      })
    }
  }

  checkboxChangeHandlerCommon = (
    filterCheckboxChecked,
    filterCheckboxCheckedTest,
    eventCheckbox,
    event) => {

    const filterCheckboxCheckedCommon = this.state[filterCheckboxChecked]
    const filterCheckboxCheckedTestCommon = this.state[filterCheckboxCheckedTest]
    const eventCheckboxCommon = this.state[eventCheckbox]

    const target = event.target
    const value = target.value
    const name = target.name

    if (!eventCheckboxCommon.includes(value)) {

      this.setState({
        // eventCheckboxLength: [...eventCheckboxLength, event.target.value],

        [eventCheckbox]: [...eventCheckboxCommon, value],

        [filterCheckboxCheckedTest]: filterCheckboxCheckedTestCommon.map(elem =>
          Object.entries(elem)[0][0] === value
            ?
            Object.fromEntries(
              [
                [
                  Object.entries(elem)[0][0],
                  Object.entries(elem)[0][1] = true
                ],
              ]
            )
            : Object.fromEntries(
            [
              [
                Object.entries(elem)[0][0],
                Object.entries(elem)[0][1],
              ],
            ]
            )
        ),
        [filterCheckboxChecked]: filterCheckboxCheckedTestCommon.map(elem =>
          Object.values(
            Object.entries(elem)[0][0] === value
              ?
              Object.fromEntries(
                [
                  [
                    Object.entries(elem)[0][0],
                    Object.entries(elem)[0][1] = true
                  ],
                ]
              )
              : Object.fromEntries(
              [
                [
                  Object.entries(elem)[0][0],
                  Object.entries(elem)[0][1],
                ],
              ]
              )
          )[0]
        ),
      })
    } else {
      this.setState({
        [eventCheckbox]: eventCheckboxCommon.filter(item => item !== value),
        [filterCheckboxCheckedTest]: filterCheckboxCheckedTestCommon.map
        (elem =>
          Object.entries(elem)[0][0] === value
            ? Object.fromEntries(
            [
              [
                Object.entries(elem)[0][0],
                Object.entries(elem)[0][1] = false
              ],
            ]
            )
            : Object.fromEntries(
            [
              [
                Object.entries(elem)[0][0],
                Object.entries(elem)[0][1],
              ],
            ]
            )
        ),

        [filterCheckboxChecked]: filterCheckboxCheckedTestCommon.map
        (elem =>
          Object.values(
            Object.entries(elem)[0][0] === value
              ? Object.fromEntries(
              [
                [
                  Object.entries(elem)[0][0],
                  Object.entries(elem)[0][1] = false
                ],
              ]
              )
              : Object.fromEntries(
              [
                [
                  Object.entries(elem)[0][0],
                  Object.entries(elem)[0][1],
                ],
              ]
              )
          )[0]
        ),
      })
    }
  }

  // Фильтр селект - начало
  eventSelectChangeHandler = (filterSelected, option) => {
    const productsFiltered = []
    if (this.state.productsFiltered.length === 0) {
      productsFiltered.push(...this.state.products)
    } else {
      productsFiltered.push(...this.state.productsFiltered)
    }

    this.setState({
      productsFiltered: productsFiltered
        .map((product) => {
          return (
            this.state[filterSelected] === product[option]
              ? {...product, visible: true}
              : {...product, visible: false}
          )
        })
        .filter(product => product.visible)
    })
  }

  selectChangeHandler = (filterSelected, event) => {
    this.setState({
      [filterSelected]: event.target.value
    })
  }
  // Фильтр селект - конец

  inCartProductAmountChangeHandler = (event) => {
    let currentProductsArrs = [
      'products',
      'productsFiltered',
      'inCartProducts'
    ]

    let eventTargetValue = event.target.value

    currentProductsArrs.map(currentProductsArr => {
      return (
        this.setState({
          [currentProductsArr]: this.state[currentProductsArr]
            .map((product) => {
              return (
                product.id === this.state.eventAddToCartData.id
                  ?
                  {
                    ...product,
                    inCartVisible: true,
                    inCartProductAmount: eventTargetValue < 0
                      ? eventTargetValue * -1
                      : eventTargetValue
                    ,
                  }
                  :
                  {...product}
              )
            })
        })
      )
    })
  }

  inCartProductAmountAddHandler = () => {
    let currentProductsArrs = [
      'products',
      'productsFiltered',
      'inCartProducts'
    ]

    currentProductsArrs.map(currentProductsArr => {
      return (
        this.setState({
          [currentProductsArr]: this.state[currentProductsArr]
            .map((product) => {
              return (
                product.id === this.state.eventAddToCartData.id
                  ?
                  {
                    ...product,
                    inCartProductAmountAdded: Number(product.inCartProductAmountAdded) + Number(product.inCartProductAmount),
                  }
                  :
                  {...product}
              )
            })
        })
      )
    })
  }


  inCartProductAmountRecalculateHandler = () => {
    let currentProductsArrs = [
      'products',
      'productsFiltered',
      'inCartProducts'
    ]

    currentProductsArrs.map(currentProductsArr => {
      return (
        this.setState({
          [currentProductsArr]: this.state[currentProductsArr]
            .map((product) => {
              return (
                product.id === this.state.eventAddToCartData.id
                  ?
                  {
                    ...product,
                    inCartProductAmountAdded: Number(product.inCartProductAmount),
                  }
                  : {...product}

              )
            }),
        })
      )
    })

    window.setTimeout(() =>
      this.inCartProductsLocalStorageClearAddHandler(), 200)
  }

  inCartProductDeleteHandler = () => {
    let currentProductsArrs = [
      'products',
      'productsFiltered',
      'inCartProducts'
    ]

    currentProductsArrs.map(currentProductsArr => {
      return (
        this.setState({
          [currentProductsArr]: this.state[currentProductsArr]
            .map((product) => {
              return (
                product.id === this.state.eventAddToCartData.id
                  ?
                  {
                    ...product,
                    inCartProductAmount: 0,
                    inCartProductAmountAdded: 0,
                    inCartVisible: false,
                  }
                  : {...product}
              )
            }),
        })
      )
    })

    window.setTimeout(() =>
      this.inCartProductsLocalStorageClearAddHandler(), 200)
  }

  resultsSortingCatChangeHandler = () => {
    this.setState({
      resultsSortingCatActive: true,
      resultsSortingCartActive: false
    })
  }

  resultsSortingCartChangeHandler = (event) => {

    this.setState({
      resultsSortingCatActive: false,
      resultsSortingCartActive: true,
      inCartProducts: this.state.products.filter(product => product.inCartVisible),
      paginationCurrentPageCart: 1,
    })
    window.setTimeout(() =>
      this.inCartProductsLocalStorageClearAddHandler(), 1300)

    this.props.onInCartProductAmountChange(this.state.products)


  }

  inCartProductsLocalStorageAddHandler = () => {
    window.localStorage.setItem('inCartProductsLocal', JSON.stringify(this.state.products))
  }

  resultsSortingPlaceOrderHandler = () => {
    this.setState({
      orderPopUpActive: !this.state.orderPopUpActive
    })
  }

  inCartProductsLocalStorageClearAddHandler = () => {
    window.localStorage.removeItem('inCartProductsLocal')
    window.localStorage.setItem('inCartProductsLocal', JSON.stringify(this.state.products))
  }


  resultsCartInfoSubmitHandler = (event) => {
    event.preventDefault()
    send(
      'service_nonwpvs',
      'template_w2t9pkh',
      this.state.toSend,
      'W2ZJ6CbSzoXMOY3kP',
    ).then((response) => {
      // console.log('SUCCESS!', response.status, response.text)
    })
      .catch((err) => {
        // console.log('FAILED...', err)
      })
    this.setState({
      sendButtonClicked: true
    })

  }

  resultsCartInfoInputHandler = (event) => {
    this.setState({
      toSend: {
        ...this.state.toSend,
        clientOrder: this.state.inCartProducts
          .map(product => {
            return (
              `
              Код: ${product.skuInternal}
              Артикул: ${product.skuManufacturer}
              Наименование: ${product.title}
              Кол-во: ${product.inCartProductAmountAdded}
              Цена: ${product.price}
              `
            )
          }).join(" === ")
        ,
        clientOrderTotalPrice: this.state.inCartProducts.reduce(function (sum, poduct) {
          return sum + (poduct.inCartProductAmountAdded * poduct.price);
        }, 0.0).toFixed(2)
        ,
        [event.target.name]: event.target.value,
      }
    })
  }

  personalDataPolicyHandler = () => {
    this.setState({
      personalDataPolicyShow: !this.state.personalDataPolicyShow
    })
  }

  personalDataCookiesHandler = () => {
    window.localStorage.setItem('cookieDate', Date.now())
    this.setState({
      personalDataCookiesShow: !this.state.personalDataCookiesShow
    })
  }

  paginationHandleClick = (event) => {
    this.setState({
      paginationCurrentPage: Number(event.target.id)
    })
  }

  paginationHandleClickCart = (event) => {
    this.setState({
      paginationCurrentPageCart: Number(event.target.id)
    })
  }

  btnIncrementClick = () => {
    let listid = this.state.upperPageBound + 1
    this.setState({
      upperPageBound: this.state.upperPageBound + this.state.pageBound,
      lowerPageBound: this.state.lowerPageBound + this.state.pageBound,
      paginationCurrentPage: listid,
    })
    // this.setPrevAndNextBtnClass(listid);
  }
  btnDecrementClick = () => {
    let listid = this.state.upperPageBound - this.state.pageBound
    this.setState({
      upperPageBound: this.state.upperPageBound - this.state.pageBound,
      lowerPageBound: this.state.lowerPageBound - this.state.pageBound,
      paginationCurrentPage: listid
    })
    // this.setPrevAndNextBtnClass(listid);
  }
  btnPrevClick = () => {
    if((this.state.paginationCurrentPage -1)%this.state.pageBound === 0 ){
      this.setState({
        upperPageBound: this.state.upperPageBound - this.state.pageBound,
        lowerPageBound: this.state.lowerPageBound - this.state.pageBound,
      })
    }
    this.setState({
      paginationCurrentPage: this.state.paginationCurrentPage - 1,
    })
    // this.setPrevAndNextBtnClass(listid);
  }
  btnNextClick = () => {
    if((this.state.paginationCurrentPage +1) > this.state.upperPageBound ){
      this.setState({
        upperPageBound: this.state.upperPageBound + this.state.pageBound,
        lowerPageBound: this.state.lowerPageBound + this.state.pageBound,
      })
    }
    if (this.state.paginationCurrentPage === this.state.upperPageBound) {
      this.setState({
        isNextBtnActive: 'disabled',
      })
    }
      this.setState({
        paginationCurrentPage: this.state.paginationCurrentPage + 1,
      })

    // this.setPrevAndNextBtnClass(listid);
  }

  scrollToTop = () => {
    this.myRefTop.current.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  }

  //Перезагрузить сайт? Возможно изменения не сохранятся
/*  componentDidMount() {
    window.onbeforeunload = function () {
      return true
    }
  }*/

/*  componentDidUnmount() {
    window.onbeforeunload = null
  }*/


  render() {

    let renderFilter
    renderFilter =
      <Filter
        filterState={this.state}
        filterAllFiltersVisible={this.props.filterAllFiltersVisible}
        filterUdlinitelVisible={this.props.filterUdlinitelVisible}
        filterAvtVykluchateliModulnyeVisible={this.props.filterAvtVykluchateliModulnyeVisible}
        onSelectCatChange={this.selectCatChangeHandler}

        filterRangeWidthHandler={this.filterRangeWidthHandler}
        filterRangeInputMin={this.filterRangeInputMin}
        filterRangeCurrentMin={this.filterRangeCurrentMin}
        filterRangeMouseMoveMinHandler={this.filterRangeMouseMoveMinHandler}
        filterRangeSetInputMaxHandler={this.filterRangeSetInputMaxHandler}
        filterRangeSetCurrentMaxHandler={this.filterRangeSetCurrentMaxHandler}
        filterRangeMouseMoveMaxHandler={this.filterRangeMouseMoveMaxHandler}

        rangeSliderWidth={this.props.rangeSliderWidth}
        rangeOffsetSliderWidht={this.props.rangeOffsetSliderWidht}
        rangeMin={this.props.rangeMin}
        rangeMax={this.props.rangeMax}
        rangeMinValueBetween={this.props.rangeMinValueBetween}
        rangeCurrentMin={this.props.rangeCurrentMin}
        rangeInputMin={this.props.rangeInputMin}
        rangeCurrentMax={this.props.rangeCurrentMax}
        rangeInputMax={this.props.rangeInputMax}
        filterButtonResetClicked={this.props.filterButtonResetClicked}

        onCheckboxUdlinitelSocketsEventChange={this.checkboxUdlinitelSocketsChangeHandler}
        onCheckboxUdlinitelLengthEventChangeNew={this.checkboxUdlinitelLengthChangeHandlerNew}
        onCheckboxUdlinitelCurrentEventChange={this.checkboxUdlinitelCurrentChangeHandler}
        onCheckboxUdlinitelLoadEventChange={(event) => this.checkboxChangeHandlerCommon('filterCheckboxUdlinitelLoadChecked', 'filterCheckboxUdlinitelLoadCheckedTest', 'eventCheckboxLoad', event)}
        onCheckboxUdlinitelCableEventChange={(event) => this.checkboxChangeHandlerCommon('filterCheckboxUdlinitelCableChecked', 'filterCheckboxUdlinitelCableCheckedTest', 'eventCheckboxCable', event)}
        onSelectUdlinitelGroundingEventChange={(event) => this.selectChangeHandler('filterSelectUdlinitelGroundSelected', event)}
        onSelectUdlinitelSwitchEventChange={(event) => this.selectChangeHandler('filterSelectUdlinitelSwitchSelected', event)}
        onCheckboxUdlinitelProtectionEventChange={(event) => this.checkboxChangeHandlerCommon('filterCheckboxUdlinitelProtectionChecked', 'filterCheckboxUdlinitelProtectionCheckedTest', 'eventCheckboxProtection', event)}

        onCheckboxAvtVyklModulPoleEventChange={(event) => this.checkboxChangeHandlerCommon('filterCheckboxAvtVyklModulPoleChecked', 'filterCheckboxAvtVyklModulPoleCheckedTest', 'eventCheckboxAvtVyklModulPole', event)}

        onCheckboxAvtVyklModulCurrentEventChange={(event) => this.checkboxChangeHandlerCommon('filterCheckboxAvtVyklModulCurrentChecked', 'filterCheckboxAvtVyklModulCurrentCheckedTest', 'eventCheckboxAvtVyklModulCurrent', event)}

        onCheckboxAvtVyklModulBreakingCapacityEventChange={(event) => this.checkboxChangeHandlerCommon('filterCheckboxAvtVyklModulBreakingCapacityChecked', 'filterCheckboxAvtVyklModulBreakingCapacityCheckedTest', 'eventCheckboxAvtVyklModulBreakingCapacity', event)}

        onSelectAvtVyklModulBrandEventChange={(event) => this.selectChangeHandler('filterSelectAvtVyklModulBrandSelected', event)}
        onSelectAvtVyklModulSeriesEventChange={(event) => this.selectChangeHandler('filterSelectAvtVyklModulSeriesSelected', event)}

        onFilterButtonClick={this.filterButtonClickHandler}
        onFilterButtonResetClick={this.filterButtonResetClickHandler}
        onButtonClickTest={this.buttonClickTestHandler}

        eventCheckboxDataHandler={this.eventCheckboxDataHandler}
      />

      let renderResults
      renderResults =
        <Results
          filterState={this.state}
          filterRadiobuttonsSorting={this.state.filterRadiobuttonsSorting}
          filterRadiobuttonsSortingName={this.state.filterRadiobuttonsSortingName}
          products={this.state.products}
          productsFiltered={this.state.productsFiltered}
          inCartProductAmountChangeHandler={this.inCartProductAmountChangeHandler}
          inCartProductAmountAddHandler={this.inCartProductAmountAddHandler}
          inCartProductAmountRecalculateHandler={this.inCartProductAmountRecalculateHandler}
          inCartProductDeleteHandler={this.inCartProductDeleteHandler}
          eventAddToCartDataHandler={this.eventAddToCartDataHandler}
          resultsSortingCatChangeHandler={this.resultsSortingCatChangeHandler}
          resultsSortingCartChangeHandler={this.resultsSortingCartChangeHandler}
          resultsSortingPlaceOrderHandler={this.resultsSortingPlaceOrderHandler}
          resultsCartInfoSubmitHandler={this.resultsCartInfoSubmitHandler}
          resultsCartInfoInputHandler={this.resultsCartInfoInputHandler}
          personalDataPolicyHandler={this.personalDataPolicyHandler}
          paginationHandleClick={this.paginationHandleClick}
          paginationHandleClickCart={this.paginationHandleClickCart}
          btnNextClick={this.btnNextClick}
          btnPrevClick={this.btnPrevClick}
          btnDecrementClick={this.btnDecrementClick}
          btnIncrementClick={this.btnIncrementClick}
          scrollToTop={this.scrollToTop}
        />

    let renderPersonalDataPolicy
    renderPersonalDataPolicy =
      <PersonalDataPolicy
        personalDataPolicyShow={this.state.personalDataPolicyShow}
        personalDataPolicyHandler={this.personalDataPolicyHandler}
      />

    let renderPersonalDataCookies
    renderPersonalDataCookies =
      <PersonalDataCookies
        personalDataCookiesShow={this.state.personalDataCookiesShow}
        personalDataCookiesHandler={this.personalDataCookiesHandler}
      />

    let renderContentWrapper
    renderContentWrapper =
      <MediaQuery minWidth={1280}>
      <div className={classes.ContentWrapper}>
        {renderFilter}
        {renderResults}
        {renderPersonalDataPolicy}
        {renderPersonalDataCookies}
      </div>
      </MediaQuery>

    let renderContentWrapperTablet
    renderContentWrapperTablet =
      <MediaQuery minWidth={600} maxWidth={1279}>
        <div className={classes.tablet_ContentWrapper}>
          <div className={classes.tablet_ContentWrapper_wrapper}>
            {renderFilter}
            {renderResults}
            {renderPersonalDataPolicy}
            {renderPersonalDataCookies}
          </div>
        </div>
      </MediaQuery>

    let renderContentWrapperMobile
    renderContentWrapperMobile =
      <MediaQuery maxWidth={599}>
        <div className={classes.mobile_ContentWrapper}>
          <div className={classes.mobile_ContentWrapper_wrapper}>
          {renderFilter}
          {renderResults}
          {renderPersonalDataPolicy}
          {renderPersonalDataCookies}
          </div>
        </div>
      </MediaQuery>

    return (
      <>
        {renderContentWrapper}
        {renderContentWrapperTablet}
        {renderContentWrapperMobile}
      </>
    )
  }
}

function mapStateToProps(state) {

  return {

    //Выбор категории товаров
    filterSelectedCategory: state.filterSelectCatReducer.filterSelectedCategory,
    filterAllFiltersVisible: state.filterSelectCatReducer.filterAllFiltersVisible,
    filterUdlinitelVisible: state.filterSelectCatReducer.filterUdlinitelVisible,
    filterVilkiVisible: state.filterSelectCatReducer.filterVilkiVisible,
    filterShtepselnyeGnezdaVisible: state.filterSelectCatReducer.filterShtepselnyeGnezdaVisible,
    filterVykluchateliProhodnyeVisible: state.filterSelectCatReducer.filterVykluchateliProhodnyeVisible,
    filterShnuryVisible: state.filterSelectCatReducer.filterShnuryVisible,
    filterTroinikiVisible: state.filterSelectCatReducer.filterTroinikiVisible,
    filterTelephoneUdlinitelVisible: state.filterSelectCatReducer.filterTelephoneUdlinitelVisible,
    filterKolodkiVisible: state.filterSelectCatReducer.filterKolodkiVisible,
    filterRazjemPitaniyaVisible: state.filterSelectCatReducer.filterRazjemPitaniyaVisible,
    filterAvtVykluchateliModulnyeVisible: state.filterSelectCatReducer.filterAvtVykluchateliModulnyeVisible,

    //Фильтр по цене
    rangeSliderWidth: state.filterRangeReducer.rangeSliderWidth,
    rangeOffsetSliderWidht: state.filterRangeReducer.rangeOffsetSliderWidht,
    rangeMin: state.filterRangeReducer.rangeMin,
    rangeMax: state.filterRangeReducer.rangeMax,
    rangeMinValueBetween: state.filterRangeReducer.rangeMinValueBetween,
    rangeCurrentMin: state.filterRangeReducer.rangeCurrentMin,
    rangeInputMin: state.filterRangeReducer.rangeInputMin,
    rangeCurrentMax: state.filterRangeReducer.rangeCurrentMax,
    rangeInputMax: state.filterRangeReducer.rangeInputMax,
    filterButtonResetClicked: state.filterRangeReducer.filterButtonResetClicked,

    //Заполнение inCartVisible
    inCartProducts: state.inCartProductsReducer.inCartProducts,

  }
}

function mapDispatchToProps(dispatch) {
  return {

    //Выбор категории товаров
    onSelectFilterCategory: event => dispatch(selectFilterCategory(event)),
    onAllFiltersVisible: () => dispatch(allFiltersVisible()),
    onUdlinitelVisible: () => dispatch(udlinitelVisible()),
    onVilkiVisible: () => dispatch(vilkiVisible()),
    onShtepselnyeGnezdaVisible: () => dispatch(shtepselnyeGnezdaVisible()),
    onVykluchateliProhodnyeVisible: () => dispatch(vykluchateliProhodnyeVisible()),
    onShnuryVisible: () => dispatch(shnuryVisible()),
    onTroinikiVisible: () => dispatch(troinikiVisible()),
    onTelephoneUdlinitelVisible: () => dispatch(telephoneUdlinitelVisible()),
    onKolodkiVisible: () => dispatch(kolodkiVisible()),
    onRazjemPitaniyaVisible: () => dispatch(razjemPitaniyaVisible()),
    onAvtVykluchateliModulnyeVisible: () => dispatch(avtVykluchateliModulnyeVisible()),

    //Фильтр по цене
    onFilterRangeWidth: (rangeSliderWidth, rangeOffsetSliderWidht) => dispatch(filterRangeWidth(rangeSliderWidth, rangeOffsetSliderWidht)),
    onFilterRangeInputMinimal: (rangeInputMin) => dispatch(filterRangeInputMinimal(rangeInputMin)),
    onFilterRangeCurrentMinimal: (rangeCurrentMin) => dispatch(filterRangeCurrentMinimal(rangeCurrentMin)),
    onFilterRangeMouseMoveMinimalHandler: (rangeCurrentMin) => dispatch(filterRangeMouseMoveMinimalHandler(rangeCurrentMin)),
    onFilterRangeSetInputMaximumHandler: (rangeInputMax) => dispatch(filterRangeSetInputMaximumHandler(rangeInputMax)),
    onFilterRangeSetCurrentMaximumHandler: (rangeCurrentMax) => dispatch(filterRangeSetCurrentMaximumHandler(rangeCurrentMax)),
    onFilterRangeMouseMoveMaximumHandler: (rangeCurrentMax) => dispatch(filterRangeMouseMoveMaximumHandler(rangeCurrentMax)),
    onFilterRangeReset: () => dispatch(filterRangeReset()),

    //Заполнение inCartVisible
    onInCartProductAmountChange: () => dispatch(inCartProductAmountChange(products))

  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ContentWrapper)