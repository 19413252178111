const products =
  [
    {
      id: 1,
      visible: true,
      inCartVisible: false,
      inCartProductAmount: 0,
      inCartProductAmountAdded: '0',
      cat: "Удлинители и сетевые фильтры",
      img: ["../../img/1/setevoi-filtr-tdm-sq1303-0021-3m-5-rozetok -1.jpg"],
      alt: null,
      title: "Удлинитель бытовой  У05  5 гнезд, 3метра, с заземлением, ПВС  3х1мм2  16А/250В TDM [SQ1303-0021]",
      sockets: "5",
      cableLength: "3",
      grounding: "Есть",
      cable: "ПВС 3х1 мм2",
      ratedCurrent: "16 А",
      load: "3.5",
      switchButton: "Нет",
      color: "белый",
      protectionDegree: "IP20",
      type: "бытовой",
      brand: "TDM Electric (ТДМ Электрик)",
      skuManufacturer: "SQ1303-0021",
      skuInternal: "00559119",
      balance: "20",
      price: 362.00,
      filterUsed: false
    },
    {
      id: 2,
      visible: true,
      inCartVisible: false,
      inCartProductAmount: 0,
      inCartProductAmountAdded: '0',
      cat: "Удлинители и сетевые фильтры",
      img: ["../../img/2/setevoi-filtr-tdm-sq1304-0002-3m-5-rozetok -1.jpg"],
      alt: null,
      title: "Сетевой фильтр СФ-05В выключатель, 5 гнезд, 3 метра, с заземлением, ПВС 3х1мм2 16А/250В TDM [SQ1304-0002]",
      sockets: "5",
      cableLength: "3",
      grounding: "Есть",
      cable: "ПВС 3х1 мм2",
      ratedCurrent: "16 А",
      load: "3.5",
      switchButton: "Есть",
      color: "черный",
      protectionDegree: "IP20",
      type: "сетевой фильтр",
      brand: "TDM Electric (ТДМ Электрик)",
      skuManufacturer: "SQ1304-0002",
      skuInternal: "00559176",
      balance: "1",
      price: 432.99,
      filterUsed: false
    },
    {
      id: 3,
      visible: true,
      inCartVisible: false,
      inCartProductAmount: 0,
      inCartProductAmountAdded: '0',
      cat: "Удлинители и сетевые фильтры",
      img: ["../../img/3/setevoi-filtr-power-cube-spg-b-6-1.9m-5-rozetok -1.jpg"],
      alt: null,
      title: "Фильтр-удлинитель Power Cube B 1,9 м 5 розеток (серый) 10А/2,2кВт (30 шт.) [SPG-B-6]",
      sockets: "5",
      cableLength: "1.9",
      grounding: "Есть",
      cable: "ПВС 3х0,75 мм2",
      ratedCurrent: "10 А",
      load: "2.2",
      switchButton: "Есть",
      color: "серый",
      protectionDegree: "IP20",
      type: "сетевой фильтр",
      brand: "Power Cube",
      skuManufacturer: "SPG-B-6",
      skuInternal: "00006875",
      balance: "15",
      price: 371.28,
      filterUsed: false
    },
    {
      id: 4,
      visible: true,
      inCartVisible: false,
      inCartProductAmount: 0,
      inCartProductAmountAdded: '0',
      cat: "Удлинители и сетевые фильтры",
      img: ["../../img/4/setevoi-filtr-garnizon-ehw-6-1.8m-6-rozetok -1.jpg"],
      alt: null,
      title: "Сетевой фильтр Гарнизон ЕНW-6 1.8 м 6 розеток белый (30шт.) [EHW-6]",
      sockets: "6",
      cableLength: "1.8",
      grounding: "Есть",
      cable: "ПВС 3х0,75 мм2",
      ratedCurrent: "10 А",
      load: "2.2",
      switchButton: "Есть",
      color: "белый",
      protectionDegree: "IP20",
      type: "сетевой фильтр",
      brand: "Гарнизон",
      skuManufacturer: "EHW- 6",
      skuInternal: "00035769",
      balance: "11",
      price: 122.12,
      filterUsed: false
    },
    {
      id: 5,
      visible: true,
      inCartVisible: false,
      inCartProductAmount: 0,
      inCartProductAmountAdded: '0',
      cat: "Удлинители и сетевые фильтры",
      img: ["../../img/5/setevoi-filtr-garnizon-1.8m-3-rozetki-elb-g3-w-3.jpg"],
      alt: null,
      title: "Удлинитель Гарнизон ELB-G3-W-3, 3 евророзетки с выключателем и заземлением, допустимый ток 16А, длина 3м. (50шт.)",
      sockets: "3",
      cableLength: "3",
      grounding: "Есть",
      cable: "ПВС 3х1 мм2",
      ratedCurrent: "16 А",
      load: "3.5",
      switchButton: "Есть",
      color: "белый",
      protectionDegree: "IP20",
      type: "бытовой",
      brand: "Гарнизон",
      skuManufacturer: "ELB-G3-W-3",
      skuInternal: "145393",
      balance: "25",
      price: 297.35,
      filterUsed: false
    },
    {
      id: 6,
      visible: true,
      inCartVisible: false,
      inCartProductAmount: 0,
      inCartProductAmountAdded: '0',
      cat: "Удлинители и сетевые фильтры",
      img: ["../../img/6/udlinitel-y05k-3m-5-rozetok-WYP10-16-05-03-ZK-N.jpg"],
      alt: null,
      title: "Удлинитель У05К-выключатель. 5 мест 2Р+PЕ/3метра 3х1мм2 16А/250 IEK (6 шт.) [WYP10-16-05-03-ZK-N]",
      sockets: "5",
      cableLength: "3",
      grounding: "Есть",
      cable: "ПВС 3х1 мм2",
      ratedCurrent: "16 А",
      load: "3.5",
      switchButton: "Есть",
      color: "белый",
      protectionDegree: "IP20",
      type: "бытовой",
      brand: "IEK (ИЭК)",
      skuManufacturer: "WYP10-16-05-03-ZK-N",
      skuInternal: "00485452",
      balance: "5",
      price: 458.15,
      filterUsed: false
    },
    {
      id: 7,
      visible: true,
      inCartVisible: false,
      inCartProductAmount: 0,
      inCartProductAmountAdded: '0',
      cat: "Удлинители и сетевые фильтры",
      img: ["../../img/7/udlinitel-na-katushke-yk10-4mesta-10m-iek-garden-WKP23-06-04-10.jpg"],
      alt: null,
      title: "Катушка УК10 с т/з 4 места 2Р/10м 2х0,75 мм2 Garden (10 шт.) [WKP23-06-04-10]",
      sockets: "4",
      cableLength: "10",
      grounding: "Есть",
      cable: "ПВС 2х0,75 мм2",
      ratedCurrent: "6 А",
      load: "1.3",
      switchButton: "Нет",
      color: "черный",
      protectionDegree: "IP20",
      type: "на катушке",
      brand: "IEK (ИЭК)",
      skuManufacturer: "WKP23-06-04-10",
      skuInternal: "00485377",
      balance: "1",
      price: 772.02,
      filterUsed: false
    },
    {
      id: 8,
      visible: true,
      inCartVisible: false,
      inCartProductAmount: 0,
      inCartProductAmountAdded: '0',
      cat: "Удлинители и сетевые фильтры",
      img: ["../../img/8/8.jpg"],
      alt: null,
      title: "Рамка УР10 1 место 2Р/10метров 2х0,75 мм2 (2 шт.) [WKF20-06-01-10]",
      sockets: "1",
      cableLength: "10",
      grounding: "Есть",
      cable: "ПВС 2х0,75 мм2",
      ratedCurrent: "6 А",
      load: "1.3",
      switchButton: "Нет",
      color: "черный",
      protectionDegree: "IP20",
      type: "на рамке",
      brand: "IEK (ИЭК)",
      skuManufacturer: "WKF20-06-01-10",
      skuInternal: "00485350",
      balance: "2",
      price: 608.16,
      filterUsed: false
    },
    {
      id: 9,
      visible: true,
      inCartVisible: false,
      inCartProductAmount: 0,
      inCartProductAmountAdded: '0',
      cat: "Удлинители и сетевые фильтры",
      img: ["../../img/9/9.jpg"],
      alt: null,
      title: "481S-1305 Удлинитель ТМ СОЮЗ 2200Вт 3 гнезда универсальная (ПВС 2х1) 5м",
      sockets: "3",
      cableLength: "5",
      grounding: "Нет",
      cable: "ПВС 2х1 мм2",
      ratedCurrent: "10 А",
      load: "2.2",
      switchButton: "Нет",
      color: "белый",
      protectionDegree: "IP20",
      type: "бытовой",
      brand: "Универсал (UNIVersal)",
      skuManufacturer: "481S-1305",
      skuInternal: "112926",
      balance: "1",
      price: 195.19,
      filterUsed: false
    },
    {
      id: 10,
      visible: true,
      inCartVisible: false,
      inCartProductAmount: 0,
      inCartProductAmountAdded: '0',
      cat: "Удлинители и сетевые фильтры",
      img: ["../../img/10/10.jpg"],
      alt: null,
      title: "481S-5202 Удлинитель-шнур (ПВС 2х1, 10А) 1-местный на рамке, L= 20,0м ТМ СОЮЗ",
      sockets: "1",
      cableLength: "20",
      grounding: "Нет",
      cable: "ПВС 2х1 мм2",
      ratedCurrent: "10 А",
      load: "2.2",
      switchButton: "Нет",
      color: "черный",
      protectionDegree: "IP20",
      type: "на рамке",
      brand: "Универсал (UNIVersal)",
      skuManufacturer: "481S-5202",
      skuInternal: "00049544",
      balance: "7",
      price: 656.74,
      filterUsed: false
    },
    {
      id: 11,
      visible: true,
      inCartVisible: false,
      inCartProductAmount: 0,
      inCartProductAmountAdded: '0',
      cat: "Удлинители и сетевые фильтры",
      img: ["../../img/11/11.jpg"],
      alt: null,
      title: "481S-5203 Удлинитель-шнур (ПВС 2х1, 10А) 1-местный на рамке, L= 30,0м ТМ СОЮЗ",
      sockets: "1",
      cableLength: "30",
      grounding: "Нет",
      cable: "ПВС 2х1 мм2",
      ratedCurrent: "10 А",
      load: "2.2",
      switchButton: "Нет",
      color: "черный",
      protectionDegree: "IP20",
      type: "на рамке",
      brand: "Универсал (UNIVersal)",
      skuManufacturer: "481S-5203",
      skuInternal: "00049545",
      balance: "12",
      price: 931.62,
      filterUsed: false
    },
    {
      id: 12,
      visible: true,
      inCartVisible: false,
      inCartProductAmount: 0,
      inCartProductAmountAdded: '0',
      cat: "Удлинители и сетевые фильтры",
      img: ["../../img/12/12.jpg"],
      alt: null,
      title: "481S-5205 Удлинитель-шнур (ПВС 2х1, 10А) 1-местный на рамке, L= 50,0м ТМ СОЮЗ",
      sockets: "1",
      cableLength: "50",
      grounding: "Нет",
      cable: "ПВС 2х1 мм2",
      ratedCurrent: "10 А",
      load: "2.2",
      switchButton: "Нет",
      color: "черный",
      protectionDegree: "IP20",
      type: "на рамке",
      brand: "Универсал (UNIVersal)",
      skuManufacturer: "481S-5205",
      skuInternal: "00049547",
      balance: "1",
      price: 1482.28,
      filterUsed: false
    },
    {
      id: 13,
      visible: true,
      inCartVisible: false,
      inCartProductAmount: 0,
      inCartProductAmountAdded: '0',
      cat: "Удлинители и сетевые фильтры",
      img: ["../../img/13/13.jpg"],
      alt: null,
      title: "9631997 Удлинитель-шнур (ПВС 2х0,75, 6А) 1-местный, L= 10,0м / УШ-6 Универсал",
      sockets: "1",
      cableLength: "10",
      grounding: "Нет",
      cable: "ПВС 2х0,75 мм2",
      ratedCurrent: "6 А",
      load: "1.3",
      switchButton: "Нет",
      color: "оранжевый",
      protectionDegree: "IP54",
      type: "удлинитель-шнур",
      brand: "Универсал (UNIVersal)",
      skuManufacturer: "9631997",
      skuInternal: "00003475",
      balance: "18",
      price: 334.76,
      filterUsed: false
    },
    {
      id: 14,
      visible: true,
      inCartVisible: false,
      inCartProductAmount: 0,
      inCartProductAmountAdded: '0',
      cat: "Удлинители и сетевые фильтры",
      img: ["../../img/14/14.jpg"],
      alt: null,
      title: "9631999 Удлинитель-шнур (ПВС 2х0,75, 6А) 1-местный, L= 20,0м / УШ-6 Универсал",
      sockets: "1",
      cableLength: "20",
      grounding: "Нет",
      cable: "ПВС 2х0,75 мм2",
      ratedCurrent: "6 А",
      load: "1.3",
      switchButton: "Нет",
      color: "оранжевый",
      protectionDegree: "IP54",
      type: "удлинитель-шнур",
      brand: "Универсал (UNIVersal)",
      skuManufacturer: "9631999",
      skuInternal: "00003477",
      balance: "13",
      price: 592.22,
      filterUsed: false
    },
    {
      id: 15,
      visible: true,
      inCartVisible: false,
      inCartProductAmount: 0,
      inCartProductAmountAdded: '0',
      cat: "Удлинители и сетевые фильтры",
      img: ["../../img/15/15.jpg"],
      alt: null,
      title: "9632001 Удлинитель-шнур (ПВС 2х0,75, 6А) 1-местный, L= 30,0м / УШ-6 Универсал",
      sockets: "1",
      cableLength: "30",
      grounding: "Нет",
      cable: "ПВС 2х0,75 мм2",
      ratedCurrent: "6 А",
      load: "1.3",
      switchButton: "Нет",
      color: "оранжевый",
      protectionDegree: "IP54",
      type: "удлинитель-шнур",
      brand: "Универсал (UNIVersal)",
      skuManufacturer: "9632001",
      skuInternal: "00003479",
      balance: "8",
      price: 853.72,
      filterUsed: false
    },
    {
      id: 16,
      visible: true,
      inCartVisible: false,
      inCartProductAmount: 0,
      inCartProductAmountAdded: '0',
      cat: "Удлинители и сетевые фильтры",
      img: ["../../img/16/16.jpg"],
      alt: null,
      title: "9632002 Удлинитель-шнур (ПВС 2х0,75, 6А) 1-местный, L= 40,0м / УШ-6 Универсал",
      sockets: "1",
      cableLength: "40",
      grounding: "Нет",
      cable: "ПВС 2х0,75 мм2",
      ratedCurrent: "6 А",
      load: "1.3",
      switchButton: "Нет",
      color: "оранжевый",
      protectionDegree: "IP54",
      type: "удлинитель-шнур",
      brand: "Универсал (UNIVersal)",
      skuManufacturer: "9632002",
      skuInternal: "00003480",
      balance: "16",
      price: 1132.75,
      filterUsed: false
    },
    {
      id: 17,
      visible: true,
      inCartVisible: false,
      inCartProductAmount: 0,
      inCartProductAmountAdded: '0',
      cat: "Удлинители и сетевые фильтры",
      img: ["../../img/17/17.jpg"],
      alt: null,
      title: "9632003 Удлинитель-шнур IP54 (ПВС 2х0,75, 6А) 1-местный, L= 50,0м / УШ-6 Универсал",
      sockets: "1",
      cableLength: "50",
      grounding: "Нет",
      cable: "ПВС 2х0,75 мм2",
      ratedCurrent: "6 А",
      load: "1.3",
      switchButton: "Нет",
      color: "оранжевый",
      protectionDegree: "IP54",
      type: "удлинитель-шнур",
      brand: "Универсал (UNIVersal)",
      skuManufacturer: "9632003",
      skuInternal: "00006854",
      balance: "26",
      price: 1397.24,
      filterUsed: false
    },
    {
      id: 18,
      visible: true,
      inCartVisible: false,
      inCartProductAmount: 0,
      inCartProductAmountAdded: '0',
      cat: "Удлинители и сетевые фильтры",
      img: ["../../img/18/18.jpg"],
      alt: null,
      title: "9632004 Удлинитель-шнур (ПВС3х1,5, 16А) 1-местный, L= 30,0м / УШ-16 с з/к.Универсал",
      sockets: "1",
      cableLength: "30",
      grounding: "Есть",
      cable: "ПВС 3х1,5 мм2",
      ratedCurrent: "16 А",
      load: "3",
      switchButton: "Нет",
      color: "оранжевый",
      protectionDegree: "IP54",
      type: "удлинитель-шнур",
      brand: "Универсал (UNIVersal)",
      skuManufacturer: "9632004",
      skuInternal: "00006850",
      balance: "17",
      price: 1952.44,
      filterUsed: false
    },
    {
      id: 19,
      visible: true,
      inCartVisible: false,
      inCartProductAmount: 0,
      inCartProductAmountAdded: '0',
      cat: "Удлинители и сетевые фильтры",
      img: ["../../img/19/19.jpg"],
      alt: null,
      title: "9632005 Удлинитель-шнур (ПВС3х1,5, 16А) 1-местный, L= 40,0м / УШ-16 с з/к.Универсал",
      sockets: "1",
      cableLength: "40",
      grounding: "Есть",
      cable: "ПВС 3х1,5 мм2",
      ratedCurrent: "16 А",
      load: "3",
      switchButton: "Нет",
      color: "оранжевый",
      protectionDegree: "IP54",
      type: "удлинитель-шнур",
      brand: "Универсал (UNIVersal)",
      skuManufacturer: "9632005",
      skuInternal: "00006851",
      balance: "2",
      price: 2593.73,
      filterUsed: false
    },
    {
      id: 20,
      visible: true,
      inCartVisible: false,
      inCartProductAmount: 0,
      inCartProductAmountAdded: '0',
      cat: "Удлинители и сетевые фильтры",
      img: ["../../img/20/20.jpg"],
      alt: null,
      title: "9632006 Удлинитель-шнур (ПВС3х1,5, 16А) 1-местный, L= 50,0м / УШ-16 с з/к.Универсал",
      sockets: "1",
      cableLength: "50",
      grounding: "Есть",
      cable: "ПВС 3х1,5 мм2",
      ratedCurrent: "16 А",
      load: "3",
      switchButton: "Нет",
      color: "оранжевый",
      protectionDegree: "IP54",
      type: "удлинитель-шнур",
      brand: "Универсал (UNIVersal)",
      skuManufacturer: "9632006",
      skuInternal: "00006852",
      balance: "15",
      price: 3213.70,
      filterUsed: false
    },
    {
      id: 21,
      visible: true,
      inCartVisible: false,
      inCartProductAmount: 0,
      inCartProductAmountAdded: '0',
      cat: "Удлинители и сетевые фильтры",
      img: ["../../img/21/21.jpg"],
      alt: null,
      title: "9632008 Удлинитель-шнур IP54 (ПВС 3х0,75, 10А) 1-местный, L= 10,0м / УШ-10 с з/к.Универсал",
      sockets: "1",
      cableLength: "10",
      grounding: "Есть",
      cable: "ПВС 3х0,75 мм2",
      ratedCurrent: "10 А",
      load: "2.2",
      switchButton: "Нет",
      color: "оранжевый",
      protectionDegree: "IP54",
      type: "удлинитель-шнур",
      brand: "Универсал (UNIVersal)",
      skuManufacturer: "9632008",
      skuInternal: "00007481",
      balance: "1",
      price: 454.78,
      filterUsed: false
    },
    {
      id: 22,
      visible: true,
      inCartVisible: false,
      inCartProductAmount: 0,
      inCartProductAmountAdded: '0',
      cat: "Удлинители и сетевые фильтры",
      img: ["../../img/22/22.jpg"],
      alt: null,
      title: "9632009 Удлинитель-шнур (ПВС 3х0,75, 10А) 1-местный, L= 20,0м / УШ-10 с з/к.Универсал",
      sockets: "1",
      cableLength: "20",
      grounding: "Есть",
      cable: "ПВС 3х0,75 мм2",
      ratedCurrent: "10 А",
      load: "2.2",
      switchButton: "Нет",
      color: "оранжевый",
      protectionDegree: "IP54",
      type: "удлинитель-шнур",
      brand: "Универсал (UNIVersal)",
      skuManufacturer: "9632009",
      skuInternal: "00007482",
      balance: "19",
      price: 808.17,
      filterUsed: false
    },
    {
      id: 23,
      visible: true,
      inCartVisible: false,
      inCartProductAmount: 0,
      inCartProductAmountAdded: '0',
      cat: "Удлинители и сетевые фильтры",
      img: ["../../img/23/23.jpg"],
      alt: null,
      title: "9632011 Удлинитель-шнур (ПВС 3х0,75, 10А) 1-местный, L= 30,0м / УШ-10 с з/к.Универсал",
      sockets: "1",
      cableLength: "30",
      grounding: "Есть",
      cable: "ПВС 3х0,75 мм2",
      ratedCurrent: "10 А",
      load: "2.2",
      switchButton: "Нет",
      color: "оранжевый",
      protectionDegree: "IP54",
      type: "удлинитель-шнур",
      brand: "Универсал (UNIVersal)",
      skuManufacturer: "9632011",
      skuInternal: "00007483",
      balance: "9",
      price: 1176.67,
      filterUsed: false
    },
    {
      id: 24,
      visible: true,
      inCartVisible: false,
      inCartProductAmount: 0,
      inCartProductAmountAdded: '0',
      cat: "Удлинители и сетевые фильтры",
      img: ["../../img/24/24.jpg"],
      alt: null,
      title: "9632012 Удлинитель-шнур (ПВС 3х0,75, 10А) 1-местный, L= 40,0м / УШ-10 с з/к.Универсал",
      sockets: "1",
      cableLength: "40",
      grounding: "Есть",
      cable: "ПВС 3х0,75 мм2",
      ratedCurrent: "10 А",
      load: "2.2",
      switchButton: "Нет",
      color: "оранжевый",
      protectionDegree: "IP54",
      type: "удлинитель-шнур",
      brand: "Универсал (UNIVersal)",
      skuManufacturer: "9632012",
      skuInternal: "00007484",
      balance: "14",
      price: 1541.19,
      filterUsed: false
    },
    {
      id: 25,
      visible: true,
      inCartVisible: false,
      inCartProductAmount: 0,
      inCartProductAmountAdded: '0',
      cat: "Удлинители и сетевые фильтры",
      img: ["../../img/25/25.jpg"],
      alt: null,
      title: "9632013 Удлинитель-шнур (ПВС 3х0,75, 10А) 1-местный, L= 50,0м / УШ-10 с з/к.Универсал",
      sockets: "1",
      cableLength: "50",
      grounding: "Есть",
      cable: "ПВС 3х0,75 мм2",
      ratedCurrent: "10 А",
      load: "2.2",
      switchButton: "Нет",
      color: "оранжевый",
      protectionDegree: "IP54",
      type: "удлинитель-шнур",
      brand: "Универсал (UNIVersal)",
      skuManufacturer: "9632013",
      skuInternal: "00007485",
      balance: "18",
      price: 1898.31,
      filterUsed: false
    },
    {
      id: 26,
      visible: true,
      inCartVisible: false,
      inCartProductAmount: 0,
      inCartProductAmountAdded: '0',
      cat: "Удлинители и сетевые фильтры",
      img: ["../../img/26/26.jpg"],
      alt: null,
      title: "9632777 Удлинитель-шнур (ПВС 2х1) 1-местный, L= 10 м / УШ-10 Универсал",
      sockets: "1",
      cableLength: "10",
      grounding: "Нет",
      cable: "ПВС 2х1 мм2",
      ratedCurrent: "10 А",
      load: "2.2",
      switchButton: "Нет",
      color: "оранжевый",
      protectionDegree: "IP54",
      type: "удлинитель-шнур",
      brand: "Универсал (UNIVersal)",
      skuManufacturer: "9632777",
      skuInternal: "00037605",
      balance: "2",
      price: 402.15,
      filterUsed: false
    },
    {
      id: 27,
      visible: true,
      inCartVisible: false,
      inCartProductAmount: 0,
      inCartProductAmountAdded: '0',
      cat: "Удлинители и сетевые фильтры",
      img: ["../../img/27/27.jpg"],
      alt: null,
      title: "9632780 Удлинитель-шнур (ПВС 2х1) 1-местный, L= 20 м / УШ-10 Универсал",
      sockets: "1",
      cableLength: "20",
      grounding: "Нет",
      cable: "ПВС 2х1 мм2",
      ratedCurrent: "10 А",
      load: "2.2",
      switchButton: "Нет",
      color: "оранжевый",
      protectionDegree: "IP54",
      type: "удлинитель-шнур",
      brand: "Универсал (UNIVersal)",
      skuManufacturer: "9632780",
      skuInternal: "00037606",
      balance: "23",
      price: 712.99,
      filterUsed: false
    },
    {
      id: 28,
      visible: true,
      inCartVisible: false,
      inCartProductAmount: 0,
      inCartProductAmountAdded: '0',
      cat: "Удлинители и сетевые фильтры",
      img: ["../../img/28/28.jpg"],
      alt: null,
      title: "9632782 Удлинитель-шнур (ПВС 2х1) 1-местный, L= 30 м / УШ-10 Универсал",
      sockets: "1",
      cableLength: "30",
      grounding: "Нет",
      cable: "ПВС 2х1 мм2",
      ratedCurrent: "10 А",
      load: "2.2",
      switchButton: "Нет",
      color: "оранжевый",
      protectionDegree: "IP54",
      type: "удлинитель-шнур",
      brand: "Универсал (UNIVersal)",
      skuManufacturer: "9632782",
      skuInternal: "00033683",
      balance: "3",
      price: 1034.68,
      filterUsed: false
    },
    {
      id: 29,
      visible: true,
      inCartVisible: false,
      inCartProductAmount: 0,
      inCartProductAmountAdded: '0',
      cat: "Удлинители и сетевые фильтры",
      img: ["../../img/29/29.jpg"],
      alt: null,
      title: "9632808 Удлинитель-шнур (ПВС3х1) 1-местный, L= 50 м / УШ-16 с з/к.Универсал",
      sockets: "1",
      cableLength: "50",
      grounding: "Есть",
      cable: "ПВС 3х1 мм2",
      ratedCurrent: "16 А",
      load: "3.5",
      switchButton: "Нет",
      color: "оранжевый",
      protectionDegree: "IP54",
      type: "удлинитель-шнур",
      brand: "Универсал (UNIVersal)",
      skuManufacturer: "9632808",
      skuInternal: "00033220",
      balance: "6",
      price: 2383.74,
      filterUsed: false
    },
    {
      id: 30,
      visible: true,
      inCartVisible: false,
      inCartProductAmount: 0,
      inCartProductAmountAdded: '0',
      cat: "Удлинители и сетевые фильтры",
      img: ["../../img/30/30.jpg"],
      alt: null,
      title: "9632813 Удлинитель-шнур (ПВС3х1) 1-местный, L= 30 м / УШ-16 с з/к.Универсал",
      sockets: "1",
      cableLength: "30",
      grounding: "Есть",
      cable: "ПВС 3х1 мм2",
      ratedCurrent: "16 А",
      load: "3.5",
      switchButton: "Нет",
      color: "оранжевый",
      protectionDegree: "IP54",
      type: "удлинитель-шнур",
      brand: "Универсал (UNIVersal)",
      skuManufacturer: "9632813",
      skuInternal: "00033218",
      balance: "8",
      price: 1474.89,
      filterUsed: false
    },
    {
      id: 31,
      visible: true,
      inCartVisible: false,
      inCartProductAmount: 0,
      inCartProductAmountAdded: '0',
      cat: "Удлинители и сетевые фильтры",
      img: ["../../img/31/31.jpg"],
      alt: null,
      title: "9632814 Удлинитель-шнур (ПВС3х1) 1-местный, L= 40 м / УШ-16 с з/к.Универсал",
      sockets: "1",
      cableLength: "40",
      grounding: "Есть",
      cable: "ПВС 3х1 мм2",
      ratedCurrent: "16 А",
      load: "3.5",
      switchButton: "Нет",
      color: "оранжевый",
      protectionDegree: "IP54",
      type: "удлинитель-шнур",
      brand: "Универсал (UNIVersal)",
      skuManufacturer: "9632814",
      skuInternal: "00033219",
      balance: "4",
      price: 1938.74,
      filterUsed: false
    },
    {
      id: 32,
      visible: true,
      inCartVisible: false,
      inCartProductAmount: 0,
      inCartProductAmountAdded: '0',
      cat: "Удлинители и сетевые фильтры",
      img: ["../../img/32/32.jpg"],
      alt: null,
      title: "9632019 Удлинитель-шнур (ПВС 2х0,75, 6А) 1-местный на рамке, L= 40,0м / УШ-6 Универсал",
      sockets: "1",
      cableLength: "40",
      grounding: "Нет",
      cable: "ПВС 2х0,75 мм2",
      ratedCurrent: "6 А",
      load: "1.3",
      switchButton: "Нет",
      color: "оранжевый",
      protectionDegree: "IP54",
      type: "на рамке",
      brand: "Универсал (UNIVersal)",
      skuManufacturer: "9632019",
      skuInternal: "00003472",
      balance: "5",
      price: 1159.32,
      filterUsed: false
    },
    {
      id: 33,
      visible: true,
      inCartVisible: false,
      inCartProductAmount: 0,
      inCartProductAmountAdded: '0',
      cat: "Удлинители и сетевые фильтры",
      img: ["../../img/33/33.jpg"],
      alt: null,
      title: "9632020 Удлинитель-шнур (ПВС 2х0,75, 6А) 1-местный на рамке, L= 50,0м / УШ-6 Универсал",
      sockets: "1",
      cableLength: "50",
      grounding: "Нет",
      cable: "ПВС 2х0,75 мм2",
      ratedCurrent: "6 А",
      load: "1.3",
      switchButton: "Нет",
      color: "оранжевый",
      protectionDegree: "IP54",
      type: "на рамке",
      brand: "Универсал (UNIVersal)",
      skuManufacturer: "9632020",
      skuInternal: "00003473",
      balance: "24",
      price: 1419.70,
      filterUsed: false
    },
    {
      id: 34,
      visible: true,
      inCartVisible: false,
      inCartProductAmount: 0,
      inCartProductAmountAdded: '0',
      cat: "Удлинители и сетевые фильтры",
      img: ["../../img/34/34.jpg"],
      alt: null,
      title: "9632024 Удлинитель-шнур (ПВС3х0,75, 10А) 1-местныйна рамке,L=30,0м/ УШ-10 с з/к.Универсал",
      sockets: "1",
      cableLength: "30",
      grounding: "Есть",
      cable: "ПВС 3х0,75 мм2",
      ratedCurrent: "10 А",
      load: "2.2",
      switchButton: "Нет",
      color: "оранжевый",
      protectionDegree: "IP54",
      type: "на рамке",
      brand: "Универсал (UNIVersal)",
      skuManufacturer: "9632024",
      skuInternal: "00007489",
      balance: "7",
      price: 1233.54,
      filterUsed: false
    },
    {
      id: 35,
      visible: true,
      inCartVisible: false,
      inCartProductAmount: 0,
      inCartProductAmountAdded: '0',
      cat: "Удлинители и сетевые фильтры",
      img: ["../../img/35/35.jpg"],
      alt: null,
      title: "9632025 Удлинитель-шнур (ПВС3х0,75, 10А) 1-местныйна рамке,L=40,0м/ УШ-10 с з/к.Универсал",
      sockets: "1",
      cableLength: "40",
      grounding: "Есть",
      cable: "ПВС 3х0,75 мм2",
      ratedCurrent: "10 А",
      load: "2.2",
      switchButton: "Нет",
      color: "оранжевый",
      protectionDegree: "IP54",
      type: "на рамке",
      brand: "Универсал (UNIVersal)",
      skuManufacturer: "9632025",
      skuInternal: "00007490",
      balance: "6",
      price: 1607.51,
      filterUsed: false
    },
    {
      id: 36,
      visible: true,
      inCartVisible: false,
      inCartProductAmount: 0,
      inCartProductAmountAdded: '0',
      cat: "Удлинители и сетевые фильтры",
      img: ["../../img/36/36.jpg"],
      alt: null,
      title: "9632026 Удлинитель-шнур (ПВС3х0,75, 10А) 1-местныйна рамке,L=50,0м/ УШ-10 с з/к.Универсал",
      sockets: "1",
      cableLength: "50",
      grounding: "Есть",
      cable: "ПВС 3х0,75 мм2",
      ratedCurrent: "10 А",
      load: "2.2",
      switchButton: "Нет",
      color: "оранжевый",
      protectionDegree: "IP54",
      type: "на рамке",
      brand: "Универсал (UNIVersal)",
      skuManufacturer: "9632026",
      skuInternal: "00007491",
      balance: "5",
      price: 1979.70,
      filterUsed: false
    },
    {
      id: 37,
      visible: true,
      inCartVisible: false,
      inCartProductAmount: 0,
      inCartProductAmountAdded: '0',
      cat: "Удлинители и сетевые фильтры",
      img: ["../../img/37/37.jpg"],
      alt: null,
      title: "9632789 Удлинитель-шнур (ПВС 2х1) 1-местный на рамке, L= 10,0м / УШ-10 Универсал",
      sockets: "1",
      cableLength: "10",
      grounding: "Нет",
      cable: "ПВС 2х1 мм2",
      ratedCurrent: "10 А",
      load: "2.2",
      switchButton: "Нет",
      color: "оранжевый",
      protectionDegree: "IP54",
      type: "на рамке",
      brand: "Универсал (UNIVersal)",
      skuManufacturer: "9632789",
      skuInternal: "00040377",
      balance: "12",
      price: 451.63,
      filterUsed: false
    },
    {
      id: 38,
      visible: true,
      inCartVisible: false,
      inCartProductAmount: 0,
      inCartProductAmountAdded: '0',
      cat: "Удлинители и сетевые фильтры",
      img: ["../../img/38/38.jpg"],
      alt: null,
      title: "9632793 Удлинитель-шнур (ПВС 2х1) 1-местный на рамке, L= 30,0м / УШ-10 Универсал (уп.10 шт.)",
      sockets: "1",
      cableLength: "30",
      grounding: "Нет",
      cable: "ПВС 2х1 мм2",
      ratedCurrent: "10 А",
      load: "2.2",
      switchButton: "Нет",
      color: "оранжевый",
      protectionDegree: "IP54",
      type: "на рамке",
      brand: "Универсал (UNIVersal)",
      skuManufacturer: "9632793",
      skuInternal: "00035256",
      balance: "2",
      price: 1101.57,
      filterUsed: false
    },
    {
      id: 39,
      visible: true,
      inCartVisible: false,
      inCartProductAmount: 0,
      inCartProductAmountAdded: '0',
      cat: "Удлинители и сетевые фильтры",
      img: ["../../img/39/39.jpg"],
      alt: null,
      title: "9632794 Удлинитель-шнур (ПВС 2х1) 1-местный на рамке, L= 40,0м / УШ-10 Универсал",
      sockets: "1",
      cableLength: "40",
      grounding: "Нет",
      cable: "ПВС 2х1 мм2",
      ratedCurrent: "10 А",
      load: "2.2",
      switchButton: "Нет",
      color: "оранжевый",
      protectionDegree: "IP54",
      type: "на рамке",
      brand: "Универсал (UNIVersal)",
      skuManufacturer: "9632794",
      skuInternal: "00035601",
      balance: "14",
      price: 1421.74,
      filterUsed: false
    },
    {
      id: 40,
      visible: true,
      inCartVisible: false,
      inCartProductAmount: 0,
      inCartProductAmountAdded: '0',
      cat: "Удлинители и сетевые фильтры",
      img: ["../../img/40/40.jpg"],
      alt: null,
      title: "9632795 Удлинитель-шнур (ПВС 2х1) 1-местный на рамке, L= 50,0м / УШ-10 Универсал",
      sockets: "1",
      cableLength: "50",
      grounding: "Нет",
      cable: "ПВС 2х1 мм2",
      ratedCurrent: "10 А",
      load: "2.2",
      switchButton: "Нет",
      color: "оранжевый",
      protectionDegree: "IP54",
      type: "на рамке",
      brand: "Универсал (UNIVersal)",
      skuManufacturer: "9632795",
      skuInternal: "00035259",
      balance: "10",
      price: 1746.84,
      filterUsed: false
    },
    {
      id: 41,
      visible: true,
      inCartVisible: false,
      inCartProductAmount: 0,
      inCartProductAmountAdded: '0',
      cat: "Удлинители и сетевые фильтры",
      img: ["../../img/41/41.jpg"],
      alt: null,
      title: "9633860 Удлинитель IP-44 (ПВС3х1,5) 4-мест., L= 10,0м / У16-040 (евро) Универсал",
      sockets: "4",
      cableLength: "10",
      grounding: "Есть",
      cable: "ПВС 3х1,5 мм2",
      ratedCurrent: "16 А",
      load: "3.5",
      switchButton: "Нет",
      color: "оранжевый",
      protectionDegree: "IP44",
      type: "на колодке",
      brand: "Универсал (UNIVersal)",
      skuManufacturer: "9633860",
      skuInternal: "00036564",
      balance: "4",
      price: 1056.98,
      filterUsed: false
    },
    {
      id: 42,
      visible: true,
      inCartVisible: false,
      inCartProductAmount: 0,
      inCartProductAmountAdded: '0',
      cat: "Удлинители и сетевые фильтры",
      img: ["../../img/42/42.jpg"],
      alt: null,
      title: "9633861 Удлинитель IP-44 (ПВС3х1,5) 4-мест., L= 20,0м / У16-040 (евро) Универсал",
      sockets: "4",
      cableLength: "20",
      grounding: "Есть",
      cable: "ПВС 3х1,5 мм2",
      ratedCurrent: "16 А",
      load: "3.5",
      switchButton: "Нет",
      color: "оранжевый",
      protectionDegree: "IP44",
      type: "на колодке",
      brand: "Универсал (UNIVersal)",
      skuManufacturer: "9633861",
      skuInternal: "00034776",
      balance: "7",
      price: 1648.01,
      filterUsed: false
    },
    {
      id: 43,
      visible: true,
      inCartVisible: false,
      inCartProductAmount: 0,
      inCartProductAmountAdded: '0',
      cat: "Удлинители и сетевые фильтры",
      img: ["../../img/43/43.jpg"],
      alt: null,
      title: "9633862 Удлинитель IP-44 (ПВС3х1,5) 4-мест., L= 30,0м / У16-040 (евро) Универсал",
      sockets: "4",
      cableLength: "30",
      grounding: "Есть",
      cable: "ПВС 3х1,5 мм2",
      ratedCurrent: "16 А",
      load: "3.5",
      switchButton: "Нет",
      color: "оранжевый",
      protectionDegree: "IP44",
      type: "на колодке",
      brand: "Универсал (UNIVersal)",
      skuManufacturer: "9633862",
      skuInternal: "00034777",
      balance: "3",
      price: 2304.67,
      filterUsed: false
    },
    {
      id: 44,
      visible: true,
      inCartVisible: false,
      inCartProductAmount: 0,
      inCartProductAmountAdded: '0',
      cat: "Удлинители и сетевые фильтры",
      img: ["../../img/44/44.jpg"],
      alt: null,
      title: "9633924 Удлинитель IP-44 (ПВС3х1,5) 5-мест., L= 30,0м / У10-026 (евро) Универсал",
      sockets: "5",
      cableLength: "30",
      grounding: "Есть",
      cable: "ПВС 3х1,5 мм2",
      ratedCurrent: "16 А",
      load: "3.5",
      switchButton: "Нет",
      color: "оранжевый",
      protectionDegree: "IP44",
      type: "на колодке",
      brand: "Универсал (UNIVersal)",
      skuManufacturer: "9633924",
      skuInternal: "00034773",
      balance: "5",
      price: 2425.20,
      filterUsed: false
    },
    {
      id: 45,
      visible: true,
      inCartVisible: false,
      inCartProductAmount: 0,
      inCartProductAmountAdded: '0',
      cat: "Удлинители и сетевые фильтры",
      img: ["../../img/45/45.jpg"],
      alt: null,
      title: "9633925 Удлинитель IP-44 (ПВС3х1,5) 5-мест., L= 40,0м / У10-026 (евро) Универсал",
      sockets: "5",
      cableLength: "40",
      grounding: "Есть",
      cable: "ПВС 3х1,5 мм2",
      ratedCurrent: "16 А",
      load: "3.5",
      switchButton: "Нет",
      color: "оранжевый",
      protectionDegree: "IP44",
      type: "на колодке",
      brand: "Универсал (UNIVersal)",
      skuManufacturer: "9633925",
      skuInternal: "00034774",
      balance: "8",
      price: 2040.07,
      filterUsed: false
    },
    {
      id: 46,
      visible: true,
      inCartVisible: false,
      inCartProductAmount: 0,
      inCartProductAmountAdded: '0',
      cat: "Удлинители и сетевые фильтры",
      img: ["../../img/46/46.jpg"],
      alt: null,
      title: "9633926 Удлинитель IP-44 (ПВС3х1,5) 5-мест., L= 50,0м / У10-026 (евро) Универсал",
      sockets: "5",
      cableLength: "50",
      grounding: "Есть",
      cable: "ПВС 3х1,5 мм2",
      ratedCurrent: "16 А",
      load: "3.5",
      switchButton: "Нет",
      color: "оранжевый",
      protectionDegree: "IP44",
      type: "на колодке",
      brand: "Универсал (UNIVersal)",
      skuManufacturer: "9633926",
      skuInternal: "00034775",
      balance: "6",
      price: 2484.59,
      filterUsed: false
    },
    {
      id: 47,
      visible: true,
      inCartVisible: false,
      inCartProductAmount: 0,
      inCartProductAmountAdded: '0',
      cat: "Удлинители и сетевые фильтры",
      img: ["../../img/47/47.jpg"],
      alt: null,
      title: "9634134 Удлинитель IP-44 (КГ3х1,5) 4-мест., L= 40,0м / У16-040 (евро) Универсал",
      sockets: "4",
      cableLength: "40",
      grounding: "Есть",
      cable: "КГ 3х1,5 мм2",
      ratedCurrent: "16 А",
      load: "3.5",
      switchButton: "Нет",
      color: "черный",
      protectionDegree: "IP44",
      type: "на колодке",
      brand: "Универсал (UNIVersal)",
      skuManufacturer: "9634134",
      skuInternal: "00019097",
      balance: "2",
      price: 2514.96,
      filterUsed: false
    },
    {
      id: 48,
      visible: true,
      inCartVisible: false,
      inCartProductAmount: 0,
      inCartProductAmountAdded: '0',
      cat: "Удлинители и сетевые фильтры",
      img: ["../../img/48/48.jpg"],
      alt: null,
      title: "9634135 Удлинитель IP-44 (КГ3х1,5) 4-мест., L= 50,0м / У16-040 (евро) Универсал",
      sockets: "4",
      cableLength: "50",
      grounding: "Есть",
      cable: "КГ 3х1,5 мм2",
      ratedCurrent: "16 А",
      load: "3.5",
      switchButton: "Нет",
      color: "черный",
      protectionDegree: "IP44",
      type: "на колодке",
      brand: "Универсал (UNIVersal)",
      skuManufacturer: "9634135",
      skuInternal: "00019098",
      balance: "1",
      price: 4657.11,
      filterUsed: false
    },
    {
      id: 49,
      visible: true,
      inCartVisible: false,
      inCartProductAmount: 0,
      inCartProductAmountAdded: '0',
      cat: "Удлинители и сетевые фильтры",
      img: ["../../img/49/49.jpg"],
      alt: null,
      title: "9634140 Удлинитель IP-44 (КГ3х1,5) 5-мест., L= 30,0м / У10-026 (евро) Универсал",
      sockets: "5",
      cableLength: "30",
      grounding: "Есть",
      cable: "КГ 3х1,5 мм2",
      ratedCurrent: "16 А",
      load: "3.5",
      switchButton: "Нет",
      color: "черный",
      protectionDegree: "IP44",
      type: "на колодке",
      brand: "Универсал (UNIVersal)",
      skuManufacturer: "9634140",
      skuInternal: "00019594",
      balance: "3",
      price: 3213.08,
      filterUsed: false
    },
    {
      id: 50,
      visible: true,
      inCartVisible: false,
      inCartProductAmount: 0,
      inCartProductAmountAdded: '0',
      cat: "Удлинители и сетевые фильтры",
      img: ["../../img/50/50.jpg"],
      alt: null,
      title: "32.75.221.0410 Удлинитель (ПВС 3х1) 2 гнезда евро 10м (T-Plast)",
      sockets: "2",
      cableLength: "10",
      grounding: "Есть",
      cable: "ПВС 3х1 мм2",
      ratedCurrent: "16 А",
      load: "3.5",
      switchButton: "Нет",
      color: "белый",
      protectionDegree: "IP20",
      type: "бытовой",
      brand: "T.plast",
      skuManufacturer: "32.75.221.0410",
      skuInternal: "00052541",
      balance: "15",
      price: 348.29,
      filterUsed: false
    },
    {
      id: 51,
      visible: true,
      inCartVisible: false,
      inCartProductAmount: 0,
      inCartProductAmountAdded: '0',
      cat: "Удлинители и сетевые фильтры",
      img: ["../../img/51/51.jpg"],
      alt: null,
      title: "32.75.411.0405 Удлинитель (ПВС 3х1) 4 гнезда евро c выключательючателем с подсветкой 5м (T-Plast)",
      sockets: "4",
      cableLength: "5",
      grounding: "Есть",
      cable: "ПВС 3х1 мм2",
      ratedCurrent: "16 А",
      load: "3.5",
      switchButton: "Есть",
      color: "белый",
      protectionDegree: "IP20",
      type: "бытовой",
      brand: "T.plast",
      skuManufacturer: "32.75.411.0405",
      skuInternal: "00052562",
      balance: "10",
      price: 277.38,
      filterUsed: false
    },
    {
      id: 52,
      visible: true,
      inCartVisible: false,
      inCartProductAmount: 0,
      inCartProductAmountAdded: '0',
      cat: "Удлинители и сетевые фильтры",
      img: ["../../img/52/52.jpg"],
      alt: null,
      title: "32.75.421.0405 Удлинитель (ПВС 3х1) 4 гнезда евро 5м (T-Plast)",
      sockets: "4",
      cableLength: "5",
      grounding: "Есть",
      cable: "ПВС 3х1 мм2",
      ratedCurrent: "16 А",
      load: "3.5",
      switchButton: "Нет",
      color: "белый",
      protectionDegree: "IP20",
      type: "бытовой",
      brand: "T.plast",
      skuManufacturer: "32.75.421.0405",
      skuInternal: "00052552",
      balance: "14",
      price: 239.60,
      filterUsed: false
    },
    {
      id: 53,
      visible: true,
      inCartVisible: false,
      inCartProductAmount: 0,
      inCartProductAmountAdded: '0',
      cat: "Удлинители и сетевые фильтры",
      img: ["../../img/53/53.jpg"],
      alt: null,
      title: "32.75.421.0410 Удлинитель (ПВС 3х1) 4 гнезда евро 10м (T-Plast)",
      sockets: "4",
      cableLength: "10",
      grounding: "Есть",
      cable: "ПВС 3х1 мм2",
      ratedCurrent: "16 А",
      load: "3.5",
      switchButton: "Нет",
      color: "белый",
      protectionDegree: "IP20",
      type: "бытовой",
      brand: "T.plast",
      skuManufacturer: "32.75.421.0410",
      skuInternal: "00052554",
      balance: "8",
      price: 374.15,
      filterUsed: false
    },
    {
      id: 54,
      visible: true,
      inCartVisible: false,
      inCartProductAmount: 0,
      inCartProductAmountAdded: '0',
      cat: "Удлинители и сетевые фильтры",
      img: ["../../img/54/54.jpg"],
      alt: null,
      title: "9631960 Удлинитель Премиум класс (ПВС2х1) 2-мест., L= 3,0м/Е-202 ( АБС) Универсал ",
      sockets: "2",
      cableLength: "3",
      grounding: "Нет",
      cable: "ПВС 2х1 мм2",
      ratedCurrent: "16 А",
      load: "2.3",
      switchButton: "Нет",
      color: "белый",
      protectionDegree: "IP20",
      type: "бытовой",
      brand: "Универсал (UNIVersal)",
      skuManufacturer: null,
      skuInternal: "195608",
      balance: "12",
      price: 200.00,
      filterUsed: false
    },
    {
      id: 55,
      visible: true,
      inCartVisible: false,
      inCartProductAmount: 0,
      inCartProductAmountAdded: '0',
      cat: "Удлинители и сетевые фильтры",
      img: ["../../img/55/55.jpg"],
      alt: null,
      title: "9631961 Удлинитель Премиум класс (ПВС2х1) 2-мест., L= 5,0м / Е-202 ( АБС) Универсал ",
      sockets: "2",
      cableLength: "5",
      grounding: "Нет",
      cable: "ПВС 2х1 мм2",
      ratedCurrent: "16 А",
      load: "2.3",
      switchButton: "Нет",
      color: "белый",
      protectionDegree: "IP20",
      type: "бытовой",
      brand: "Универсал (UNIVersal)",
      skuManufacturer: null,
      skuInternal: "195609",
      balance: "10",
      price: 220.00,
      filterUsed: false
    },
    {
      id: 56,
      visible: true,
      inCartVisible: false,
      inCartProductAmount: 0,
      inCartProductAmountAdded: '0',
      cat: "Удлинители и сетевые фильтры",
      img: ["../../img/56/56.jpg"],
      alt: null,
      title: "9631967 Удлинитель Премиум класс (ПВС2х1) 3-мест., L= 10,0м / Е-203 ( АБС) Универсал",
      sockets: "3",
      cableLength: "10",
      grounding: "Нет",
      cable: "ПВС 2х1 мм2",
      ratedCurrent: "16 А",
      load: "2.3",
      switchButton: "Нет",
      color: "белый",
      protectionDegree: "IP20",
      type: "бытовой",
      brand: "Универсал (UNIVersal)",
      skuManufacturer: "9631967",
      skuInternal: "00041421",
      balance: "4",
      price: 423.30,
      filterUsed: false
    },
    {
      id: 57,
      visible: true,
      inCartVisible: false,
      inCartProductAmount: 0,
      inCartProductAmountAdded: '0',
      cat: "Удлинители и сетевые фильтры",
      img: ["../../img/57/57.jpg"],
      alt: null,
      title: "9631984 Удлинитель Премиум класс (ПВС3х1) 4-мест., L= 10,0м / Е-304 (евро, АБС) Универсал",
      sockets: "4",
      cableLength: "10",
      grounding: "Есть",
      cable: "ПВС 3х1 мм2",
      ratedCurrent: "10 А",
      load: "3",
      switchButton: "Нет",
      color: "белый",
      protectionDegree: "IP20",
      type: "бытовой",
      brand: "Универсал (UNIVersal)",
      skuManufacturer: "9631984",
      skuInternal: "00012972",
      balance: "34",
      price: 608.72,
      filterUsed: false
    },
    {
      id: 58,
      visible: true,
      inCartVisible: false,
      inCartProductAmount: 0,
      inCartProductAmountAdded: '0',
      cat: "Удлинители и сетевые фильтры",
      img: ["../../img/58/58.jpg"],
      alt: null,
      title: "002-5М Удлинитель-рулетка универсальный (ШВВП2х0,75) со вставкой 4-х мест.5 м / У6-002 Универсал",
      sockets: "4",
      cableLength: "5",
      grounding: "Нет",
      cable: "ШВВП 2х0,75 мм2",
      ratedCurrent: "6 А",
      load: null,
      switchButton: "Нет",
      color: "белый",
      protectionDegree: "IP20",
      type: "рулетка",
      brand: "Универсал (UNIVersal)",
      skuManufacturer: null,
      skuInternal: null,
      balance: null,
      price: 302.08,
      filterUsed: false
    },
    {
      id: 59,
      visible: true,
      inCartVisible: false,
      inCartProductAmount: 0,
      inCartProductAmountAdded: '0',
      cat: "Удлинители и сетевые фильтры",
      img: ["../../img/59/59.jpg"],
      alt: null,
      title: "002-7М Удлинитель-рулетка универсальный (ШВВП2х0,75) со вставкой 4-х мест.7 м / У6-002 Универсал",
      sockets: "4",
      cableLength: "7",
      grounding: "Нет",
      cable: "ШВВП 2х0,75 мм2",
      ratedCurrent: "6 А",
      load: null,
      switchButton: "Нет",
      color: "белый",
      protectionDegree: "IP20",
      type: "рулетка",
      brand: "Универсал (UNIVersal)",
      skuManufacturer: null,
      skuInternal: null,
      balance: null,
      price: 190.81,
      filterUsed: false
    },
    {
      id: 60,
      visible: true,
      inCartVisible: false,
      inCartProductAmount: 0,
      inCartProductAmountAdded: '0',
      cat: "Удлинители и сетевые фильтры",
      img: ["../../img/60/60.jpg"],
      alt: null,
      title: "011М-05 Удлинитель (ПВС 2х0,75) 2-мест., L= 5,0м / У6-011 М (люкс) Универсал",
      sockets: "2",
      cableLength: "5",
      grounding: "Нет",
      cable: "ПВС 2х0,75 мм2",
      ratedCurrent: "10 А",
      load: "2.3",
      switchButton: "Нет",
      color: "белый",
      protectionDegree: "IP20",
      type: "бытовой",
      brand: "Универсал (UNIVersal)",
      skuManufacturer: "011М-05",
      skuInternal: "00033782",
      balance: "14",
      price: 193.75,
      filterUsed: false
    },
    {
      id: 61,
      visible: true,
      inCartVisible: false,
      inCartProductAmount: 0,
      inCartProductAmountAdded: '0',
      cat: "Удлинители и сетевые фильтры",
      img: ["../../img/61/61.jpg"],
      alt: null,
      title: "011М-10 Удлинитель (ПВС 2х0,75) 2-мест., L= 10м / У6-011 М (люкс) Универсал",
      sockets: "2",
      cableLength: "10",
      grounding: "Нет",
      cable: "ПВС 2х0,75 мм2",
      ratedCurrent: "10 А",
      load: "2.3",
      switchButton: "Нет",
      color: "белый",
      protectionDegree: "IP20",
      type: "бытовой",
      brand: "Универсал (UNIVersal)",
      skuManufacturer: "011М-10",
      skuInternal: "00037696",
      balance: "11",
      price: 404.29,
      filterUsed: false
    },
    {
      id: 62,
      visible: true,
      inCartVisible: false,
      inCartProductAmount: 0,
      inCartProductAmountAdded: '0',
      cat: "Удлинители и сетевые фильтры",
      img: ["../../img/62/62.jpg"],
      alt: null,
      title: "555-07 Удлинитель (ПВС3х0,75) 4-мест., L= 7,0м / У10-555 (евро) Универсал",
      sockets: "4",
      cableLength: "7",
      grounding: "Есть",
      cable: "ПВС 3х0,75 мм2",
      ratedCurrent: "10 А",
      load: "2.3",
      switchButton: "Нет",
      color: "белый",
      protectionDegree: "IP20",
      type: "бытовой",
      brand: "Универсал (UNIVersal)",
      skuManufacturer: "555-07",
      skuInternal: "00040385",
      balance: "17",
      price: 452.59,
      filterUsed: false
    },
    {
      id: 63,
      visible: true,
      inCartVisible: false,
      inCartProductAmount: 0,
      inCartProductAmountAdded: '0',
      cat: "Удлинители и сетевые фильтры",
      img: ["../../img/63/63.jpg"],
      alt: null,
      title: "555-10 Удлинитель (ПВС3х0,75) 4-мест., L= 10,0м / У10-555 (евро) Универсал",
      sockets: "4",
      cableLength: "10",
      grounding: "Есть",
      cable: "ПВС 3х0,75 мм2",
      ratedCurrent: "10 А",
      load: "2.3",
      switchButton: "Нет",
      color: "белый",
      protectionDegree: "IP20",
      type: "бытовой",
      brand: "Универсал (UNIVersal)",
      skuManufacturer: "555-10",
      skuInternal: "00006909",
      balance: "39",
      price: 582.00,
      filterUsed: false
    },
    {
      id: 64,
      visible: true,
      inCartVisible: false,
      inCartProductAmount: 0,
      inCartProductAmountAdded: '0',
      cat: "Удлинители и сетевые фильтры",
      img: ["../../img/64/64.jpg"],
      alt: null,
      title: "Удлинитель бытовой  У03В выключатель, 3 гнезда, 5метров, с заземлением, ПВС 3х1мм2  16А/250В TDM [SQ1303-0102]",
      sockets: "3",
      cableLength: "5",
      grounding: "Есть",
      cable: "ПВС 3х1 мм2",
      ratedCurrent: "16 А",
      load: "3.5",
      switchButton: "Есть",
      color: "белый",
      protectionDegree: "IP20",
      type: "бытовой",
      brand: "TDM Electric (ТДМ Электрик)",
      skuManufacturer: "SQ1303-0102",
      skuInternal: "00559123",
      balance: "10",
      price: 433.68,
      filterUsed: false
    },
    {
      id: 65,
      visible: true,
      inCartVisible: false,
      inCartProductAmount: 0,
      inCartProductAmountAdded: '0',
      cat: "Вилки",
      img: ["../../img/65/65.jpg"],
      alt: null,
      title: "A113 Вилка б/з белая 6А 250В (500шт/уп)",
      sockets: null,
      cableLength: null,
      grounding: "Нет",
      cable: null,
      ratedCurrent: "6 А",
      load: null,
      switchButton: null,
      color: "белый",
      protectionDegree: null,
      type: null,
      brand: "Универсал (UNIVersal)",
      skuManufacturer: "A113",
      skuInternal: "211536",
      balance: "35",
      price: 21.22,
      filterUsed: false
    },
    {
      id: 66,
      visible: true,
      inCartVisible: false,
      inCartProductAmount: 0,
      inCartProductAmountAdded: '0',
      cat: "Вилки",
      img: ["../../img/66/66.jpg"],
      alt: null,
      title: "Вилка угловая плоская (8 мм) белая 16А 250В TDM [SQ1806-0193]",
      sockets: null,
      cableLength: null,
      grounding: null,
      cable: null,
      ratedCurrent: "16 А",
      load: null,
      switchButton: null,
      color: "белый",
      protectionDegree: null,
      type: null,
      brand: "TDM Electric (ТДМ Электрик)",
      skuManufacturer: "SQ1806-0193",
      skuInternal: "00559761",
      balance: "12",
      price: 96.40,
      filterUsed: false
    },
    {
      id: 67,
      visible: true,
      inCartVisible: false,
      inCartProductAmount: 0,
      inCartProductAmountAdded: '0',
      cat: "Вилки",
      img: ["../../img/67/67.jpg"],
      alt: null,
      title: "Вилка угловая с/з белая 16А 250В TDM [SQ1806-0007]",
      sockets: null,
      cableLength: null,
      grounding: "Есть",
      cable: null,
      ratedCurrent: "16 А",
      load: null,
      switchButton: null,
      color: "белый",
      protectionDegree: null,
      type: null,
      brand: "TDM Electric (ТДМ Электрик)",
      skuManufacturer: "SQ1806-0007",
      skuInternal: "00559765",
      balance: "5",
      price: 36.83,
      filterUsed: false
    },
    {
      id: 68,
      visible: true,
      inCartVisible: false,
      inCartProductAmount: 0,
      inCartProductAmountAdded: '0',
      cat: "Вилки",
      img: ["../../img/68/68.jpg"],
      alt: null,
      title: "Вилка штепс. В16-002 (евро) угловая с кембриком 16 А",
      sockets: null,
      cableLength: null,
      grounding: "Есть",
      cable: null,
      ratedCurrent: "16 А",
      load: null,
      switchButton: null,
      color: "белый",
      protectionDegree: null,
      type: null,
      brand: "BYLECTRICA",
      skuManufacturer: null,
      skuInternal: "249371",
      balance: "227",
      price: 25.20,
      filterUsed: false
    },
    {
      id: 69,
      visible: true,
      inCartVisible: false,
      inCartProductAmount: 0,
      inCartProductAmountAdded: '0',
      cat: "Штепсельные гнезда",
      img: ["../../img/69/69.jpg"],
      alt: null,
      title: "А0115 Штепсельное гнездо б/з черное 10А 250В (200шт/уп)",
      sockets: null,
      cableLength: null,
      grounding: "Нет",
      cable: null,
      ratedCurrent: "10 А",
      load: null,
      switchButton: null,
      color: "черный",
      protectionDegree: null,
      type: null,
      brand: "Универсал (UNIVersal)",
      skuManufacturer: null,
      skuInternal: null,
      balance: null,
      price: 28.85,
      filterUsed: false
    },
    {
      id: 70,
      visible: true,
      inCartVisible: false,
      inCartProductAmount: 0,
      inCartProductAmountAdded: '0',
      cat: "Штепсельные гнезда",
      img: ["../../img/70/70.jpg"],
      alt: null,
      title: "А102 Штепсельное гнездо с/з белое 16А 250В (200шт/уп)",
      sockets: null,
      cableLength: null,
      grounding: "Есть",
      cable: null,
      ratedCurrent: "16 А",
      load: null,
      switchButton: null,
      color: "белый",
      protectionDegree: null,
      type: null,
      brand: "Универсал (UNIVersal)",
      skuManufacturer: null,
      skuInternal: null,
      balance: null,
      price: 47.74,
      filterUsed: false
    },
    {
      id: 71,
      visible: true,
      inCartVisible: false,
      inCartProductAmount: 0,
      inCartProductAmountAdded: '0',
      cat: "Штепсельные гнезда",
      img: ["../../img/71/71.jpg"],
      alt: null,
      title: "А1020 Штепсельное гнездо с/з черное 16А 250В (200шт/уп)",
      sockets: null,
      cableLength: null,
      grounding: "Есть",
      cable: null,
      ratedCurrent: "16 А",
      load: null,
      switchButton: null,
      color: "черный",
      protectionDegree: null,
      type: null,
      brand: "Универсал (UNIVersal)",
      skuManufacturer: null,
      skuInternal: null,
      balance: null,
      price: 38.74,
      filterUsed: false
    },
    {
      id: 72,
      visible: true,
      inCartVisible: false,
      inCartProductAmount: 0,
      inCartProductAmountAdded: '0',
      cat: "Штепсельные гнезда",
      img: ["../../img/72/72.jpg"],
      alt: null,
      title: "ОА0150 Штепсельное гнездо б/з черное ПВХ-облив 10А 250В (150шт/уп)",
      sockets: null,
      cableLength: null,
      grounding: "Нет",
      cable: null,
      ratedCurrent: "10 А",
      load: null,
      switchButton: null,
      color: "черный",
      protectionDegree: null,
      type: null,
      brand: "Универсал (UNIVersal)",
      skuManufacturer: null,
      skuInternal: null,
      balance: null,
      price: 36.36,
      filterUsed: false
    },
    {
      id: 73,
      visible: true,
      inCartVisible: false,
      inCartProductAmount: 0,
      inCartProductAmountAdded: '0',
      cat: "Штепсельные гнезда",
      img: ["../../img/73/73.jpg"],
      alt: null,
      title: "ОА1020 Штепсельное гнездо с/з черное ПВХ-облив 16А 250В (150шт/уп)",
      sockets: null,
      cableLength: null,
      grounding: "Есть",
      cable: null,
      ratedCurrent: "16 А",
      load: null,
      switchButton: null,
      color: "черный",
      protectionDegree: null,
      type: null,
      brand: "Универсал (UNIVersal)",
      skuManufacturer: null,
      skuInternal: null,
      balance: null,
      price: 35.15,
      filterUsed: false
    },
    {
      id: 74,
      visible: true,
      inCartVisible: false,
      inCartProductAmount: 0,
      inCartProductAmountAdded: '0',
      cat: "Штепсельные гнезда",
      img: ["../../img/74/74.jpg"],
      alt: null,
      title: "А115 Штепсельное гнездо б/з белое 10А 250В (200шт/уп)",
      sockets: null,
      cableLength: null,
      grounding: "Нет",
      cable: null,
      ratedCurrent: "10 А",
      load: null,
      switchButton: null,
      color: "белый",
      protectionDegree: null,
      type: null,
      brand: "Универсал (UNIVersal)",
      skuManufacturer: null,
      skuInternal: null,
      balance: null,
      price: 29.95,
      filterUsed: false
    },
    {
      id: 75,
      visible: true,
      inCartVisible: false,
      inCartProductAmount: 0,
      inCartProductAmountAdded: '0',
      cat: "Штепсельные гнезда",
      img: ["../../img/75/75.jpg"],
      alt: null,
      title: "602228 Штепсельный разъем с заглушкой однофазный (каучук) (50шт/уп)",
      sockets: null,
      cableLength: null,
      grounding: null,
      cable: null,
      ratedCurrent: null,
      load: null,
      switchButton: null,
      color: "черный",
      protectionDegree: "IP44",
      type: null,
      brand: "Универсал (UNIVersal)",
      skuManufacturer: "602228",
      skuInternal: "00049919",
      balance: "47",
      price: 73.18,
      filterUsed: false
    },
    {
      id: 76,
      visible: true,
      inCartVisible: false,
      inCartProductAmount: 0,
      inCartProductAmountAdded: '0',
      cat: "Выключатели проходные (бра)",
      img: ["../../img/76/76.jpg"],
      alt: null,
      title: "А106 Переключатель бра белый 6А 250В (100шт/уп)",
      sockets: null,
      cableLength: null,
      grounding: null,
      cable: null,
      ratedCurrent: "6 А",
      load: null,
      switchButton: null,
      color: "белый",
      protectionDegree: null,
      type: null,
      brand: "Универсал (UNIVersal)",
      skuManufacturer: "А106",
      skuInternal: "00022723",
      balance: "80",
      price: 26.08,
      filterUsed: false
    },
    {
      id: 77,
      visible: true,
      inCartVisible: false,
      inCartProductAmount: 0,
      inCartProductAmountAdded: '0',
      cat: "Выключатели проходные (бра)",
      img: ["../../img/77/77.jpg"],
      alt: null,
      title: "А0106 Переключатель бра черный 6А 250В (100шт/уп)",
      sockets: null,
      cableLength: null,
      grounding: null,
      cable: null,
      ratedCurrent: "6 А",
      load: null,
      switchButton: null,
      color: "черный",
      protectionDegree: null,
      type: null,
      brand: "Универсал (UNIVersal)",
      skuManufacturer: "А0106",
      skuInternal: "00022724",
      balance: "229",
      price: 15.65,
      filterUsed: false
    },
    {
      id: 78,
      visible: true,
      inCartVisible: false,
      inCartProductAmount: 0,
      inCartProductAmountAdded: '0',
      cat: "Выключатели проходные (бра)",
      img: ["../../img/78/78.jpg"],
      alt: null,
      title: "Выключатель проходной (черный)",
      sockets: null,
      cableLength: null,
      grounding: null,
      cable: null,
      ratedCurrent: null,
      load: null,
      switchButton: null,
      color: "черный",
      protectionDegree: null,
      type: null,
      brand: "BYLECTRICA",
      skuManufacturer: null,
      skuInternal: "00001691",
      balance: "690",
      price: 1.96,
      filterUsed: false
    },
    {
      id: 79,
      visible: true,
      inCartVisible: false,
      inCartProductAmount: 0,
      inCartProductAmountAdded: '0',
      cat: "Выключатели проходные (бра)",
      img: ["../../img/79/79.jpg"],
      alt: null,
      title: "Выключатель проходной ползунковый ПР6-09-2/250",
      sockets: null,
      cableLength: null,
      grounding: null,
      cable: null,
      ratedCurrent: null,
      load: null,
      switchButton: null,
      color: "черный",
      protectionDegree: null,
      type: null,
      brand: "BYLECTRICA",
      skuManufacturer: "ПР6-09-2/250",
      skuInternal: "00010841",
      balance: "66",
      price: 16.18,
      filterUsed: false
    },
    {
      id: 80,
      visible: true,
      inCartVisible: false,
      inCartProductAmount: 0,
      inCartProductAmountAdded: '0',
      cat: "Шнуры с вилкой",
      img: ["../../img/80/80.jpg"],
      alt: null,
      title: "Шнур с вилкой L-2,2м белый ПВС-ВП 2х0.75 (204-029)",
      sockets: null,
      cableLength: "2.2",
      grounding: null,
      cable: "ПВС 2х0,75 мм2",
      ratedCurrent: null,
      load: null,
      switchButton: null,
      color: "белый",
      protectionDegree: null,
      type: null,
      brand: ".",
      skuManufacturer: "ПВС-ВП 2х0.75 (204-029)",
      skuInternal: "00003552",
      balance: "9",
      price: 54.96,
      filterUsed: false
    },
    {
      id: 81,
      visible: true,
      inCartVisible: false,
      inCartProductAmount: 0,
      inCartProductAmountAdded: '0',
      cat: "Тройники и разветвители",
      img: ["../../img/81/troynik-univers-zt-toker-6-a-chernyy.jpg"],
      alt: null,
      title: "Тройник универсальный ЗТ TOKER 6 А черный",
      sockets: "3",
      cableLength: null,
      grounding: null,
      cable: null,
      ratedCurrent: "6 А",
      load: null,
      switchButton: null,
      color: "черный",
      protectionDegree: null,
      type: null,
      brand: "ООО БелТиз",
      skuManufacturer: null,
      skuInternal: "00004239",
      balance: "7",
      price: 40.75,
      filterUsed: false
    },
    {
      id: 82,
      visible: true,
      inCartVisible: false,
      inCartProductAmount: 0,
      inCartProductAmountAdded: '0',
      cat: "Тройники и разветвители",
      img: ["../../img/82/82.jpg"],
      alt: null,
      title: "Разветвитель РВ16-258 (белый, евровилка /3-х местный. универсальная, АБС, Минск)",
      sockets: "3",
      cableLength: null,
      grounding: null,
      cable: null,
      ratedCurrent: "16 А",
      load: null,
      switchButton: null,
      color: "белый",
      protectionDegree: null,
      type: null,
      brand: "BYLECTRICA",
      skuManufacturer: null,
      skuInternal: "00003380",
      balance: "1",
      price: 186.97,
      filterUsed: false
    },
    {
      id: 83,
      visible: true,
      inCartVisible: false,
      inCartProductAmount: 0,
      inCartProductAmountAdded: '0',
      cat: "Тройники и разветвители",
      img: ["../../img/83/83.jpg"],
      alt: null,
      title: "А202 Разветвитель 2гн. б/з плоский белый 6А 250В (50шт/уп)",
      sockets: "2",
      cableLength: null,
      grounding: "Нет",
      cable: null,
      ratedCurrent: "6 А",
      load: null,
      switchButton: null,
      color: "белый",
      protectionDegree: null,
      type: null,
      brand: "Универсал (UNIVersal)",
      skuManufacturer: "А202",
      skuInternal: "00022685",
      balance: "25",
      price: 87.57,
      filterUsed: false
    },
    {
      id: 84,
      visible: true,
      inCartVisible: false,
      inCartProductAmount: 0,
      inCartProductAmountAdded: '0',
      cat: "Телефонные удлинители",
      img: ["../../img/84/84.jpg"],
      alt: null,
      title: "Телефонный удлинитель (евро) 10м",
      sockets: null,
      cableLength: "10",
      grounding: null,
      cable: null,
      ratedCurrent: null,
      load: null,
      switchButton: null,
      color: "белый",
      protectionDegree: null,
      type: null,
      brand: "Джек-Джек",
      skuManufacturer: null,
      skuInternal: "00003347",
      balance: "41",
      price: 43.48,
      filterUsed: false
    },
    {
      id: 85,
      visible: true,
      inCartVisible: false,
      inCartProductAmount: 0,
      inCartProductAmountAdded: '0',
      cat: "Телефонные удлинители",
      img: ["../../img/85/85.jpg"],
      alt: null,
      title: "Телефонный удлинитель (евро) 20м",
      sockets: null,
      cableLength: "20",
      grounding: null,
      cable: null,
      ratedCurrent: null,
      load: null,
      switchButton: null,
      color: "белый",
      protectionDegree: null,
      type: null,
      brand: "Джек-Джек",
      skuManufacturer: null,
      skuInternal: "00003349",
      balance: "40",
      price: 74.27,
      filterUsed: false
    },
    {
      id: 86,
      visible: true,
      inCartVisible: false,
      inCartProductAmount: 0,
      inCartProductAmountAdded: '0',
      cat: "Колодки",
      img: ["../../img/86/86.jpg"],
      alt: null,
      title: "Р16-413 Колодка 1 гнездо с/з c крышкой каучуковая IP44",
      sockets: "1",
      cableLength: null,
      grounding: "Есть",
      cable: null,
      ratedCurrent: null,
      load: null,
      switchButton: null,
      color: "черный",
      protectionDegree: "IP44",
      type: null,
      brand: "Минск",
      skuManufacturer: "Р16-413",
      skuInternal: "265331",
      balance: "6",
      price: 91.91,
      filterUsed: false
    },
    {
      id: 87,
      visible: true,
      inCartVisible: false,
      inCartProductAmount: 0,
      inCartProductAmountAdded: '0',
      cat: "Колодки",
      img: ["../../img/87/87.jpg"],
      alt: null,
      title: "E305 Колодка 5 гнезда с/з AБС-пластик (60шт/уп)",
      sockets: "5",
      cableLength: null,
      grounding: "Есть",
      cable: null,
      ratedCurrent: null,
      load: null,
      switchButton: null,
      color: "белый",
      protectionDegree: "IP20",
      type: null,
      brand: "Универсал (UNIVersal)",
      skuManufacturer: "E305",
      skuInternal: "211539",
      balance: "16",
      price: 153.10,
      filterUsed: false
    },
    {
      id: 88,
      visible: true,
      inCartVisible: false,
      inCartProductAmount: 0,
      inCartProductAmountAdded: '0',
      cat: "Колодки",
      img: ["../../img/88/88.jpg"],
      alt: null,
      title: "E206 Колодка 6 гнезда б/з AБС-пластик (60шт/уп)",
      sockets: "6",
      cableLength: null,
      grounding: "Есть",
      cable: null,
      ratedCurrent: null,
      load: null,
      switchButton: null,
      color: "белый",
      protectionDegree: "IP20",
      type: null,
      brand: "Универсал (UNIVersal)",
      skuManufacturer: "E206",
      skuInternal: "211538",
      balance: "25",
      price: 146.47,
      filterUsed: false
    },
    {
      id: 89,
      visible: true,
      inCartVisible: false,
      inCartProductAmount: 0,
      inCartProductAmountAdded: '0',
      cat: "Разъемы питания 2.1х5.5 с клеммной колодкой",
      img: ["../../img/89/89.jpg"],
      alt: null,
      title: "Разъем питания гнездо 2.1х5.5 с клеммной колодкой REXANT [14-0315]",
      sockets: null,
      cableLength: null,
      grounding: null,
      cable: null,
      ratedCurrent: null,
      load: null,
      switchButton: null,
      color: null,
      protectionDegree: null,
      type: null,
      brand: "REXANT",
      skuManufacturer: "14-0315",
      skuInternal: "00614654",
      balance: "16",
      price: 25.79,
      filterUsed: false
    },
    {
      id: 90,
      visible: true,
      inCartVisible: false,
      inCartProductAmount: 0,
      inCartProductAmountAdded: '0',
      cat: "Разъемы питания 2.1х5.5 с клеммной колодкой",
      img: ["../../img/90/90.jpg"],
      alt: null,
      title: "Разъем питания штекер 2.1х5.5 с клеммной колодкой (блистер) REXANT [14-0314]",
      sockets: null,
      cableLength: null,
      grounding: null,
      cable: null,
      ratedCurrent: null,
      load: null,
      switchButton: null,
      color: null,
      protectionDegree: null,
      type: null,
      brand: "REXANT",
      skuManufacturer: "14-0314",
      skuInternal: "00614655",
      balance: "16",
      price: 16.45,
      filterUsed: false
    },

    /*{
      id: 91,
      visible: true,
      inCartVisible: false,
      inCartProductAmount: 0,
      inCartProductAmountAdded: '0',
      cat: "Удлинители и сетевые фильтры",
      img: ["../../img/1/setevoi-filtr-tdm-sq1303-0021-3m-5-rozetok -1.jpg"],
      alt: null,
      title: "TEST - Удлинитель бытовой  У05  5 гнезд, 3метра, с заземлением, ПВС  3х1мм2  16А/250В TDM [SQ1303-0021]",
      sockets: "5",
      cableLength: "3",
      grounding: "Нет",
      cable: "ПВС 3х0,75 мм2",
      ratedCurrent: "16 А",
      load: "2.2",
      switchButton: "Есть",
      color: "белый",
      protectionDegree: "IP54",
      type: "бытовой",
      brand: "TDM Electric (ТДМ Электрик)",
      skuManufacturer: "SQ1303-0021",
      skuInternal: "00559119",
      balance: "20",
      price: 362.00,
      filterUsed: false
    },*/
    {
      id: 92,
      visible: true,
      inCartVisible: false,
      inCartProductAmount: 0,
      inCartProductAmountAdded: '0',
      cat: "Автоматические выключатели модульные",
img: ["../../img/92/92.jpg"],
      alt: null,
      title: "Автоматический выключатель ВА47-63 1P 6А C 4,5kA EKF PROxima [mcb4763-1-06C-pro]",
      series: "ВА47-63",
      poleNumber: "1P",
      current: "6",
      characteristics: "С",
      breakingCapacity: "4,5kA",
      type: "Модульный",
      brand: "EKF",
      skuManufacturer: "mcb4763-1-06C-pro",
      skuInternal: "00356124",
      balance: "5",
      price: 95.42,
      filterUsed: false
    },
    {
      id: 93,
      visible: true,
      inCartVisible: false,
      inCartProductAmount: 0,
      inCartProductAmountAdded: '0',
      cat: "Автоматические выключатели модульные",
img: ["../../img/93/93.jpg"],
      alt: null,
      title: "Автоматический выключатель SH201L C6 4,5кА [2CDS241001R0064]",
      series: "SH201L",
      poleNumber: "1P",
      current: "6",
      characteristics: "С",
      breakingCapacity: "4,5kA",
      type: "Модульный",
      brand: "ABB",
      skuManufacturer: "2CDS241001R0064",
      skuInternal: "00027687",
      balance: "1",
      price: 373.92,
      filterUsed: false
    },
    {
      id: 94,
      visible: true,
      inCartVisible: false,
      inCartProductAmount: 0,
      inCartProductAmountAdded: '0',
      cat: "Автоматические выключатели модульные",
img: ["../../img/94/94.jpg"],
      alt: null,
      title: "Автоматический выключатель ВА47-29-1С6-УХЛ3-КЭАЗ 4,5кА [141485]",
      series: "ВА47-29",
      poleNumber: "1P",
      current: "6",
      characteristics: "С",
      breakingCapacity: "4,5kA",
      type: "Модульный",
      brand: "КЭАЗ",
      skuManufacturer: "141485",
      skuInternal: "172686",
      balance: "23",
      price: 89.34,
      filterUsed: false
    },
    {
      id: 95,
      visible: true,
      inCartVisible: false,
      inCartProductAmount: 0,
      inCartProductAmountAdded: '0',
      cat: "Автоматические выключатели модульные",
img: ["../../img/95/95.jpg"],
      alt: null,
      title: "Автоматический выключатель ВА47-29 1Р 8А 4,5кА х-ка С ИЭК [MVA20-1-008-C]",
      series: "ВА47-29",
      poleNumber: "1P",
      current: "8",
      characteristics: "С",
      breakingCapacity: "4,5kA",
      type: "Модульный",
      brand: "IEK",
      skuManufacturer: "MVA20-1-008-C",
      skuInternal: "00010184",
      balance: "2",
      price: 51.14,
      filterUsed: false
    },
    {
      id: 96,
      visible: true,
      inCartVisible: false,
      inCartProductAmount: 0,
      inCartProductAmountAdded: '0',
      cat: "Автоматические выключатели модульные",
img: ["../../img/96/96.jpg"],
      alt: null,
      title: "Автоматический выключатель Z406 1Р C20 4,5кА ELVERT Z4061C-20",
      series: "Z406",
      poleNumber: "1P",
      current: "20",
      characteristics: "С",
      breakingCapacity: "4,5kA",
      type: "Модульный",
      brand: "ELVERT",
      skuManufacturer: "Z4061C-20",
      balance: 6,
      price: 74.20,
      filterUsed: false
    },
    {
      id: 97,
      visible: true,
      inCartVisible: false,
      inCartProductAmount: 0,
      inCartProductAmountAdded: '0',
      cat: "Автоматические выключатели модульные",
img: ["../../img/94/94.jpg"],
      alt: null,
      title: "Автоматический выключатель ВА47-29-1С20-УХЛ3-КЭАЗ 4,5кА [141554]",
      series: "ВА47-29",
      poleNumber: "1P",
      current: "20",
      characteristics: "С",
      breakingCapacity: "4,5kA",
      type: "Модульный",
      brand: "КЭАЗ",
      skuManufacturer: "141554",
      skuInternal: "172680",
      balance: "39",
      price: 83.66,
      filterUsed: false
    },
    {
      id: 98,
      visible: true,
      inCartVisible: false,
      inCartProductAmount: 0,
      inCartProductAmountAdded: '0',
      cat: "Автоматические выключатели модульные",
img: ["../../img/98/98.jpg"],
      alt: null,
      title: "Автоматический выключатель ВА47-29 1Р 25А 4,5кА х-ка С GENERICA [MVA25-1-025-C]",
      series: "ВА47-29",
      poleNumber: "1P",
      current: "25",
      characteristics: "С",
      breakingCapacity: "4,5kA",
      type: "Модульный",
      brand: "IEK",
      skuManufacturer: "MVA25-1-025-C",
      skuInternal: "00473066",
      balance: "20",
      price: 98.01,
      filterUsed: false
    },
    {
      id: 99,
      visible: true,
      inCartVisible: false,
      inCartProductAmount: 0,
      inCartProductAmountAdded: '0',
      cat: "Автоматические выключатели модульные",
img: ["../../img/92/92.jpg"],
      alt: null,
      title: "Автоматический выключатель ВА47-63 1P 40А С 4.5кА EKF PROxima [mcb4763-1-40C-pro]",
      series: "ВА47-63",
      poleNumber: "1P",
      current: "40",
      characteristics: "С",
      breakingCapacity: "4,5kA",
      type: "Модульный",
      brand: "EKF",
      skuManufacturer: "mcb4763-1-40C-pro",
      skuInternal: "00539680",
      balance: "1",
      price: 99.30,
      filterUsed: false
    },
    {
      id: 100,
      visible: true,
      inCartVisible: false,
      inCartProductAmount: 0,
      inCartProductAmountAdded: '0',
      cat: "Автоматические выключатели модульные",
img: ["../../img/92/92.jpg"],
      alt: null,
      title: "Автоматический выключатель ВА47-63 1P 50А С 4.5кА EKF PROxima [mcb4763-1-50C-pro]",
      series: "ВА47-63",
      poleNumber: "1P",
      current: "50",
      characteristics: "С",
      breakingCapacity: "4,5kA",
      type: "Модульный",
      brand: "EKF",
      skuManufacturer: "mcb4763-1-50C-pro",
      skuInternal: "00539679",
      balance: "2",
      price: 125.77,
      filterUsed: false
    },
    {
      id: 101,
      visible: true,
      inCartVisible: false,
      inCartProductAmount: 0,
      inCartProductAmountAdded: '0',
      cat: "Автоматические выключатели модульные",
img: ["../../img/95/95.jpg"],
      alt: null,
      title: "Автоматический выключатель ВА47-29 1Р 50А 4,5кА х-ка С ИЭК [MVA20-1-050-C]",
      series: "ВА47-29",
      poleNumber: "1P",
      current: "50",
      characteristics: "С",
      breakingCapacity: "4,5kA",
      type: "Модульный",
      brand: "IEK",
      skuManufacturer: "MVA20-1-050-C",
      skuInternal: "00004292",
      balance: "110",
      price: 47.55,
      filterUsed: false
    },
    {
      id: 102,
      visible: true,
      inCartVisible: false,
      inCartProductAmount: 0,
      inCartProductAmountAdded: '0',
      cat: "Автоматические выключатели модульные",
img: ["../../img/102/102.jpg"],
      alt: null,
      title: "Автоматический выключатель SH201L C50 4,5кА [2CDS241001R0504]",
      series: "SH201L",
      poleNumber: "1P",
      current: "50",
      characteristics: "С",
      breakingCapacity: "4,5kA",
      type: "Модульный",
      brand: "ABB",
      skuManufacturer: "2CDS241001R0504",
      skuInternal: "247895",
      balance: "13",
      price: 340.57,
      filterUsed: false
    },
    {
      id: 103,
      visible: true,
      inCartVisible: false,
      inCartProductAmount: 0,
      inCartProductAmountAdded: '0',
      cat: "Автоматические выключатели модульные",
img: ["../../img/103/103.jpg"],
      alt: null,
      title: "Автоматический выключатель ВА47-29 2Р 4А 4,5кА х-ка С ИЭК [MVA20-2-004-C]",
      series: "ВА47-29",
      poleNumber: "2P",
      current: "4",
      characteristics: "С",
      breakingCapacity: "4,5kA",
      type: "Модульный",
      brand: "IEK",
      skuManufacturer: "MVA20-2-004-C",
      skuInternal: "00004297",
      balance: "37",
      price: 222.06,
      filterUsed: false
    },
    {
      id: 104,
      visible: true,
      inCartVisible: false,
      inCartProductAmount: 0,
      inCartProductAmountAdded: '0',
      cat: "Автоматические выключатели модульные",
img: ["../../img/103/103.jpg"],
      alt: null,
      title: "Автоматический выключатель ВА47-29 2Р 5А 4,5кА х-ка С ИЭК [MVA20-2-005-C]",
      series: "ВА47-29",
      poleNumber: "2P",
      current: "5",
      characteristics: "С",
      breakingCapacity: "4,5kA",
      type: "Модульный",
      brand: "IEK",
      skuManufacturer: "MVA20-2-005-C",
      skuInternal: "00004298",
      balance: "3",
      price: 102.29,
      filterUsed: false
    },
    {
      id: 105,
      visible: true,
      inCartVisible: false,
      inCartProductAmount: 0,
      inCartProductAmountAdded: '0',
      cat: "Автоматические выключатели модульные",
img: ["../../img/105/105.jpg"],
      alt: null,
      title: "Автоматический выключатель SH202L C6 4,5кА [2CDS242001R0064]",
      series: "SH202L",
      poleNumber: "2P",
      current: "6",
      characteristics: "С",
      breakingCapacity: "4,5kA",
      type: "Модульный",
      brand: "ABB",
      skuManufacturer: "2CDS242001R0064",
      skuInternal: "00028777",
      balance: "4",
      price: 483.85,
      filterUsed: false
    },
    {
      id: 106,
      visible: true,
      inCartVisible: false,
      inCartProductAmount: 0,
      inCartProductAmountAdded: '0',
      cat: "Автоматические выключатели модульные",
img: ["../../img/103/103.jpg"],
      alt: null,
      title: "Автоматический выключатель ВА47-29-2С6-УХЛ3-КЭАЗ 4,5кА [231628]",
      series: "ВА47-29",
      poleNumber: "2P",
      current: "6",
      characteristics: "С",
      breakingCapacity: "4,5kA",
      type: "Модульный",
      brand: "КЭАЗ",
      skuManufacturer: "231628",
      skuInternal: "268704",
      balance: "15",
      price: 186.22,
      filterUsed: false
    },
    {
      id: 107,
      visible: true,
      inCartVisible: false,
      inCartProductAmount: 0,
      inCartProductAmountAdded: '0',
      cat: "Автоматические выключатели модульные",
img: ["../../img/103/103.jpg"],
      alt: null,
      title: "Автоматический выключатель ВА47-29 2Р 10А 4,5кА х-ка С ИЭК [MVA20-2-010-C]",
      series: "ВА47-29",
      poleNumber: "2P",
      current: "10",
      characteristics: "С",
      breakingCapacity: "4,5kA",
      type: "Модульный",
      brand: "IEK",
      skuManufacturer: "MVA20-2-010-C",
      skuInternal: "00003882",
      balance: "9",
      price: 176.86,
      filterUsed: false
    },
    {
      id: 108,
      visible: true,
      inCartVisible: false,
      inCartProductAmount: 0,
      inCartProductAmountAdded: '0',
      cat: "Автоматические выключатели модульные",
img: ["../../img/103/103.jpg"],
      alt: null,
      title: "Автоматический выключатель ВА47-29-2С10-УХЛ3-КЭАЗ 4,5кА [141592]",
      series: "ВА47-29",
      poleNumber: "2P",
      current: "10",
      characteristics: "С",
      breakingCapacity: "4,5kA",
      type: "Модульный",
      brand: "КЭАЗ",
      skuManufacturer: "141592",
      skuInternal: "172687",
      balance: "10",
      price: 186.22,
      filterUsed: false
    },
    {
      id: 109,
      visible: true,
      inCartVisible: false,
      inCartProductAmount: 0,
      inCartProductAmountAdded: '0',
      cat: "Автоматические выключатели модульные",
img: ["../../img/103/103.jpg"],
      alt: null,
      title: "Автоматический выключатель ВА47-29 2Р 13А 4,5кА х-ка С ИЭК [MVA20-2-013-C]",
      series: "ВА47-29",
      poleNumber: "2P",
      current: "13",
      characteristics: "С",
      breakingCapacity: "4,5kA",
      type: "Модульный",
      brand: "IEK",
      skuManufacturer: "MVA20-2-013-C",
      skuInternal: "124770",
      balance: "10",
      price: 149.22,
      filterUsed: false
    },
    {
      id: 110,
      visible: true,
      inCartVisible: false,
      inCartProductAmount: 0,
      inCartProductAmountAdded: '0',
      cat: "Автоматические выключатели модульные",
img: ["../../img/103/103.jpg"],
      alt: null,
      title: "Автоматический выключатель ВА47-29 2Р 32А 4,5кА х-ка С ИЭК [MVA20-2-032-C]",
      series: "ВА47-29",
      poleNumber: "2P",
      current: "32",
      characteristics: "С",
      breakingCapacity: "4,5kA",
      type: "Модульный",
      brand: "IEK",
      skuManufacturer: "MVA20-2-032-C",
      skuInternal: "00004301",
      balance: "4",
      price: 247.26,
      filterUsed: false
    },
    {
      id: 111,
      visible: true,
      inCartVisible: false,
      inCartProductAmount: 0,
      inCartProductAmountAdded: '0',
      cat: "Автоматические выключатели модульные",
img: ["../../img/111/111.jpg"],
      alt: null,
      title: "Автоматический выключатель ВА47-63 2P 32А C 4,5kA EKF PROxima [mcb4763-2-32C-pro]",
      series: "ВА47-63",
      poleNumber: "2P",
      current: "32",
      characteristics: "С",
      breakingCapacity: "4,5kA",
      type: "Модульный",
      brand: "EKF",
      skuManufacturer: "mcb4763-2-32C-pro",
      skuInternal: "00542015",
      balance: "5",
      price: 201.60,
      filterUsed: false
    },
    {
      id: 112,
      visible: true,
      inCartVisible: false,
      inCartProductAmount: 0,
      inCartProductAmountAdded: '0',
      cat: "Автоматические выключатели модульные",
img: ["../../img/112/112.jpg"],
      alt: null,
      title: "Автоматический выключатель Z406 2Р C32 4,5кА ELVERT Z4062C-32",
      series: "Z406",
      poleNumber: "2P",
      current: "32",
      characteristics: "С",
      breakingCapacity: "4,5kA",
      type: "Модульный",
      brand: "ELVERT",
      skuManufacturer: "Z4062C-32",
      balance: 2,
      price: 151.20,
      filterUsed: false
    },
    {
      id: 113,
      visible: true,
      inCartVisible: false,
      inCartProductAmount: 0,
      inCartProductAmountAdded: '0',
      cat: "Автоматические выключатели модульные",
img: ["../../img/113/113.jpg"],
      alt: null,
      title: "Автоматический выключатель ВА47-29 3Р 6А 4,5кА х-ка С ИЭК [MVA20-3-006-C]",
      series: "ВА47-29",
      poleNumber: "3P",
      current: "6",
      characteristics: "С",
      breakingCapacity: "4,5kA",
      type: "Модульный",
      brand: "IEK",
      skuManufacturer: "MVA20-3-006-C",
      skuInternal: "00004310",
      balance: "5",
      price: 340.98,
      filterUsed: false
    },
    {
      id: 114,
      visible: true,
      inCartVisible: false,
      inCartProductAmount: 0,
      inCartProductAmountAdded: '0',
      cat: "Автоматические выключатели модульные",
img: ["../../img/114/114.jpg"],
      alt: null,
      title: "Автоматический выключатель SH203L C6 4,5кА [2CDS243001R0064]",
      series: "SH203L",
      poleNumber: "3P",
      current: "6",
      characteristics: "С",
      breakingCapacity: "4,5kA",
      type: "Модульный",
      brand: "ABB",
      skuManufacturer: "2CDS243001R0064",
      skuInternal: "00026871",
      balance: "2",
      price: 641.16,
      filterUsed: false
    },
    {
      id: 115,
      visible: true,
      inCartVisible: false,
      inCartProductAmount: 0,
      inCartProductAmountAdded: '0',
      cat: "Автоматические выключатели модульные",
img: ["../../img/115/115.jpg"],
      alt: null,
      title: "Автоматический выключатель ВА47-29-3С6-УХЛ3-КЭАЗ 4,5кА [231630]",
      series: "ВА47-29",
      poleNumber: "3P",
      current: "6",
      characteristics: "С",
      breakingCapacity: "4,5kA",
      type: "Модульный",
      brand: "КЭАЗ",
      skuManufacturer: "231630",
      skuInternal: "268706",
      balance: "15",
      price: 249.01,
      filterUsed: false
    },
    {
      id: 116,
      visible: true,
      inCartVisible: false,
      inCartProductAmount: 0,
      inCartProductAmountAdded: '0',
      cat: "Автоматические выключатели модульные",
img: ["../../img/113/113.jpg"],
      alt: null,
      title: "Автоматический выключатель ВА47-29 3Р 8А 4,5кА х-ка С ИЭК [MVA20-3-008-C]",
      series: "ВА47-29",
      poleNumber: "3P",
      current: "8",
      characteristics: "С",
      breakingCapacity: "4,5kA",
      type: "Модульный",
      brand: "IEK",
      skuManufacturer: "MVA20-3-008-C",
      skuInternal: "00009876",
      balance: "1",
      price: 153.00,
      filterUsed: false
    },
    {
      id: 117,
      visible: true,
      inCartVisible: false,
      inCartProductAmount: 0,
      inCartProductAmountAdded: '0',
      cat: "Автоматические выключатели модульные",
img: ["../../img/113/113.jpg"],
      alt: null,
      title: "Автоматический выключатель ВА47-29 3Р 10А 4,5кА х-ка С ИЭК [MVA20-3-010-C]",
      series: "ВА47-29",
      poleNumber: "3P",
      current: "10",
      characteristics: "С",
      breakingCapacity: "4,5kA",
      type: "Модульный",
      brand: "IEK",
      skuManufacturer: "MVA20-3-010-C",
      skuInternal: "00003885",
      balance: "4",
      price: 337.70,
      filterUsed: false
    },
    {
      id: 118,
      visible: true,
      inCartVisible: false,
      inCartProductAmount: 0,
      inCartProductAmountAdded: '0',
      cat: "Автоматические выключатели модульные",
img: ["../../img/115/115.jpg"],
      alt: null,
      title: "Автоматический выключатель ВА47-29-3С10-УХЛ3-КЭАЗ 4,5кА [141610]",
      series: "ВА47-29",
      poleNumber: "3P",
      current: "10",
      characteristics: "С",
      breakingCapacity: "4,5kA",
      type: "Модульный",
      brand: "КЭАЗ",
      skuManufacturer: "141610",
      skuInternal: "172695",
      balance: "11",
      price: 279.78,
      filterUsed: false
    },
    {
      id: 119,
      visible: true,
      inCartVisible: false,
      inCartProductAmount: 0,
      inCartProductAmountAdded: '0',
      cat: "Автоматические выключатели модульные",
img: ["../../img/119/119.jpg"],
      alt: null,
      title: "Автоматический выключатель ВА47-29 3Р 16А 4,5кА х-ка С GENERICA [MVA25-3-016-C]",
      series: "ВА47-29",
      poleNumber: "3P",
      current: "16",
      characteristics: "С",
      breakingCapacity: "4,5kA",
      type: "Модульный",
      brand: "IEK",
      skuManufacturer: "MVA25-3-016-C",
      skuInternal: "00473082",
      balance: "2",
      price: 325.40,
      filterUsed: false
    },
    {
      id: 120,
      visible: true,
      inCartVisible: false,
      inCartProductAmount: 0,
      inCartProductAmountAdded: '0',
      cat: "Автоматические выключатели модульные",
img: ["../../img/115/115.jpg"],
      alt: null,
      title: "Автоматический выключатель ВА47-29-3C32-УХЛ3-КЭАЗ 4,5кА [141616]",
      series: "ВА47-29",
      poleNumber: "3P",
      current: "32",
      characteristics: "С",
      breakingCapacity: "4,5kA",
      type: "Модульный",
      brand: "КЭАЗ",
      skuManufacturer: 141616,
      balance: 6,
      price: 250.01,
      filterUsed: false
    },
    {
      id: 121,
      visible: true,
      inCartVisible: false,
      inCartProductAmount: 0,
      inCartProductAmountAdded: '0',
      cat: "Автоматические выключатели модульные",
img: ["../../img/119/119.jpg"],
      alt: null,
      title: "Автоматический выключатель ВА47-29 3Р 63А 4,5кА х-ка С GENERICA [MVA25-3-063-C]",
      series: "ВА47-29",
      poleNumber: "3P",
      current: "63",
      characteristics: "С",
      breakingCapacity: "4,5kA",
      type: "Модульный",
      brand: "IEK",
      skuManufacturer: "MVA25-3-063-C",
      skuInternal: "00473088",
      balance: "3",
      price: 411.75,
      filterUsed: false
    },
    {
      id: 122,
      visible: true,
      inCartVisible: false,
      inCartProductAmount: 0,
      inCartProductAmountAdded: '0',
      cat: "Автоматические выключатели модульные",
img: ["../../img/122/122.jpg"],
      alt: null,
      title: "Автоматический выключатель OptiDin BM63-1C 1-УХЛ3 6кА [103542]",
      series: "OptiDin BM63",
      poleNumber: "1P",
      current: "1",
      characteristics: "С",
      breakingCapacity: "6kA",
      type: "Модульный",
      brand: "КЭАЗ",
      skuManufacturer: "103542",
      skuInternal: "123070",
      balance: "10",
      price: 198.89,
      filterUsed: false
    },
    {
      id: 123,
      visible: true,
      inCartVisible: false,
      inCartProductAmount: 0,
      inCartProductAmountAdded: '0',
      cat: "Автоматические выключатели модульные",
img: ["../../img/122/122.jpg"],
      alt: null,
      title: "Автоматический выключатель OptiDin BM63-1C 3-УХЛ3 6кА [103549]",
      series: "OptiDin BM63",
      poleNumber: "1P",
      current: "3",
      characteristics: "С",
      breakingCapacity: "6kA",
      type: "Модульный",
      brand: "КЭАЗ",
      skuManufacturer: "103549",
      skuInternal: "123071",
      balance: "6",
      price: 106.73,
      filterUsed: false
    },
    {
      id: 124,
      visible: true,
      inCartVisible: false,
      inCartProductAmount: 0,
      inCartProductAmountAdded: '0',
      cat: "Автоматические выключатели модульные",
img: ["../../img/124/124.jpg"],
      alt: null,
      title: "Автоматический выключатель ВА47-60M 1Р 10А 6кА C IEK MVA31-1-010-C",
      series: "ВА47-60M",
      poleNumber: "1P",
      current: "10",
      characteristics: "С",
      breakingCapacity: "6kA",
      type: "Модульный",
      brand: "IEK",
      skuManufacturer: "MVA31-1-010-C",
      balance: 3,
      price: 225.98,
      filterUsed: false
    },
    {
      id: 125,
      visible: true,
      inCartVisible: false,
      inCartProductAmount: 0,
      inCartProductAmountAdded: '0',
      cat: "Автоматические выключатели модульные",
img: ["../../img/124/124.jpg"],
      alt: null,
      title: "Автоматический выключатель ВА47-60M 1Р 16А 6кА С IEK MVA31-1-016-C",
      series: "ВА47-60M",
      poleNumber: "1P",
      current: "16",
      characteristics: "С",
      breakingCapacity: "6kA",
      type: "Модульный",
      brand: "IEK",
      skuManufacturer: "MVA31-1-016-C",
      balance: 10,
      price: 217.99,
      filterUsed: false
    },
    {
      id: 126,
      visible: true,
      inCartVisible: false,
      inCartProductAmount: 0,
      inCartProductAmountAdded: '0',
      cat: "Автоматические выключатели модульные",
img: ["../../img/124/124.jpg"],
      alt: null,
      title: "Автоматический выключатель ВА47-60M 1Р 25А 6кА С IEK MVA31-1-025-C",
      series: "ВА47-60M",
      poleNumber: "1P",
      current: "25",
      characteristics: "С",
      breakingCapacity: "6kA",
      type: "Модульный",
      brand: "IEK",
      skuManufacturer: "MVA31-1-025-C",
      balance: 3,
      price: 228.64,
      filterUsed: false
    },
    {
      id: 127,
      visible: true,
      inCartVisible: false,
      inCartProductAmount: 0,
      inCartProductAmountAdded: '0',
      cat: "Автоматические выключатели модульные",
img: ["../../img/124/124.jpg"],
      alt: null,
      title: "Автоматический выключатель ВА47-60M 1Р 32А 6кА C IEK MVA31-1-032-C",
      series: "ВА47-60M",
      poleNumber: "1P",
      current: "32",
      characteristics: "С",
      breakingCapacity: "6kA",
      type: "Модульный",
      brand: "IEK",
      skuManufacturer: "MVA31-1-032-C",
      balance: 3,
      price: 257.65,
      filterUsed: false
    },
    {
      id: 128,
      visible: true,
      inCartVisible: false,
      inCartProductAmount: 0,
      inCartProductAmountAdded: '0',
      cat: "Автоматические выключатели модульные",
img: ["../../img/122/122.jpg"],
      alt: null,
      title: "Автоматический выключатель OptiDin BM63-1C32-УХЛ3 6кА [103550]",
      series: "OptiDin BM63",
      poleNumber: "1P",
      current: "32",
      characteristics: "С",
      breakingCapacity: "6kA",
      type: "Модульный",
      brand: "КЭАЗ",
      skuManufacturer: "103550",
      skuInternal: "00036556",
      balance: "10",
      price: 133.46,
      filterUsed: false
    },
    {
      id: 129,
      visible: true,
      inCartVisible: false,
      inCartProductAmount: 0,
      inCartProductAmountAdded: '0',
      cat: "Автоматические выключатели модульные",
img: ["../../img/122/122.jpg"],
      alt: null,
      title: "Автоматический выключатель OptiDin BM63-1C40-УХЛ3 6кА [103552]",
      series: "OptiDin BM63",
      poleNumber: "1P",
      current: "40",
      characteristics: "С",
      breakingCapacity: "6kA",
      type: "Модульный",
      brand: "КЭАЗ",
      skuManufacturer: "103552",
      skuInternal: "00031921",
      balance: "39",
      price: 101.17,
      filterUsed: false
    },
    {
      id: 130,
      visible: true,
      inCartVisible: false,
      inCartProductAmount: 0,
      inCartProductAmountAdded: '0',
      cat: "Автоматические выключатели модульные",
img: ["../../img/122/122.jpg"],
      alt: null,
      title: "Автоматический выключатель OptiDin BM63-1C50-УХЛ3 6кА [103554]",
      series: "OptiDin BM63",
      poleNumber: "1P",
      current: "50",
      characteristics: "С",
      breakingCapacity: "6kA",
      type: "Модульный",
      brand: "КЭАЗ",
      skuManufacturer: "103554",
      skuInternal: "00029826",
      balance: "35",
      price: 107.78,
      filterUsed: false
    },
    {
      id: 131,
      visible: true,
      inCartVisible: false,
      inCartProductAmount: 0,
      inCartProductAmountAdded: '0',
      cat: "Автоматические выключатели модульные",
img: ["../../img/131/131.jpg"],
      alt: null,
      title: "Автоматический выключатель S201 C50 6кА [2CDS251001R0504]",
      series: "S201",
      poleNumber: "1P",
      current: "50",
      characteristics: "С",
      breakingCapacity: "6kA",
      type: "Модульный",
      brand: "ABB",
      skuManufacturer: "2CDS251001R0504",
      skuInternal: "00019075",
      balance: "10",
      price: 704.73,
      filterUsed: false
    },
    {
      id: 132,
      visible: true,
      inCartVisible: false,
      inCartProductAmount: 0,
      inCartProductAmountAdded: '0',
      cat: "Автоматические выключатели модульные",
img: ["../../img/122/122.jpg"],
      alt: null,
      title: "Автоматический выключатель OptiDin BM63-1C63-УХЛ3 6кА [103556]",
      series: "OptiDin BM63",
      poleNumber: "1P",
      current: "63",
      characteristics: "С",
      breakingCapacity: "6kA",
      type: "Модульный",
      brand: "КЭАЗ",
      skuManufacturer: "103556",
      skuInternal: "00036555",
      balance: "35",
      price: 116.39,
      filterUsed: false
    },
    {
      id: 133,
      visible: true,
      inCartVisible: false,
      inCartProductAmount: 0,
      inCartProductAmountAdded: '0',
      cat: "Автоматические выключатели модульные",
img: ["../../img/133/133.jpg"],
      alt: null,
      title: "Автоматический выключатель OptiDin BM63-2C 2-УХЛ3 6кА [103676]",
      series: "OptiDin BM63",
      poleNumber: "2P",
      current: "2",
      characteristics: "С",
      breakingCapacity: "6kA",
      type: "Модульный",
      brand: "КЭАЗ",
      skuManufacturer: "103676",
      skuInternal: "123138",
      balance: "2",
      price: 467.89,
      filterUsed: false
    },
    {
      id: 134,
      visible: true,
      inCartVisible: false,
      inCartProductAmount: 0,
      inCartProductAmountAdded: '0',
      cat: "Автоматические выключатели модульные",
img: ["../../img/134/134.jpg"],
      alt: null,
      title: "Автоматический выключатель S202 C10 6кА [2CDS252001R0104]",
      series: "S202",
      poleNumber: "2P",
      current: "10",
      characteristics: "С",
      breakingCapacity: "6kA",
      type: "Модульный",
      brand: "ABB",
      skuManufacturer: "2CDS252001R0104",
      skuInternal: "00031506",
      balance: "8",
      price: 995.03,
      filterUsed: false
    },
    {
      id: 135,
      visible: true,
      inCartVisible: false,
      inCartProductAmount: 0,
      inCartProductAmountAdded: '0',
      cat: "Автоматические выключатели модульные",
img: ["../../img/133/133.jpg"],
      alt: null,
      title: "Автоматический выключатель OptiDin BM63-2C10-УХЛ3 6кА [103673]",
      series: "OptiDin BM63",
      poleNumber: "2P",
      current: "10",
      characteristics: "С",
      breakingCapacity: "6kA",
      type: "Модульный",
      brand: "КЭАЗ",
      skuManufacturer: "103673",
      skuInternal: "123134",
      balance: "6",
      price: 308.82,
      filterUsed: false
    },
    {
      id: 136,
      visible: true,
      inCartVisible: false,
      inCartProductAmount: 0,
      inCartProductAmountAdded: '0',
      cat: "Автоматические выключатели модульные",
img: ["../../img/133/133.jpg"],
      alt: null,
      title: "Автоматический выключатель OptiDin BM63-2C16-УХЛ3 6кА [103675]",
      series: "OptiDin BM63",
      poleNumber: "2P",
      current: "16",
      characteristics: "С",
      breakingCapacity: "6kA",
      type: "Модульный",
      brand: "КЭАЗ",
      skuManufacturer: "103675",
      skuInternal: "00029828",
      balance: "14",
      price: 215.27,
      filterUsed: false
    },
    {
      id: 137,
      visible: true,
      inCartVisible: false,
      inCartProductAmount: 0,
      inCartProductAmountAdded: '0',
      cat: "Автоматические выключатели модульные",
img: ["../../img/133/133.jpg"],
      alt: null,
      title: "Автоматический выключатель OptiDin BM63-2C20-УХЛ3 6кА [103677]",
      series: "OptiDin BM63",
      poleNumber: "2P",
      current: "20",
      characteristics: "С",
      breakingCapacity: "6kA",
      type: "Модульный",
      brand: "КЭАЗ",
      skuManufacturer: "103677",
      skuInternal: "123137",
      balance: "10",
      price: 267.72,
      filterUsed: false
    },
    {
      id: 138,
      visible: true,
      inCartVisible: false,
      inCartProductAmount: 0,
      inCartProductAmountAdded: '0',
      cat: "Автоматические выключатели модульные",
img: ["../../img/133/133.jpg"],
      alt: null,
      title: "Автоматический выключатель OptiDin BM63-2C25-УХЛ3 6кА [103678]",
      series: "OptiDin BM63",
      poleNumber: "2P",
      current: "25",
      characteristics: "С",
      breakingCapacity: "6kA",
      type: "Модульный",
      brand: "КЭАЗ",
      skuManufacturer: "103678",
      skuInternal: "00029827",
      balance: "22",
      price: 175.63,
      filterUsed: false
    },
    {
      id: 139,
      visible: true,
      inCartVisible: false,
      inCartProductAmount: 0,
      inCartProductAmountAdded: '0',
      cat: "Автоматические выключатели модульные",
img: ["../../img/133/133.jpg"],
      alt: null,
      title: "Автоматический выключатель OptiDin BM63-2C32-УХЛ3 6кА [103680]",
      series: "OptiDin BM63",
      poleNumber: "2P",
      current: "32",
      characteristics: "С",
      breakingCapacity: "6kA",
      type: "Модульный",
      brand: "КЭАЗ",
      skuManufacturer: "103680",
      skuInternal: "123139",
      balance: "30",
      price: 270.90,
      filterUsed: false
    },
    {
      id: 140,
      visible: true,
      inCartVisible: false,
      inCartProductAmount: 0,
      inCartProductAmountAdded: '0',
      cat: "Автоматические выключатели модульные",
img: ["../../img/134/134.jpg"],
      alt: null,
      title: "Автоматический выключатель S202 C32 6кА [2CDS252001R0324]",
      series: "S202",
      poleNumber: "2P",
      current: "32",
      characteristics: "С",
      breakingCapacity: "6kA",
      type: "Модульный",
      brand: "ABB",
      skuManufacturer: "2CDS252001R0324",
      skuInternal: "00030396",
      balance: "1",
      price: 1492.26,
      filterUsed: false
    },
    {
      id: 141,
      visible: true,
      inCartVisible: false,
      inCartProductAmount: 0,
      inCartProductAmountAdded: '0',
      cat: "Автоматические выключатели модульные",
img: ["../../img/133/133.jpg"],
      alt: null,
      title: "Автоматический выключатель OptiDin BM63-2C40-УХЛ3 6кА [103682]",
      series: "OptiDin BM63",
      poleNumber: "2P",
      current: "40",
      characteristics: "С",
      breakingCapacity: "6kA",
      type: "Модульный",
      brand: "КЭАЗ",
      skuManufacturer: "103682",
      skuInternal: "123140",
      balance: "28",
      price: 231.27,
      filterUsed: false
    },
    {
      id: 142,
      visible: true,
      inCartVisible: false,
      inCartProductAmount: 0,
      inCartProductAmountAdded: '0',
      cat: "Автоматические выключатели модульные",
img: ["../../img/133/133.jpg"],
      alt: null,
      title: "Автоматический выключатель OptiDin BM63-2C50-УХЛ3 6кА [103684]",
      series: "OptiDin BM63",
      poleNumber: "2P",
      current: "50",
      characteristics: "С",
      breakingCapacity: "6kA",
      type: "Модульный",
      brand: "КЭАЗ",
      skuManufacturer: "103684",
      skuInternal: "123142",
      balance: "36",
      price: 257.62,
      filterUsed: false
    },
    {
      id: 143,
      visible: true,
      inCartVisible: false,
      inCartProductAmount: 0,
      inCartProductAmountAdded: '0',
      cat: "Автоматические выключатели модульные",
img: ["../../img/133/133.jpg"],
      alt: null,
      title: "Автоматический выключатель OptiDin BM63-2C63-УХЛ3 6кА [103686]",
      series: "OptiDin BM63",
      poleNumber: "2P",
      current: "63",
      characteristics: "С",
      breakingCapacity: "6kA",
      type: "Модульный",
      brand: "КЭАЗ",
      skuManufacturer: "103686",
      skuInternal: "00033210",
      balance: "24",
      price: 269.58,
      filterUsed: false
    },
    {
      id: 144,
      visible: true,
      inCartVisible: false,
      inCartProductAmount: 0,
      inCartProductAmountAdded: '0',
      cat: "Автоматические выключатели модульные",
img: ["../../img/144/144.jpg"],
      alt: null,
      title: "Автоматический выключатель OptiDin BM63-3C 6-УХЛ3 6кА [103747]",
      series: "OptiDin BM63",
      poleNumber: "3P",
      current: "6",
      characteristics: "С",
      breakingCapacity: "6kA",
      type: "Модульный",
      brand: "КЭАЗ",
      skuManufacturer: "103747",
      skuInternal: "123153",
      balance: "1",
      price: 611.24,
      filterUsed: false
    },
    {
      id: 145,
      visible: true,
      inCartVisible: false,
      inCartProductAmount: 0,
      inCartProductAmountAdded: '0',
      cat: "Автоматические выключатели модульные",
img: ["../../img/145/145.jpg"],
      alt: null,
      title: "Автоматический выключатель S203 C6 6кА [2CDS253001R0064]",
      series: "S203",
      poleNumber: "3P",
      current: "6",
      characteristics: "С",
      breakingCapacity: "6kA",
      type: "Модульный",
      brand: "ABB",
      skuManufacturer: "2CDS253001R0064",
      skuInternal: "00020055",
      balance: "1",
      price: 1627.30,
      filterUsed: false
    },
    {
      id: 146,
      visible: true,
      inCartVisible: false,
      inCartProductAmount: 0,
      inCartProductAmountAdded: '0',
      cat: "Автоматические выключатели модульные",
img: ["../../img/145/145.jpg"],
      alt: null,
      title: "Автоматический выключатель S203 C10 6кА [2CDS253001R0104]",
      series: "S203",
      poleNumber: "3P",
      current: "10",
      characteristics: "С",
      breakingCapacity: "6kA",
      type: "Модульный",
      brand: "ABB",
      skuManufacturer: "2CDS253001R0104",
      skuInternal: "00019064",
      balance: "6",
      price: 1376.07,
      filterUsed: false
    },
    {
      id: 147,
      visible: true,
      inCartVisible: false,
      inCartProductAmount: 0,
      inCartProductAmountAdded: '0',
      cat: "Автоматические выключатели модульные",
img: ["../../img/145/145.jpg"],
      alt: null,
      title: "Автоматический выключатель S203 C100 6кА [2CDS253001R0824]",
      series: "S203",
      poleNumber: "3P",
      current: "100",
      characteristics: "С",
      breakingCapacity: "6kA",
      type: "Модульный",
      brand: "ABB",
      skuManufacturer: "2CDS253001R0824",
      skuInternal: "269715",
      balance: "0",
      price: 0,
      filterUsed: false
    },
    {
      id: 148,
      visible: true,
      inCartVisible: false,
      inCartProductAmount: 0,
      inCartProductAmountAdded: '0',
      cat: "Автоматические выключатели модульные",
img: ["../../img/144/144.jpg"],
      alt: null,
      title: "Автоматический выключатель OptiDin BM63-3D 2-УХЛ3 6кА [103764]",
      series: "OptiDin BM63",
      poleNumber: "3P",
      current: "2",
      characteristics: "D",
      breakingCapacity: "6kA",
      type: "Модульный",
      brand: "КЭАЗ",
      skuManufacturer: "103764",
      skuInternal: "123186",
      balance: "1",
      price: 410.52,
      filterUsed: false
    },
    {
      id: 149,
      visible: true,
      inCartVisible: false,
      inCartProductAmount: 0,
      inCartProductAmountAdded: '0',
      cat: "Автоматические выключатели модульные",
img: ["../../img/149/149.jpg"],
      alt: null,
      title: "Автоматический выключатель ВА47-100 1Р 25А 10кА х-ка С TDM [SQ0207-0048]",
      series: "ВА47-100",
      poleNumber: "1P",
      current: "25",
      characteristics: "С",
      breakingCapacity: "10kA",
      type: "Модульный",
      brand: "TDM",
      skuManufacturer: "SQ0207-0048",
      skuInternal: "00356994",
      balance: "2",
      price: 499.81,
      filterUsed: false
    },
    {
      id: 150,
      visible: true,
      inCartVisible: false,
      inCartProductAmount: 0,
      inCartProductAmountAdded: '0',
      cat: "Автоматические выключатели модульные",
img: ["../../img/150/150.jpg"],
      alt: null,
      title: "Автоматический выключатель ВА47-100 1Р 32А 10кА х-ка С ИЭК [MVA40-1-032-C]",
      series: "ВА47-100",
      poleNumber: "1P",
      current: "32",
      characteristics: "С",
      breakingCapacity: "10kA",
      type: "Модульный",
      brand: "IEK",
      skuManufacturer: "MVA40-1-032-C",
      skuInternal: "00024849",
      balance: "17",
      price: 354.34,
      filterUsed: false
    },
    {
      id: 151,
      visible: true,
      inCartVisible: false,
      inCartProductAmount: 0,
      inCartProductAmountAdded: '0',
      cat: "Автоматические выключатели модульные",
img: ["../../img/150/150.jpg"],
      alt: null,
      title: "Автоматический выключатель ВА47-100 1Р 35А 10кА х-ка С ИЭК [MVA40-1-035-C]",
      series: "ВА47-100",
      poleNumber: "1P",
      current: "35",
      characteristics: "С",
      breakingCapacity: "10kA",
      type: "Модульный",
      brand: "IEK",
      skuManufacturer: "MVA40-1-035-C",
      skuInternal: "00052986",
      balance: "12",
      price: 209.24,
      filterUsed: false
    },
    {
      id: 152,
      visible: true,
      inCartVisible: false,
      inCartProductAmount: 0,
      inCartProductAmountAdded: '0',
      cat: "Автоматические выключатели модульные",
img: ["../../img/149/149.jpg"],
      alt: null,
      title: "Автоматический выключатель ВА47-100 1Р 40А 10кА х-ка С TDM [SQ0207-0051]",
      series: "ВА47-100",
      poleNumber: "1P",
      current: "40",
      characteristics: "С",
      breakingCapacity: "10kA",
      type: "Модульный",
      brand: "TDM",
      skuManufacturer: "SQ0207-0051",
      skuInternal: "00356997",
      balance: "4",
      price: 483.84,
      filterUsed: false
    },
    {
      id: 153,
      visible: true,
      inCartVisible: false,
      inCartProductAmount: 0,
      inCartProductAmountAdded: '0',
      cat: "Автоматические выключатели модульные",
img: ["../../img/149/149.jpg"],
      alt: null,
      title: "Автоматический выключатель ВА47-100 1Р 50А 10кА х-ка С TDM [SQ0207-0052]",
      series: "ВА47-100",
      poleNumber: "1P",
      current: "50",
      characteristics: "С",
      breakingCapacity: "10kA",
      type: "Модульный",
      brand: "TDM",
      skuManufacturer: "SQ0207-0052",
      skuInternal: "00356998",
      balance: "12",
      price: 499.81,
      filterUsed: false
    },
    {
      id: 154,
      visible: true,
      inCartVisible: false,
      inCartProductAmount: 0,
      inCartProductAmountAdded: '0',
      cat: "Автоматические выключатели модульные",
img: ["../../img/149/149.jpg"],
      alt: null,
      title: "Автоматический выключатель ВА47-100 1Р 63А 10кА х-ка С TDM [SQ0207-0053]",
      series: "ВА47-100",
      poleNumber: "1P",
      current: "63",
      characteristics: "С",
      breakingCapacity: "10kA",
      type: "Модульный",
      brand: "TDM",
      skuManufacturer: "SQ0207-0053",
      skuInternal: "00356999",
      balance: "16",
      price: 473.69,
      filterUsed: false
    },
    {
      id: 155,
      visible: true,
      inCartVisible: false,
      inCartProductAmount: 0,
      inCartProductAmountAdded: '0',
      cat: "Автоматические выключатели модульные",
img: ["../../img/150/150.jpg"],
      alt: null,
      title: "Автоматический выключатель ВА47-100 1Р 80А 10кА х-ка С ИЭК [MVA40-1-080-C]",
      series: "ВА47-100",
      poleNumber: "1P",
      current: "80",
      characteristics: "С",
      breakingCapacity: "10kA",
      type: "Модульный",
      brand: "IEK",
      skuManufacturer: "MVA40-1-080-C",
      skuInternal: "00007822",
      balance: "32",
      price: 209.24,
      filterUsed: false
    },
    {
      id: 156,
      visible: true,
      inCartVisible: false,
      inCartProductAmount: 0,
      inCartProductAmountAdded: '0',
      cat: "Автоматические выключатели модульные",
img: ["../../img/149/149.jpg"],
      alt: null,
      title: "Автоматический выключатель ВА47-100 1Р 80А 10кА х-ка С TDM [SQ0207-0054]",
      series: "ВА47-100",
      poleNumber: "1P",
      current: "80",
      characteristics: "С",
      breakingCapacity: "10kA",
      type: "Модульный",
      brand: "TDM",
      skuManufacturer: "SQ0207-0054",
      skuInternal: "00357000",
      balance: "12",
      price: 477.49,
      filterUsed: false
    },
    {
      id: 157,
      visible: true,
      inCartVisible: false,
      inCartProductAmount: 0,
      inCartProductAmountAdded: '0',
      cat: "Автоматические выключатели модульные",
img: ["../../img/157/157.jpg"],
      alt: null,
      title: "Автоматический выключатель ВА47-100 3Р 10А 10кА х-ка С ИЭК [MVA40-3-010-C]",
      series: "ВА47-100",
      poleNumber: "3P",
      current: "10",
      characteristics: "С",
      breakingCapacity: "10kA",
      type: "Модульный",
      brand: "IEK",
      skuManufacturer: "MVA40-3-010-C",
      skuInternal: "00020397",
      balance: "19",
      price: 681.73,
      filterUsed: false
    },
    {
      id: 158,
      visible: true,
      inCartVisible: false,
      inCartProductAmount: 0,
      inCartProductAmountAdded: '0',
      cat: "Автоматические выключатели модульные",
img: ["../../img/157/157.jpg"],
      alt: null,
      title: "Автоматический выключатель ВА47-100 3Р 35А 10кА х-ка С ИЭК [MVA40-3-035-C]",
      series: "ВА47-100",
      poleNumber: "3P",
      current: "35",
      characteristics: "С",
      breakingCapacity: "10kA",
      type: "Модульный",
      brand: "IEK",
      skuManufacturer: "MVA40-3-035-C",
      skuInternal: "00052987",
      balance: "4",
      price: 627.73,
      filterUsed: false
    },
    {
      id: 159,
      visible: true,
      inCartVisible: false,
      inCartProductAmount: 0,
      inCartProductAmountAdded: '0',
      cat: "Автоматические выключатели модульные",
img: ["../../img/157/157.jpg"],
      alt: null,
      title: "Автоматический выключатель ВА47-100 3Р 80А 10кА х-ка D ИЭК [MVA40-3-080-D]",
      series: "ВА47-100",
      poleNumber: "3P",
      current: "80",
      characteristics: "D",
      breakingCapacity: "10kA",
      type: "Модульный",
      brand: "IEK",
      skuManufacturer: "MVA40-3-080-D",
      skuInternal: "00012605",
      balance: "6",
      price: 1575.07,
      filterUsed: false
    },
    {
      id: 160,
      visible: true,
      inCartVisible: false,
      inCartProductAmount: 0,
      inCartProductAmountAdded: '0',
      cat: "Автоматические выключатели модульные",
img: ["../../img/157/157.jpg"],
      alt: null,
      title: "Автоматический выключатель ВА47-100 3Р 100А 10кА х-ка D ИЭК [MVA40-3-100-D]",
      series: "ВА47-100",
      poleNumber: "3P",
      current: "100",
      characteristics: "D",
      breakingCapacity: "10kA",
      type: "Модульный",
      brand: "IEK",
      skuManufacturer: "MVA40-3-100-D",
      skuInternal: "125256",
      balance: "8",
      price: 1464.90,
      filterUsed: false
    },
    {
      id: 161,
      visible: true,
      inCartVisible: false,
      inCartProductAmount: 0,
      inCartProductAmountAdded: '0',
      cat: "Автоматические выключатели модульные",
img: ["../../img/161/161.jpg"],
      alt: null,
      title: "Автоматический выключатель eZ113 3Р C100 10кА ELVERT eZ1133C-100",
      series: "eZ113",
      poleNumber: "3P",
      current: "100",
      characteristics: "С",
      breakingCapacity: "10kA",
      type: "Модульный",
      brand: "ELVERT",
      skuManufacturer: "eZ1133C-100",
      balance: 1,
      price: 1022,
      filterUsed: false
    },
    {
      id: 162,
      visible: true,
      inCartVisible: false,
      inCartProductAmount: 0,
      inCartProductAmountAdded: '0',
      cat: "Автоматические выключатели модульные",
img: ["../../img/162/162.jpg"],
      alt: null,
      title: "Автоматический выключатель ВА66-31 3P 80А 18кА GENERICA IEK SAV10-3-0080-G",
      series: "ВА66-31",
      poleNumber: "3P",
      current: "80",
      breakingCapacity: "18kA",
      type: "Модульный",
      brand: "IEK",
      skuManufacturer: "SAV10-3-0080-G",
      balance: 2,
      price: 2507.79,
      filterUsed: false
    },
    {
      id: 163,
      visible: true,
      inCartVisible: false,
      inCartProductAmount: 0,
      inCartProductAmountAdded: '0',
      cat: "Автоматические выключатели модульные",
img: ["../../img/163/163.jpg"],
      alt: null,
      title: "Автоматический выключатель ВА88-32 3Р 100А 25кА IEK SVA10-3-0100-R",
      series: "ВА88-32",
      poleNumber: "3P",
      current: "100",
      breakingCapacity: "25kA",
      type: "Модульный",
      brand: "IEK",
      skuManufacturer: "SVA10-3-0100-R",
      balance: 5,
      price: 2941.31,
      filterUsed: false
    },
    {
      id: 164,
      visible: true,
      inCartVisible: false,
      inCartProductAmount: 0,
      inCartProductAmountAdded: '0',
      cat: "Автоматические выключатели модульные",
img: ["../../img/164/164.jpg"],
      alt: null,
      title: "Автоматический выключатель для защиты электродвигателей ABB XT2S 160 MA 80 Im=480...1120 3p F F (160А 50кА 3P) 1SDA067768R1",
      series: "XT2S",
      poleNumber: "3P",
      current: "160",
      breakingCapacity: "50kA",
      type: "Модульный",
      brand: "ABB",
      skuManufacturer: "1SDA067768R1",
      balance: 1,
      price: 29900,
      filterUsed: false
    },
    {
      id: 165,
      visible: true,
      inCartVisible: false,
      inCartProductAmount: 0,
      inCartProductAmountAdded: '0',
      cat: "Автоматические выключатели модульные",
img: ["../../img/165/165.jpg"],
      alt: null,
      title: "Контакт состояния КС47 на DIN-рейку ИЭК [MVA00D-KS-1]",
      brand: "IEK",
      skuManufacturer: "MVA00D-KS-1",
      skuInternal: "00044348",
      balance: "6",
      price: 185.64,
      filterUsed: false
    },
    {
      id: 166,
      visible: true,
      inCartVisible: false,
      inCartProductAmount: 0,
      inCartProductAmountAdded: '0',
      cat: "Автоматические выключатели модульные",
img: ["../../img/166/166.jpg"],
      alt: null,
      title: "Расцепитель независ.РН47 новая серия на DIN-рейку ИЭК [MVA01D-RN]",
      brand: "IEK",
      skuManufacturer: "MVA01D-RN",
      skuInternal: "335335",
      balance: "17",
      price: 858.22,
      filterUsed: false
    },
    {
      id: 167,
      visible: true,
      inCartVisible: false,
      inCartProductAmount: 0,
      inCartProductAmountAdded: '0',
      cat: "Автоматические выключатели модульные",
img: ["../../img/166/166.jpg"],
      alt: null,
      title: "Расцепитель независ.РН47 новая серия на DIN-рейку ИЭК [MVA01D-RN]",
      brand: "IEK",
      skuManufacturer: "MVA01D-RN",
      skuInternal: "223430",
      balance: "11",
      price: 970.83,
      filterUsed: false
    },
    {
      id: 168,
      visible: true,
      inCartVisible: false,
      inCartProductAmount: 0,
      inCartProductAmountAdded: '0',
      cat: "Автоматические выключатели модульные",
img: ["../../img/168/168.jpg"],
      alt: null,
      title: "Световой индикатор фаз ИЭК [MIF10-400]",
      brand: "IEK",
      skuManufacturer: "MIF10-400",
      skuInternal: "00006859",
      balance: "4",
      price: 136.58,
      filterUsed: false
    },
    {
      id: 169,
      visible: true,
      inCartVisible: false,
      inCartProductAmount: 0,
      inCartProductAmountAdded: '0',
      cat: "Автоматические выключатели модульные",
img: ["../../img/169/169.jpg"],
      alt: null,
      title: "Адаптер на дин-рейку OptiStart MP-32-HU1 [116908]",
      brand: "КЭАЗ",
      skuManufacturer: "116908",
      skuInternal: "170954",
      balance: "6",
      price: 1048.85,
      filterUsed: false
    },
    {
      id: 170,
      visible: true,
      inCartVisible: false,
      inCartProductAmount: 0,
      inCartProductAmountAdded: '0',
      cat: "Автоматические выключатели модульные",
img: ["../../img/170/170.jpg"],
      alt: null,
      title: "Супрессор OptiStart RC-K3N 230 [230879]",
      brand: "КЭАЗ",
      skuManufacturer: "230879",
      skuInternal: "223243",
      balance: "50",
      price: 724.26,
      filterUsed: false
    },
    {
      id: 171,
      visible: true,
      inCartVisible: false,
      inCartProductAmount: 0,
      inCartProductAmountAdded: '0',
      cat: "Автоматические выключатели модульные",
img: ["../../img/171/171.jpg"],
      alt: null,
      title: "Крышка присоединения кабеля OptiBlock 00-2шт(Зак.) 141020",
      brand: "КЭАЗ",
      skuManufacturer: 141020,
      balance: 2,
      price: 299.684,
      filterUsed: false
    }
  ]

export default products
/*
  
    [
    {
      id: 1,
      visible: true,
      inCartVisible: false,
      inCartProductAmount: 0,
      inCartProductAmountAdded: '0',
      cat: 'estate',
      img: ['../../img//item1.jpg', '../../img//item2.jpg', '../../img//item3.jpg', '../../img//item4.jpg', '../../img//item5.jpg', '../../img//item6.jpg',"],
      alt: ['Загородный дом с видом на озеро', 'alt2', 'alt3', 'alt4', 'alt5', 'alt6'],
      title: 'Загородный дом с видом на озеро',
      price: '3000000',
      address: 'Приозёрск, улица Прибрежная',
      date: '2 часа назад',
      filterUsed: false
    },
      {
        id: 2,
        visible: true,
      inCartVisible: false,
      inCartProductAmount: 0,
      inCartProductAmountAdded: '0',
      cat: 'cars',
        img: ['../../img//car1.jpg', '../../img//car2.jpg', '../../img//car3.jpg', '../../img//car4.jpg', '../../img//car5.jpg'"],
      alt: ['Форд Мустанг 2020', 'alt2', 'alt3', 'alt4', 'alt5'],
        title: 'Ford Mustang 2020',
        price: '2950000',
        address: 'Москва, Нахимовский проспект',
        date: '3 дня назад',
        filterUsed: false
      },
      {
        id: 3,
        visible: true,
      inCartVisible: false,
      inCartProductAmount: 0,
      inCartProductAmountAdded: '0',
      cat: 'laptops',
        img: ['../../img//item5.jpg', '../../img//car2.jpg', '../../img//car3.jpg', '../../img//car4.jpg', '../../img//car5.jpg'"],
      alt: ['Ультрабук HP Spectre X360 2019', 'alt2', 'alt3', 'alt4', 'alt5'],
        title: 'Ультрабук HP Spectre X360 2019',
        price: '41000',
        address: 'Пермь, улица Научная',
        date: '7 января',
        filterUsed: false
      },
      {
        id: 4,
        visible: true,
      inCartVisible: false,
      inCartProductAmount: 0,
      inCartProductAmountAdded: '0',
      cat: 'cars',
        img: ['../../img//item6.jpg', '../../img//car1.jpg', '../../img//car3.jpg', '../../img//car4.jpg', '../../img//car5.jpg'"],
      alt: ['ВАЗ 2101', 'alt2', 'alt3', 'alt4', 'alt5'],
        title: 'ВАЗ 2101 на ходу, без документов',
        price: '50000',
        address: 'Омск, улица Уездная',
        date: '30 декабря 2019 года',
        filterUsed: false
      },
      {
        id: 5,
        visible: true,
      inCartVisible: false,
      inCartProductAmount: 0,
      inCartProductAmountAdded: '0',
      cat: 'cameras',
        img: ['../../img//item3.jpg', '../../img//car1.jpg', '../../img//car3.jpg', '../../img//car4.jpg', '../../img//car5.jpg'"],
      alt: ['Фотокамера Canon EOS 5D + объектив', 'alt2', 'alt3', 'alt4', 'alt5'],
        title: '10мп 4к Зеркальный Фотокамера Canon EOS 5D + объектив',
        price: '19000',
        address: 'Киров, улица Ленина',
        date: '1 день назад',
        type: 'mirror',
        cameraPx: 'mp10',
        cameraRes: 'k4',
        filterUsed: false,
        isChecked: false
      },
      {
        id: 6,
        visible: true,
      inCartVisible: false,
      inCartProductAmount: 0,
      inCartProductAmountAdded: '0',
      cat: 'cameras',
        img: ['../../img//item3.jpg', '../../img//car1.jpg', '../../img//car3.jpg', '../../img//car4.jpg', '../../img//car5.jpg'"],
      alt: ['Пленочный Nikon 3D', 'alt2', 'alt3', 'alt4', 'alt5'],
        title: '1мп hd цифровой Пленочный Nikon 3D',
        price: '11000',
        address: 'Ставрополь, улица Летняя',
        date: '2 дня назад',
        type: 'digital',
        cameraPx: 'mp1',
        cameraRes: 'hd',
        filterUsed: false,
        isChecked: false
      },
      {
        id: 7,
        visible: true,
      inCartVisible: false,
      inCartProductAmount: 0,
      inCartProductAmountAdded: '0',
      cat: 'cameras',
        img: ['../../img//item3.jpg', '../../img//car1.jpg', '../../img//car3.jpg', '../../img//car4.jpg', '../../img//car5.jpg'"],
      alt: ['Пленочный 1Мп', 'alt2', 'alt3', 'alt4', 'alt5'],
        title: '1мп 5к незеркальный  Пленочный Olympus',
        price: '3000',
        address: 'Брянск, улица Кисельная',
        date: '3 дня назад',
        type: 'nonmirror',
        cameraPx: 'mp1',
        cameraRes: 'k5',
        filterUsed: false,
        isChecked: false
      },
      {
        id: 8,
        visible: true,
      inCartVisible: false,
      inCartProductAmount: 0,
      inCartProductAmountAdded: '0',
      cat: 'cameras',
        img: ['../../img//item3.jpg', '../../img//car1.jpg', '../../img//car3.jpg', '../../img//car4.jpg', '../../img//car5.jpg'"],
      alt: ['Пленочный 1Мп', 'alt2', 'alt3', 'alt4', 'alt5'],
        title: '1мп 4к незеркальный  Пленочный Зенит',
        price: '300000',
        address: 'Брянск, улица Кисельная',
        date: '3 дня назад',
        type: 'nonmirror',
        cameraPx: 'mp1',
        cameraRes: 'k4',
        filterUsed: false,
        isChecked: false
      },
      {
        id: 9,
        visible: true,
      inCartVisible: false,
      inCartProductAmount: 0,
      inCartProductAmountAdded: '0',
      cat: 'cameras',
        img: ['../../img//item3.jpg', '../../img//car1.jpg', '../../img//car3.jpg', '../../img//car4.jpg', '../../img//car5.jpg'"],
      alt: ['Пленочный 1Мп', 'alt2', 'alt3', 'alt4', 'alt5'],
        title: '1мп 4к незеркальный  Пленочный Зоркий 4к',
        price: '3000',
        address: 'Брянск, улица Кисельная',
        date: '3 дня назад',
        type: 'nonmirror',
        cameraPx: 'mp1',
        cameraRes: 'k4',
        filterUsed: false,
        isChecked: false
      },
  
  
    ]
*/
