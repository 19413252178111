import React from 'react'
import MediaQuery from 'react-responsive'
import classes from './PageName.module.css'

const PageName = () => {
  return (
    <>
      <MediaQuery minWidth={1280}>
        <div className={classes.pageName__wrapper}>
          <div className={classes.pageName__wrapper_div}>
            <div className={classes.pageName__wrapper_logo}>
              <p className={classes.pageName__logo}>Склад4.рф</p>
            </div>
          </div>
        </div>
      </MediaQuery>

      <MediaQuery minWidth={600} maxWidth={1279}>
        <div className={classes.mobile_pageName__wrapper}>
          <div className={classes.mobile_pageName__wrapper_div}>
            <div className={classes.pageName__wrapper_logo}>
              <p className={classes.pageName__logo}>Склад4.рф</p>
            </div>
          </div>
        </div>
      </MediaQuery>

      <MediaQuery maxWidth={599}>
        <div className={classes.mobile_pageName__wrapper}>
          <div className={classes.mobile_pageName__wrapper_div}>
            <div className={classes.pageName__wrapper_logo}>
              <p className={classes.pageName__logo}>Склад4.рф</p>
            </div>
          </div>
        </div>
      </MediaQuery>

    </>
  )
}

export default PageName

