import React from "react";
import classes from "./ResultsCartInfo.module.css";
import MediaQuery from "react-responsive";

const ResultsCartInfo = (props) => {

  if (props.filterState.inCartProducts.length === 0 && props.filterState.resultsSortingCartActive) {
    return (
      <div className={classes.favourites}>
        <p className={classes['favourites__empty-message']}>Корзина пуста
        </p>
        <p className={classes.favourites__notion}>Добавьте товар в корзину
        </p>
      </div>
    )
  }

  if (props.filterState.inCartProducts.length !== 0 && props.filterState.resultsSortingCartActive && props.filterState.orderPopUpActive === true) {
    let renderInputClientName
    renderInputClientName =
      <input
        // className={classes.favourites__input}
        type="text"
        name="clientName"
        placeholder="Ваше имя"
        value={props.filterState.toSend.clientName}
        onChange={props.resultsCartInfoInputHandler}
      />
    let renderInputClientTelephone
    renderInputClientTelephone =
      <input
        // className={classes.favourites__input}
        type="text"
        name="clientTelephone"
        placeholder="Телефон"
        value={props.filterState.toSend.clientTelephone}
        onChange={props.resultsCartInfoInputHandler}
      />
    let renderInputClientEmail
    renderInputClientEmail =
      <input
        // className={classes.favourites__input}
        type="text"
        name="clientEmail"
        placeholder="Email"
        value={props.filterState.toSend.clientEmail}
        onChange={props.resultsCartInfoInputHandler}
      />
    let renderInputClientCompany
    renderInputClientCompany =
      <input
        // className={classes.favourites__input}
        type="text"
        name="clientCompany"
        placeholder="Организация"
        value={props.filterState.toSend.clientCompany}
        onChange={props.resultsCartInfoInputHandler}
      />
    let renderInputClientINN
    renderInputClientINN =
      <input
        // className={classes.favourites__input}
        type="text"
        name="clientINN"
        placeholder="ИНН"
        value={props.filterState.toSend.clientINN}
        onChange={props.resultsCartInfoInputHandler}
      />
    let renderInputClientKPP
    renderInputClientKPP =
      <input
        // className={classes.favourites__input}
        type="text"
        name="clientKPP"
        placeholder="КПП"
        value={props.filterState.toSend.clientKPP}
        onChange={props.resultsCartInfoInputHandler}
      />
    let renderInputClientAddress
    renderInputClientAddress =
      <input
        // className={classes.favourites__input}
        type="text"
        name="clientAddress"
        placeholder="Юр. адрес"
        value={props.filterState.toSend.clientAddress}
        onChange={props.resultsCartInfoInputHandler}
      />
    let renderInputClientBankAccount
    renderInputClientBankAccount =
      <input
        // className={classes.favourites__input}
        type="text"
        name="clientBankAccount"
        placeholder="Расчетный счет"
        value={props.filterState.toSend.clientBankAccount}
        onChange={props.resultsCartInfoInputHandler}
      />
    let renderInputClientBankBIK
    renderInputClientBankBIK =
      <input
        // className={classes.favourites__input}
        type="text"
        name="clientBankBIK"
        placeholder="БИК Банка"
        value={props.filterState.toSend.clientBankBIK}
        onChange={props.resultsCartInfoInputHandler}
      />
    let renderButtonSubmit
    renderButtonSubmit =
      <button
        className={classes.favourites__button}
        type="submit"
      >
        {
          props.filterState.sendButtonClicked
            ?
            'Заказ отправлен'
            :
            'Отправить'
        }
      </button>
    let renderPrivacyBlock
    renderPrivacyBlock =
      <>
        <MediaQuery minWidth={1280}>
          <p className={classes.favourites__notion}>
            Не является офертой. Наличие товара и цены уточняйте.
            <br/>
            Оформляя заказ, Вы подтверждаете&ensp;
            <a
              className={classes.favourites__form_p_a}
              onClick={props.personalDataPolicyHandler}
            >
              согласие на обработку персональных данных.
            </a>
          </p>
        </MediaQuery>
        <MediaQuery minWidth={600} maxWidth={1279}>
          <p className={classes.tablet_favourites__notion}>
            Не является офертой. Наличие товара и цены уточняйте.
            <br/>
            Оформляя заказ, Вы подтверждаете&ensp;
            <a
              className={classes.favourites__form_p_a}
              onClick={props.personalDataPolicyHandler}
            >
              согласие на обработку персональных данных.
            </a>
          </p>
        </MediaQuery>
        <MediaQuery maxWidth={599}>
          <p className={classes.mobile_favourites__notion}>
            Не является офертой. Наличие товара и цены уточняйте. Оформляя заказ, Вы подтверждаете&ensp;
            <a
              className={classes.favourites__form_p_a}
              onClick={props.personalDataPolicyHandler}
            >
              согласие на обработку персональных данных.
            </a>
          </p>
        </MediaQuery>
      </>

    return (
      <>
        <p className={classes.favourites__form_p}>Заполните форму:</p>

        <MediaQuery minWidth={1280}>
          <form
            className={classes.favourites__form}
            onSubmit={props.resultsCartInfoSubmitHandler}
          >
            {renderInputClientName}
            {renderInputClientTelephone}
            {renderInputClientEmail}
            {renderInputClientCompany}
            {renderInputClientINN}
            {renderInputClientKPP}
            {renderInputClientAddress}
            {renderInputClientBankAccount}
            {renderInputClientBankBIK}
            {renderButtonSubmit}
            {renderPrivacyBlock}
          </form>
        </MediaQuery>

        <MediaQuery minWidth={600} maxWidth={1279}>
          <form
            className={classes.tablet_favourites__form}
            onSubmit={props.resultsCartInfoSubmitHandler}
          >
            {renderInputClientName}
            {renderInputClientTelephone}
            {renderInputClientEmail}
            {renderInputClientCompany}
            {renderInputClientINN}
            {renderInputClientKPP}
            {renderInputClientAddress}
            {renderInputClientBankAccount}
            {renderInputClientBankBIK}
            {renderButtonSubmit}
            {renderPrivacyBlock}
          </form>
        </MediaQuery>

        <MediaQuery maxWidth={599}>
          <form
            className={classes.mobile_favourites__form}
            onSubmit={props.resultsCartInfoSubmitHandler}
          >
            {renderInputClientName}
            {renderInputClientTelephone}
            {renderInputClientEmail}
            {renderInputClientCompany}
            {renderInputClientINN}
            {renderInputClientKPP}
            {renderInputClientAddress}
            {renderInputClientBankAccount}
            {renderInputClientBankBIK}
            {renderButtonSubmit}
            {renderPrivacyBlock}
          </form>
        </MediaQuery>
      </>
    )
  } else {
    return null
  }

}

export default ResultsCartInfo