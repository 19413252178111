import {
  SELECT,
  ALL_FILTERS_VISIBLE,
  UDLINITEL_VISIBLE,
  VILKI_VISIBLE,
  SCHTEPSELNYE_GNEZDA_VISIBLE,
  VYKLUCHATELI_PROHODNYE_VISIBLE,
  SHNURY_VISIBLE,
  TROINIKI_VISIBLE,
  TELEPHONE_UDLINITEL_VISIBLE,
  KOLODKI_VISIBLE,
  RAZJEM_PITANIYA_VISIBLE,
  AVT_VYKLUCHATELI_MODULNYE_VISIBLE,
  // ESTATE_VISIBLE,
  // CAMERA_VISIBLE,
  // LAPTOP_VISIBLE,
  // CAR_VISIBLE
} from "../actions/actionTypes"

const initialState = {

  filterSelectedCategory: 'Все товары',
  filterAllFiltersVisible: false,
  filterUdlinitelVisible: false,
  filterVilkiVisible: false,
  filterShtepselnyeGnezdaVisible: false,
  filterVykluchateliProhodnyeVisible: false,
  filterShnuryVisible: false,
  filterTroinikiVisible: false,
  filterTelephoneUdlinitelVisible: false,
  filterKolodkiVisible: false,
  filterRazjemPitaniyaVisible: false,
  filterAvtVykluchateliModulnyeVisible: false,
  // filterEstateVisible: true,
  // filterCameraVisible: true,
  // filterLaptopVisible: true,
  // filterCarVisible: true,

}

export default function filterSelectCatReducer(state = initialState, action) {

  switch (action.type) {

    case SELECT:
      return {
        ...state,
        filterSelectedCategory: action.payload
      }

    // в данном кейсе (ALL_FILTERS_VISIBLE) задумывалось выводить все фильтры для раздела "Все товары", поэтому все значения должны быть true, но временно решено отображать только фильтры для каждого конкретного раздела, поэтому временно проставил везде false:
    case ALL_FILTERS_VISIBLE:
      return {
        ...state,
        filterAllFiltersVisible: false,
        filterUdlinitelVisible: false,
        filterVilkiVisible: false,
        filterShtepselnyeGnezdaVisible: false,
        filterVykluchateliProhodnyeVisible: false,
        filterShnuryVisible: false,
        filterTroinikiVisible: false,
        filterTelephoneUdlinitelVisible: false,
        filterKolodkiVisible: false,
        filterRazjemPitaniyaVisible: false,
        filterAvtVykluchateliModulnyeVisible: false,
        // filterEstateVisible: true,
        // filterCameraVisible: true,
        // filterLaptopVisible: true,
        // filterCarVisible: true,
      }

    case UDLINITEL_VISIBLE:
      return {
        ...state,
        filterAllFiltersVisible: false,
        filterUdlinitelVisible: true,
        filterVilkiVisible: false,
        filterShtepselnyeGnezdaVisible: false,
        filterVykluchateliProhodnyeVisible: false,
        filterShnuryVisible: false,
        filterTroinikiVisible: false,
        filterTelephoneUdlinitelVisible: false,
        filterKolodkiVisible: false,
        filterRazjemPitaniyaVisible: false,
        filterAvtVykluchateliModulnyeVisible: false,
        // filterEstateVisible: true,
        // filterCameraVisible: false,
        // filterLaptopVisible: false,
        // filterCarVisible: false,
      }

    case VILKI_VISIBLE:
      return {
        ...state,
        filterAllFiltersVisible: false,
        filterUdlinitelVisible: false,
        filterVilkiVisible: true,
        filterShtepselnyeGnezdaVisible: false,
        filterVykluchateliProhodnyeVisible: false,
        filterShnuryVisible: false,
        filterTroinikiVisible: false,
        filterTelephoneUdlinitelVisible: false,
        filterKolodkiVisible: false,
        filterRazjemPitaniyaVisible: false,
        filterAvtVykluchateliModulnyeVisible: false,
        // filterEstateVisible: true,
        // filterCameraVisible: false,
        // filterLaptopVisible: false,
        // filterCarVisible: false,
      }

    case SCHTEPSELNYE_GNEZDA_VISIBLE:
      return {
        ...state,
        filterAllFiltersVisible: false,
        filterUdlinitelVisible: false,
        filterVilkiVisible: false,
        filterShtepselnyeGnezdaVisible: true,
        filterVykluchateliProhodnyeVisible: false,
        filterShnuryVisible: false,
        filterTroinikiVisible: false,
        filterTelephoneUdlinitelVisible: false,
        filterKolodkiVisible: false,
        filterRazjemPitaniyaVisible: false,
        filterAvtVykluchateliModulnyeVisible: false,
        // filterEstateVisible: true,
        // filterCameraVisible: false,
        // filterLaptopVisible: false,
        // filterCarVisible: false,
      }

    case VYKLUCHATELI_PROHODNYE_VISIBLE:
      return {
        ...state,
        filterAllFiltersVisible: false,
        filterUdlinitelVisible: false,
        filterVilkiVisible: false,
        filterShtepselnyeGnezdaVisible: false,
        filterVykluchateliProhodnyeVisible: true,
        filterShnuryVisible: false,
        filterTroinikiVisible: false,
        filterTelephoneUdlinitelVisible: false,
        filterKolodkiVisible: false,
        filterRazjemPitaniyaVisible: false,
        filterAvtVykluchateliModulnyeVisible: false,
        // filterEstateVisible: true,
        // filterCameraVisible: false,
        // filterLaptopVisible: false,
        // filterCarVisible: false,
      }

    case SHNURY_VISIBLE:
      return {
        ...state,
        filterAllFiltersVisible: false,
        filterUdlinitelVisible: false,
        filterVilkiVisible: false,
        filterShtepselnyeGnezdaVisible: false,
        filterVykluchateliProhodnyeVisible: false,
        filterShnuryVisible: true,
        filterTroinikiVisible: false,
        filterTelephoneUdlinitelVisible: false,
        filterKolodkiVisible: false,
        filterRazjemPitaniyaVisible: false,
        filterAvtVykluchateliModulnyeVisible: false,
        // filterEstateVisible: true,
        // filterCameraVisible: false,
        // filterLaptopVisible: false,
        // filterCarVisible: false,
      }

    case TROINIKI_VISIBLE:
      return {
        ...state,
        filterAllFiltersVisible: false,
        filterUdlinitelVisible: false,
        filterVilkiVisible: false,
        filterShtepselnyeGnezdaVisible: false,
        filterVykluchateliProhodnyeVisible: false,
        filterShnuryVisible: false,
        filterTroinikiVisible: true,
        filterTelephoneUdlinitelVisible: false,
        filterKolodkiVisible: false,
        filterRazjemPitaniyaVisible: false,
        filterAvtVykluchateliModulnyeVisible: false,
        // filterEstateVisible: true,
        // filterCameraVisible: false,
        // filterLaptopVisible: false,
        // filterCarVisible: false,
      }

    case TELEPHONE_UDLINITEL_VISIBLE:
      return {
        ...state,
        filterAllFiltersVisible: false,
        filterUdlinitelVisible: false,
        filterVilkiVisible: false,
        filterShtepselnyeGnezdaVisible: false,
        filterVykluchateliProhodnyeVisible: false,
        filterShnuryVisible: false,
        filterTroinikiVisible: false,
        filterTelephoneUdlinitelVisible: true,
        filterKolodkiVisible: false,
        filterRazjemPitaniyaVisible: false,
        filterAvtVykluchateliModulnyeVisible: false,
        // filterEstateVisible: true,
        // filterCameraVisible: false,
        // filterLaptopVisible: false,
        // filterCarVisible: false,
      }

    case KOLODKI_VISIBLE:
      return {
        ...state,
        filterAllFiltersVisible: false,
        filterUdlinitelVisible: false,
        filterVilkiVisible: false,
        filterShtepselnyeGnezdaVisible: false,
        filterVykluchateliProhodnyeVisible: false,
        filterShnuryVisible: false,
        filterTroinikiVisible: false,
        filterTelephoneUdlinitelVisible: false,
        filterKolodkiVisible: true,
        filterRazjemPitaniyaVisible: false,
        filterAvtVykluchateliModulnyeVisible: false,
        // filterEstateVisible: true,
        // filterCameraVisible: false,
        // filterLaptopVisible: false,
        // filterCarVisible: false,
      }

    case RAZJEM_PITANIYA_VISIBLE:
      return {
        ...state,
        filterAllFiltersVisible: false,
        filterUdlinitelVisible: false,
        filterVilkiVisible: false,
        filterShtepselnyeGnezdaVisible: false,
        filterVykluchateliProhodnyeVisible: false,
        filterShnuryVisible: false,
        filterTroinikiVisible: false,
        filterTelephoneUdlinitelVisible: false,
        filterKolodkiVisible: false,
        filterRazjemPitaniyaVisible: true,
        filterAvtVykluchateliModulnyeVisible: false,
        // filterEstateVisible: true,
        // filterCameraVisible: false,
        // filterLaptopVisible: false,
        // filterCarVisible: false,
      }

    case AVT_VYKLUCHATELI_MODULNYE_VISIBLE:
      return {
        ...state,
        filterAllFiltersVisible: false,
        filterUdlinitelVisible: false,
        filterVilkiVisible: false,
        filterShtepselnyeGnezdaVisible: false,
        filterVykluchateliProhodnyeVisible: false,
        filterShnuryVisible: false,
        filterTroinikiVisible: false,
        filterTelephoneUdlinitelVisible: false,
        filterKolodkiVisible: false,
        filterRazjemPitaniyaVisible: false,
        filterAvtVykluchateliModulnyeVisible: true,
        // filterEstateVisible: true,
        // filterCameraVisible: false,
        // filterLaptopVisible: false,
        // filterCarVisible: false,
      }


    /*    case ESTATE_VISIBLE:
          return {
            ...state,
            filterAllFiltersVisible: false,
            filterEstateVisible: true,
            filterCameraVisible: false,
            filterLaptopVisible: false,
            filterCarVisible: false,
          }

        case CAMERA_VISIBLE:
          return {
            ...state,
            filterAllFiltersVisible: false,
            filterEstateVisible: false,
            filterCameraVisible: true,
            filterLaptopVisible: false,
            filterCarVisible: false,
          }

        case LAPTOP_VISIBLE:
          return {
            ...state,
            filterAllFiltersVisible: false,
            filterEstateVisible: false,
            filterCameraVisible: false,
            filterLaptopVisible: true,
            filterCarVisible: false,
          }

        case CAR_VISIBLE:
          return {
            ...state,
            filterAllFiltersVisible: false,
            filterEstateVisible: false,
            filterCameraVisible: false,
            filterLaptopVisible: false,
            filterCarVisible: true,
          }*/

    default:
      return {
        ...state,
      }
  }

}
