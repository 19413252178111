import React from 'react'
import MediaQuery from 'react-responsive'
import classes from './Blueline.module.css'

const Blueline = () => {
  return (
    <>
      <MediaQuery minWidth={1280}>
        <div className={classes.blueline}>
          <div className={classes.blueline_wrapper}>
            <p className={classes.blueline_description}>Светотехника и электрика - распродажа складских запасов</p>
            <div className={classes.blueline_contacts__wrapper}>
              <p className={classes.blueline_contacts}>
                тел.: 8 (499) 391-03-35
              </p>
              <p className={classes.blueline_contacts}>
                email: sklad4993910335@yandex.ru
              </p>
            </div>
          </div>
        </div>
      </MediaQuery>

      <MediaQuery minWidth={600} maxWidth={1279}>
        <div className={classes.tablet_blueline}>
          <div className={classes.tablet_blueline_wrapper}>
            <p className={classes.tablet_blueline_description}>Светотехника и электрика из наличия</p>
            <div className={classes.tablet_blueline_contacts__wrapper}>
              <p className={classes.tablet_blueline_contacts}>
                8 (499) 391-03-35
              </p>
              <p className={classes.tablet_blueline_contacts}>
                sklad4993910335@yandex.ru
              </p>
            </div>
          </div>
        </div>
      </MediaQuery>

      <MediaQuery maxWidth={599}>
        <div className={classes.mobile_blueline}>
          <div className={classes.mobile_blueline_wrapper}>
            <p className={classes.mobile_blueline_description}>Светотехника и электрика</p>
            <div className={classes.mobile_blueline_contacts__wrapper}>
              <p className={classes.mobile_blueline_contacts}>
                8 (499) 391-03-35
              </p>
              <p className={classes.mobile_blueline_contacts}>
                sklad4993910335@yandex.ru
              </p>
            </div>
          </div>
        </div>
      </MediaQuery>
    </>
  )
}

export default Blueline