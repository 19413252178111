import React, {Fragment} from 'react'
import PageName from './PageName/PageName'
import Blueline from './Blueline/Blueline'
import classes from './Header.module.css'
import MediaQuery from "react-responsive";

const Header = () => {
  return (
    <>
      <MediaQuery minWidth={1280}>
        <div className={classes.header__wrapper}>
          <Blueline/>
          <PageName/>
        </div>
      </MediaQuery>
      <MediaQuery minWidth={600} maxWidth={1279}>
        <div className={classes.mobile_header__wrapper}>
          <Blueline/>
          <PageName/>
        </div>
      </MediaQuery>
      <MediaQuery maxWidth={599}>
        <div className={classes.mobile_header__wrapper}>
          <Blueline/>
          <PageName/>
        </div>
      </MediaQuery>
    </>
  )
}

export default Header